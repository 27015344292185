import { recoil } from '@salescore/client-recoil'
import { Form } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'

import { useViewValue } from '../../../../recoil/view/hooks'
import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'
import { ViewGroupPicker } from '../../../form/ViewGroupPicker'

export const KpiFormViewGroupIdFormItem = () => {
  const { form, setForm, setFormModified } = useKpiFormSelectors()

  const view = useViewValue()
  const { views, viewGroupsWithParents } = recoil.sider.useViewsRelated()
  const viewGroup = useMemo(() => {
    return viewGroupsWithParents.find((x) => x.viewGroup.id === view.viewGroupId)?.viewGroup
  }, [views, viewGroupsWithParents])

  return (
    <>
      <Form.Item data-e2e="kpi-form-name-form-item-folder-name" label={t(`フォルダ`)}>
        <ViewGroupPicker
          type={view.type}
          isPrivate={viewGroup?.private ?? view.private}
          archived={false}
          value={form.viewGroupId === undefined ? view.viewGroupId : form.viewGroupId}
          onChange={(v) => {
            // 所属なし用にnullを許容する必要がある
            if (typeof v !== 'string' && v !== null) {
              return
            }
            setForm((previous) => ({ ...previous, viewGroupId: v }))
            setFormModified(true)
          }}
        />
      </Form.Item>
    </>
  )
}

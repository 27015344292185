import { isTruthy } from '@salescore/buff-common'
import { CONSTANT, type PosthogEvent } from '@salescore/client-base'
import { Button } from 'antd'

export interface PanelOption {
  value: string
  label: string
  icon: JSX.Element
  disabled: boolean
  posthogEventName?: PosthogEvent
}

export function VerticalPanelPicker<T extends PanelOption>({
  options,
  selectedValue,
  body,
  hidePanel,
  onClick,
}: {
  options: T[]
  selectedValue: T['value'] | undefined
  body: JSX.Element
  hidePanel?: boolean
  onClick: (option: T) => void
}): JSX.Element {
  return (
    <div className="no-scrollbar flex size-full overflow-y-scroll">
      {!isTruthy(hidePanel) && (
        <div
          style={{
            borderRight: '1px solid #ddd',
          }}
          className="no-scrollbar flex flex-col overflow-y-scroll bg-white p-3"
        >
          {options.map((option, index) => {
            return (
              <Panel
                key={index}
                isPicked={option.value === selectedValue}
                option={option}
                index={index}
                onClick={() => {
                  onClick(option)
                }}
              />
            )
          })}
        </div>
      )}
      <div className="h-full" style={{ width: isTruthy(hidePanel) ? '100%' : 'calc(100% - 95px)' }}>
        {body}
      </div>
    </div>
  )
}

function Panel({
  option,
  isPicked,
  index,
  onClick,
}: {
  isPicked: boolean
  onClick: () => void
  option: PanelOption
  index: number
}): JSX.Element {
  return (
    <Button
      className={`mb-3 flex flex-col items-center justify-center text-center align-middle ${
        isPicked ? 'shadow-md shadow-blue-400' : 'shadow-sm'
      }`}
      style={{
        lineHeight: 1.4,
        fontSize: 10,
        width: 70,
        height: 70,
        color: isPicked ? CONSTANT.colors.primaryColor : undefined,
        borderColor: isPicked ? CONSTANT.colors.primaryColor : undefined,
        borderWidth: isPicked ? 2 : 1,
        // backgroundColor: isPicked ? 'white' : '#ccc',
        opacity: isPicked ? 1 : 0.7,
        zIndex: index,
      }}
      onClick={onClick}
      disabled={option.disabled}
    >
      <div className="" style={{ fontSize: 30 }}>
        {option.icon}
      </div>
      <div className="mb-2">{option.label}</div>
    </Button>
  )
}

import type { PolicyAction } from '../action'
import { necMemberRolePolicyDefinition } from './necMemberRolePolicyDefinition'

// 閲覧メンバー：~何もできないロール。NEC様向けの特別対応~ -> の予定だったが、2023/12の改修で閲覧以外も可能になった。
//            名前と実態があっていないが、近いうちに権限機能が改修されるはずなので無視する
export const readonlyMemberRolePolicyDefinition: { [k in PolicyAction]?: boolean } = {
  ...necMemberRolePolicyDefinition,
  [`kpi-create`]: false,
  [`kpi-update`]: false,
  [`kpi-delete`]: false,
  [`kpi-pivot-create`]: false,
  [`kpi-pivot-update`]: false,
  [`kpi-pivot-delete`]: false,
}

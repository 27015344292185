import { DeleteOutlined, MenuOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Button, Checkbox, Space } from 'antd'
import type { CSSProperties } from 'react'

import { useHover } from '../../hooks/useHover'
import type { KanbanItem as KanbanItemType } from './Kanban'

const defaultStyle: CSSProperties = {
  // transition: 'all 200ms ease', // XXX: transitionもuseSortableから受け取って使うので、ここで指定しないこと
  cursor: 'grab',
  zIndex: 0,
  // width: 240,
  fontSize: 13,
  // height: 50,
}

const draggingStyle: CSSProperties = {
  cursor: 'grabbing',
  // backgroundColor: `red`,
  opacity: 0.5,
  boxShadow: '-1px 0 15px 5px rgba(34, 33, 81, 0.3)',
  // TODO: ソート中の要素が、他要素の後ろに隠れてしまうのを防げなかった。別途absoluteな要素を作るしかないのだろうか？
  // XXX: absoluteにしない限りzIndex指定は意味がない上に、なんか表示がバグるのでやらないこと
  // zIndex: 1000,
}

export function KanbanItem<I>({
  item,
  onDelete,
  onCheck,
}: {
  item: KanbanItemType<I>
  onDelete: (id: string) => void
  onCheck?: (checked: boolean) => void
}) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
  })

  const element = useHover((hovered) => {
    return (
      <div
        key={item.id}
        ref={setNodeRef}
        style={{
          // useSortableから受け取ったtransformを使って場所を変化させる
          // ドラッグ中の要素以外もtransformで場所が入れ替わる
          transform: CSS.Transform.toString(transform),
          transition,
          ...defaultStyle,
          ...(isDragging ? draggingStyle : {}),
        }}
        className="kanban-item flex w-full items-center justify-between px-2 py-1 align-middle hover:opacity-70"
      >
        {/* ... */}
        <div {...attributes} {...listeners} className="flex size-full items-center gap-2">
          <MenuOutlined />
          {item.checked !== undefined && onCheck !== undefined && (
            <Checkbox
              checked={item.checked}
              onChange={(e) => {
                onCheck(Boolean(e.target.checked))
              }}
            />
          )}
          <div>{item.label}</div>
        </div>
        <Space
          style={{
            opacity: hovered ? 1 : 0,
            transition: `opacity 200ms ease`,
          }}
        >
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => {
              onDelete(item.id)
            }}
          />
        </Space>
      </div>
    )
  })
  return element
}

import type { ReactNode } from 'react'
import { useRecoilValue } from 'recoil'

import { riDisplayFormatAtom } from '../../../recoil/navigation/atoms'
import { ViewUiRiTimeSeriesPreview } from './Preview/ViewUiRiTimeSeriesPreview'
import { ViewUiRiWaterfallPreview } from './Preview/ViewUiRiWaterfallPreview'

export interface ViewUIRiPreviewProperties {
  hideFilters?: boolean
  fullWidth?: boolean
}

export function ViewUIRiPreview(properties: ViewUIRiPreviewProperties): ReactNode {
  const format = useRecoilValue(riDisplayFormatAtom)

  switch (format) {
    case 'timeSeries': {
      return <ViewUiRiTimeSeriesPreview {...properties} />
    }
    case 'waterfall': {
      return <ViewUiRiWaterfallPreview {...properties} />
    }
    default: {
      return <></>
    }
  }
}

import { useSuspenseQuery } from '@apollo/client'
import { isPresent, isTruthy } from '@salescore/buff-common'
import { FetchViewGroupsDocument, type ViewFieldsFragment } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { FolderPathAndCreator, HandleQuery, searchParentViewGroups } from '@salescore/client-common'
import { queueTimeWindowExecutorV2 } from '@salescore/frontend-common'
import { Select } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useEffect, useMemo, useState } from 'react'

interface SelectOption {
  value: string | null
  label: string
  path: string[]
  createdBy: string | undefined
  searchKey: string
}

interface Properties {
  // TODO: undefinedはありえないはずだが、ViewForm側のロジックがいまいちで型の上だとundefinedになっている。ViewForm側を直したい。
  //       また、本来はViewGroupFieldsFragmentにしたいが、formが渡されうるのでこうしている
  type: ViewFieldsFragment['type'] | undefined
  isPrivate: boolean // privateという命名にしたいが、予約語なので使えない
  archived: boolean
  ignoreViewGroupIds?: string[]
  disabled?: boolean
  placeholder?: string
  value?: unknown // Form.Itemと一緒に使うのに必要 https://ant.design/components/form#formitemusestatus
  onChange?: (value?: unknown) => void
  hideFolder?: boolean
  ignorePrivateFlag?: boolean // privateのtrue/falseに関わらず全てのgroupを表示する(isPrivateを任意パラメータにした方が良いかも)
}

export function ViewGroupPicker(properties: Omit<Properties, 'viewGroups'>): ReactNode {
  return (
    <HandleQuery loadingElement={<Select loading />}>
      <Body {...properties} />
    </HandleQuery>
  )
}

function Body({
  type,
  isPrivate,
  archived,
  ignoreViewGroupIds,
  disabled,
  placeholder,
  value,
  onChange,
  hideFolder,
  ignorePrivateFlag,
}: Properties): ReactNode {
  // 他のところでrefetch漏れがあって古いフォルダ構造を参照してしまうのを防ぐため、都度最新の値をここで取得
  // 特にサイドバーのドラッグアンドドロップに追従するために必要
  // （サイドバーのD&Dについては、D&Dの後に都度refetchすればいいのだが、refetchしていたら本番でのみ不可解な挙動をしたので、いったん利用をやめている）
  const {
    data: { viewGroups },
  } = useSuspenseQuery(FetchViewGroupsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
    fetchPolicy: 'cache-and-network',
  })
  const viewGroupsWithParents = useMemo(() => {
    const viewGroupsMap = viewGroups.groupByUniqueKey((x) => x.id)
    return viewGroups.map((viewGroup) => {
      return {
        viewGroup,
        parents: searchParentViewGroups(viewGroup.viewGroupId, viewGroupsMap),
      }
    })
  }, [viewGroups])

  const options: SelectOption[] = useMemo(() => {
    const xs = viewGroupsWithParents
      .filter(
        (x) =>
          x.viewGroup.type === type &&
          (isTruthy(ignorePrivateFlag) || x.viewGroup.private === isPrivate) &&
          x.viewGroup.archived === archived &&
          !(ignoreViewGroupIds ?? []).includes(x.viewGroup.id) &&
          (ignoreViewGroupIds ?? []).intersection(x.parents.map((parent) => parent.id)).isBlank(),
      )
      .sortBy((x) => x.viewGroup.createdAt)
      .reverse() // createdAtはISO形式のテキストだが、テキストでもソートされるはず
      .map(
        (x): SelectOption => ({
          value: x.viewGroup.id,
          label: x.viewGroup.name,
          path: x.parents.map((x) => x.name).reverse(),
          createdBy: x.viewGroup.createdBy?.name,
          searchKey: [x.viewGroup.name, ...x.parents.map((x) => x.name), x.viewGroup.createdBy?.name]
            .join('-')
            .toLowerCase(),
        }),
      )

    return [
      {
        value: null,
        label: t(`所属なし`),
        path: [],
        createdBy: '',
        searchKey: '',
      },
      ...xs,
    ]
  }, [viewGroupsWithParents])

  // ソート条件を高度にカスタマイズするため、
  // antdのfilterではなく、自前のフィルタロジックを使う
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(options)
  const [isSearching, setIsSearching] = useState(false)
  const filter = (key: string): SelectOption[] => {
    if (!isPresent(key)) {
      return options
    }
    const filteredOptions = options.filter((x) => x.searchKey.includes(key.toLowerCase()))
    // ソートを行う
    return filteredOptions.sortBy((a) => {
      return [
        a.label.includes(key) ? a.label.length : 99_999, // label側で一致しているか。一致していれば、ラベルの文字長が短い順(=類似度が高い順)
        a.searchKey.length,
      ]
    })
  }
  useEffect(() => {
    setFilteredOptions(options)
  }, [options])
  const selectedOption = options.find((x) => x.value === value)

  return (
    <div>
      <Select
        value={value}
        disabled={disabled}
        placeholder={placeholder ?? t(`フォルダを選択`)}
        showSearch
        style={{ width: '100%' }}
        onChange={onChange}
        // ソート条件を高度にカスタマイズするため、
        // antdのfilterではなく、自前のフィルタロジックを使う
        filterOption={() => {
          return true
        }}
        onSearch={(searchKey) => {
          const isSearchingNew = isPresent(searchKey)
          if (isSearchingNew !== isSearching) {
            setIsSearching(isSearchingNew)
          }

          queueTimeWindowExecutorV2({
            key: 'onSearch',
            f: () => {
              const filteredOptions = filter(searchKey)
              setFilteredOptions(filteredOptions)
            },
          })
        }}
        optionLabelProp="label"
      >
        {filteredOptions.map((x, index) => (
          <Select.Option key={index} {...x}>
            <div>
              <div className="whitespace-pre-wrap break-words pb-1">{x.label}</div>
              <FolderPathAndCreator path={x.path} createdBy={x.createdBy} />
            </div>
          </Select.Option>
        ))}
      </Select>
      {hideFolder === true ? null : (
        <div className="px-3">
          <FolderPathAndCreator path={selectedOption?.path} createdBy={selectedOption?.createdBy} />
        </div>
      )}
    </div>
  )
}

import type { OAuthExtraConfig } from '@salescore/client-common'
import { Form, Input } from 'antd'
import { t } from 'i18next'
import type { Dispatch, SetStateAction } from 'react'

export const MICROSOFT_DYNAMICS_HOST_URL_KEY = `MICROSOFT_DYNAMICS_HOST_URL_KEY`

export function MicrosoftDynamicsSourceForm({
  setExtraConfig,
}: {
  setExtraConfig: Dispatch<SetStateAction<OAuthExtraConfig>>
}) {
  return (
    <Form layout="vertical" validateTrigger="onChange">
      <Form.Item
        name="url"
        label={t(`ホストURL`)}
        required
        rules={[
          {
            pattern: /^https:\/\/[a-z-]*.com$/,
            //  ラベルに:が入ってしまうので一旦保留
            message: `https://<インスタンス名>.crm7.dynamics.com`,
          },
        ]}
      >
        <Input
          placeholder={`(${t(`例`)}) https://sample.crm7.dynamics.com`}
          onChange={(e) => {
            localStorage.setItem(MICROSOFT_DYNAMICS_HOST_URL_KEY, e.target.value)
            setExtraConfig((oldValue) => {
              return {
                ...oldValue,
                additionalScope: `${e.target.value}/user_impersonation`,
              }
            })
          }}
        />
      </Form.Item>
    </Form>
  )
}

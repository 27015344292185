import type { Dispatch, SetStateAction } from 'react'
import * as React from 'react'

import { useRowHeightState } from '../../../recoil/navigation/hooks'
import type { LatestFieldChangeItem } from '../../../recoil/records/selectors/changesSelector'
import { useRowRelatedSelector } from '../../recoil/selectors/rowRelatedSelector'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import type { RSheetsCursor } from '../../types/CursorTypes'
import { RSheetsStyle } from '../../util/RSheetsStyle'
import { RSheetCellGroup } from './RSheetCellGroup'

export type SetCursor = Dispatch<SetStateAction<RSheetsCursor | undefined>>
type ColumnHeight = number[]
export type RowHeight = ColumnHeight[]

export const ROW_HEIGHT = 42
export const CELL_WIDTH = 150

const RSheetRowMemo = React.memo(RSheetRow, (previousProperties, nextProperties) => {
  const previousCount = previousProperties.columns.filter((c) => c.isStreaming !== false).length
  const nextCount = nextProperties.columns.filter((c) => c.isStreaming !== false).length
  if (previousCount !== nextCount) {
    return false
  }

  for (const [previous, next] of previousProperties.columns.zip(nextProperties.columns)) {
    if (
      previous.key !== next.key ||
      previous.visible !== next.visible ||
      previous.isFiltered !== next.isFiltered ||
      previous.isHighlighted !== next.isHighlighted ||
      previous.color !== next.color ||
      previous.sortState !== next.sortState ||
      previous.node.name !== next.node.name ||
      previous.node.path.join('/') !== next.node.path.join('/') ||
      previous.index !== next.index ||
      previous.nodeType !== next.nodeType ||
      previous.type !== next.type ||
      previous.metaType !== next.metaType ||
      previous.required !== next.required ||
      previous.measures !== next.measures ||
      previous.conditionalEffects !== next.conditionalEffects ||
      previous.cellBackgroundColor !== next.cellBackgroundColor ||
      previous.width !== next.width
    ) {
      return false
    }
  }

  return (
    previousProperties.row === nextProperties.row &&
    previousProperties.rowIndex === nextProperties.rowIndex &&
    previousProperties.columnIndexStart === nextProperties.columnIndexStart &&
    previousProperties.isFixed === nextProperties.isFixed &&
    previousProperties.visibleRowNum === nextProperties.visibleRowNum &&
    previousProperties.expandable === nextProperties.expandable &&
    previousProperties.expanded === nextProperties.expanded &&
    previousProperties.rowHeight === nextProperties.rowHeight &&
    previousProperties.latestFieldChangesRow.size === nextProperties.latestFieldChangesRow.size &&
    // eslint-disable-next-line unicorn/prefer-spread
    Array.from(previousProperties.latestFieldChangesRow).every(
      ([key, value]) => nextProperties.latestFieldChangesRow.get(key) === value,
    )
  )
})

export function RSheetRowMemoWrapper(properties: {
  columns: Array<RSheetColumn<RSheetRecordNode>> // このcolumnsはfilteredなケースがあるので、atomを使うのはNG
  row: RSheetRecordNode
  rowIndex: number
  columnIndexStart: number
  isFixed: boolean
  latestFieldChangesRow: Map<string, LatestFieldChangeItem>
}) {
  const { visibleRowNum, getInnerCells, expandable, expanded } = useRowRelatedSelector(properties.row.id!) // TODO
  const { rowHeight } = useRowHeightState()
  return (
    <RSheetRowMemo
      {...properties}
      visibleRowNum={visibleRowNum}
      getInnerCells={getInnerCells}
      expandable={expandable}
      expanded={expanded}
      width={properties.columns.map((x) => x.width ?? CELL_WIDTH).sum()} // 現状、非固定化列の場合にしか参照されないのでそれを利用してしまっている。本当は width とかいう名前にして固定化列か否かで値を分岐させるべき
      rowHeight={rowHeight}
    />
  )
}

export function RSheetRow({
  columns,
  row,
  rowIndex,
  columnIndexStart,
  isFixed,
  latestFieldChangesRow,
  visibleRowNum,
  getInnerCells,
  expandable,
  expanded,
  width,
  rowHeight,
}: {
  columns: Array<RSheetColumn<RSheetRecordNode>> // このcolumnsはfilteredなケースがあるので、atomを使うのはNG
  row: RSheetRecordNode
  rowIndex: number
  columnIndexStart: number
  isFixed: boolean
  latestFieldChangesRow: Map<string, LatestFieldChangeItem>
  visibleRowNum?: number
  getInnerCells: ReturnType<typeof useRowRelatedSelector>['getInnerCells']
  expandable: boolean
  expanded: boolean
  width: number
  rowHeight: number
}) {
  return (
    // Row
    <div
      className={`rsheets-row`}
      style={{
        minWidth: '100%',
        width,
        display: 'flex',
        height: Math.min(row.meta.height, visibleRowNum ?? 999_999) * rowHeight,
        overflow: 'hidden',
        borderBottom: `1px ${RSheetsStyle.color.borderBlack} solid`,
        borderRight: isFixed ? RSheetsStyle.border.rightEdgeColumn : undefined,
        // boxShadow: "1px 0px 8px 4px rgba(0, 0, 0, 0.2)"
      }}
      data-e2e={`rsheets-row-${rowIndex}`}
    >
      {/* column */}
      {columns.map((column, index) => {
        const columnIndex = index + columnIndexStart
        return (
          <RSheetCellGroup
            key={column.key}
            row={row}
            column={column}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            getSheetCellGroup={getInnerCells}
            expandable={expandable}
            expanded={expanded}
            latestFieldChangesRow={latestFieldChangesRow}
          />
        )
      })}
    </div>
  )
}

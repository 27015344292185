import { isPresent } from '@salescore/buff-common'
import dayjs, { isDayjs } from 'dayjs'
import { z } from 'zod'

import { compileExpression, type CoreDslParameter, isTruthy } from '../compile'
import type { DeprecatedValidationFunctionNode, FunctionNames, FunctionNode } from '../syntax/expression_ast'

export function compileFunction(node: FunctionNode, variable: CoreDslParameter): unknown {
  const arguments_ = node.args.map((argument) => compileExpression(argument, variable))
  return callFunction(node.name, arguments_)
}

export function compileDeprecatedFunction(node: DeprecatedValidationFunctionNode, variable: CoreDslParameter): unknown {
  const arguments_ = node.arguments.map((argument) => compileExpression(argument, variable))
  return callFunction(node.functionType, arguments_)
}

function callFunction(name: FunctionNames, arguments_: unknown[]): unknown {
  switch (name) {
    case 'add': {
      // TODO: ランタイムエラーで良いのか？また効率化できないか？
      const a = z.number().parse(arguments_[0])
      const b = z.number().parse(arguments_[1])
      return a + b
    }
    case 'present': {
      return isPresent(arguments_[0])
    }
    case 'blank': {
      return !isPresent(arguments_[0])
    }
    case 'equal': {
      const a = arguments_[0]
      const b = arguments_[1]

      if (typeof a === 'string' && typeof b === 'string') {
        const shouldConsiderCase = arguments_[2]
        // 多言語対応の規模が大きくなってきたら、localeCompareの使用を検討する
        return isTruthy(shouldConsiderCase) ? a === b : a.toLowerCase() === b.toLowerCase()
      }
      return a === b
    }
    case 'in': {
      const a = arguments_[0]
      const b = arguments_[1]
      if (!Array.isArray(b)) {
        return false
      }
      if (Array.isArray(a)) {
        return a.every((aa) => b.includes(aa))
      }
      return b.includes(a)
    }
    case 'include': {
      const a = arguments_[0]
      const b = arguments_[1]
      if (Array.isArray(a) && Array.isArray(b)) {
        return b.every((bb) => a.includes(bb))
      }
      if (Array.isArray(a)) {
        return a.includes(b)
      }
      if (typeof a === 'string' && typeof b === 'string') {
        const shouldConsiderCase = arguments_[2]
        // 多言語対応の規模が大きくなってきたら、localeCompareの使用を検討する
        return isTruthy(shouldConsiderCase) ? a.includes(b) : a.toLowerCase().includes(b.toLowerCase())
      }
      return false // TODO: エラーにすべきか
    }
    case 'startsWith': {
      const a = arguments_[0]
      const b = arguments_[1]

      if (typeof a === 'string' && typeof b === 'string') {
        const shouldConsiderCase = arguments_[2]
        // 多言語対応の規模が大きくなってきたら、localeCompareの使用を検討する
        return isTruthy(shouldConsiderCase) ? a.startsWith(b) : a.toLowerCase().startsWith(b.toLowerCase())
      }
      return false // TODO: エラーにすべきか
    }
    case 'overlap': {
      const a = arguments_[0]
      const b = arguments_[1]
      if (Array.isArray(a) && Array.isArray(b)) {
        return a.some((aa) => b.includes(aa))
      }
      return false // TODO: エラーにすべきか
    }
    case 'rank': {
      const a = arguments_[0]
      const b = arguments_[1]
      if (!Array.isArray(b)) {
        return false
      }
      return b.indexOf(a)
    }
    case 'never': {
      return false
    }
    case 'not': {
      const a = arguments_[0]
      return !isTruthy(a)
    }
    case 'and': {
      return arguments_.every((x) => isTruthy(x))
    }
    case 'or': {
      return arguments_.some((x) => isTruthy(x))
    }
    case 'date': {
      const x = arguments_[0]
      // undefined, null, 空文字列のときのdate関数の挙動、仕様が悩ましいが、この挙動が使いやすい
      // 「入力なし」の式が date(x) != null になるので、xがnull, undefined, 空文字列のときにnullにしたい
      if (x === null) {
        return null
      }
      if (typeof x !== 'string') {
        return null
      }
      if (x === '') {
        return null
      }
      return dayjs(x).startOf('day')
    }
    case 'year': {
      const x = arguments_[0]
      if (!isDayjs(x)) {
        return
      }
      return x.startOf('year')
    }
    case 'month': {
      const x = arguments_[0]
      if (!isDayjs(x)) {
        return
      }
      return x.startOf('month')
    }
    case 'week': {
      const x = arguments_[0]
      if (!isDayjs(x)) {
        return
      }
      // 週の開始曜日を月曜日に調整する
      const baseDate = x.day() === 0 ? x.subtract(1, 'day') : x
      return baseDate.startOf('week').add(1, 'day')
    }
    case 'today': {
      return dayjs().startOf('day')
    }
    case 'dateAdd': {
      const [date, diff, dateType] = arguments_
      if (date === undefined || diff === undefined || dateType === undefined) {
        return
      }
      if (!isDayjs(date) || typeof diff !== 'number' || typeof dateType !== 'string') {
        return
      }
      const parsedDateType = z.enum(['day', 'week', 'month', 'year']).safeParse(dateType)
      if (!parsedDateType.success) {
        return
      }
      return date.add(diff, parsedDateType.data)
    }
    default: {
      const x: never = name
      return null
    }
  }
}

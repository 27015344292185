import { BugOutlined, CheckCircleOutlined, CloudSyncOutlined, CoffeeOutlined, WarningOutlined } from '@ant-design/icons'
import { JobQueueStatusEnum } from '@salescore/client-api'
import { Tag } from 'antd'

const colors = {
  [JobQueueStatusEnum.Waiting]: 'gray',
  [JobQueueStatusEnum.Running]: 'blue',
  [JobQueueStatusEnum.Completed]: 'green',
  [JobQueueStatusEnum.Error]: 'red',
  [JobQueueStatusEnum.Killed]: 'red',
}

const icons = {
  [JobQueueStatusEnum.Waiting]: <CoffeeOutlined />,
  [JobQueueStatusEnum.Running]: <CloudSyncOutlined />,
  [JobQueueStatusEnum.Completed]: <CheckCircleOutlined />,
  [JobQueueStatusEnum.Error]: <BugOutlined />,
  [JobQueueStatusEnum.Killed]: <WarningOutlined />,
}

const JobQueueStatusIcon = ({ status }: { status: JobQueueStatusEnum }) => {
  return icons[status]
}

export const JobQueueStatusTag = ({ status }: { status: JobQueueStatusEnum }) => {
  return (
    <Tag icon={<JobQueueStatusIcon status={status} />} color={colors[status]}>
      {status}
    </Tag>
  )
}

import type {
  ModelSearcher,
  NodePath,
  ViewQueryField,
  ViewQueryList,
  ViewQueryRecordNode,
  ViewQueryTableNode,
} from '@salescore/core'
import { logger } from '@salescore/frontend-common'
import type { SetRecoilState } from 'recoil'

import { VIEW_NEW_RECORD_PREFIX } from '../../../domain/constant'
import { generateNewRootRecord, getInnerRecordNode, getRecordNodeFromRootNode } from '../../../state/nodeUtil'
import type { ViewRecordFieldChange } from '../../../state/useViewRecordsState/useChangesState'
import { upsertChildViewRecord } from './upsertViewRecords/upsertChildViewRecord'
import { upsertRootViewRecord } from './upsertViewRecords/upsertRootViewRecord'

export interface RecordChange {
  rowIndex: number // 何行目か
  innerRowIndex: number // 何行目か(内部行)
  nodePath: NodePath // ViewQueryNodeのパス
  field: ViewQueryField // フィールド情報
  value: unknown // 変更した値
  label: string | undefined // 参照項目の場合、変更後の値だけでなく変更後のラベルもchangeに渡す
  isDelete?: boolean // 削除の場合はtrue
  isDeleteAllChildren?: boolean // 自身の子レコードを全て削除する場合はtrue
  isNestedColumn: boolean // 内部行ならtrue（nodePathから求められるはず？不要な値な気がする）
}

//
// recordsを破壊的操作で更新し、変更結果changesを返す関数
// TODO: 新規作成系は既にaddEmptyRecordMutation関連で実装しており、ここで作成することはペースト系のみしかないはず。
//       ペーストのロジックを書き換え次第、ここのロジックをリファクタリングする。
//
export function upsertViewRecords({
  records,
  view,
  recordChanges,
  modelSearcher,
  set,
}: {
  records: ViewQueryRecordNode[]
  view: ViewQueryList
  recordChanges: RecordChange[]
  modelSearcher: ModelSearcher
  set: SetRecoilState
}): ViewRecordFieldChange[] {
  if (recordChanges.every((x) => x.isDelete)) {
    // （複数行）削除の場合は、 records の破壊的操作によりインデックスが崩れるのを防止するため、削除する行のインデックスが大きい順に削除する
    const reverseSortedRecordChanges = recordChanges.sort(
      (a, b) => b.rowIndex * 1000 * 1000 + b.innerRowIndex - (a.rowIndex * 1000 * 1000 + a.innerRowIndex),
    )
    return reverseSortedRecordChanges.flatMap((recordChange) =>
      upsertViewRecord(recordChange, records, view, modelSearcher, set),
    )
  }
  return recordChanges.flatMap((recordChange) => upsertViewRecord(recordChange, records, view, modelSearcher, set))
}

function upsertViewRecord(
  recordChange: RecordChange,
  records: ViewQueryRecordNode[],
  query: ViewQueryList,
  modelSearcher: ModelSearcher,
  set: SetRecoilState,
): ViewRecordFieldChange[] {
  const { rowIndex, innerRowIndex, field, value, label } = recordChange
  const changes: ViewRecordFieldChange[] = []

  // 今回の操作の対象となるルートのレコード(行)を取得
  const targetRootRecordWithChanges = getTargetRecord(records, rowIndex, query)
  // const rootRecordNode = r(targetRootRecordWithChanges.recordNode).clone().data
  const rootRecordNode = targetRootRecordWithChanges.recordNode
  changes.push(...targetRootRecordWithChanges.changes)

  // レコードの中の対象ノードを取得
  // 親要素の時レコード、子の時はレコードの配列
  const targetRecordNode = getRecordNodeFromRootNode(rootRecordNode, field.nodePath)

  // 対象ノードが存在しないことはありえないが、一応ガードしておく
  if (targetRecordNode === undefined) {
    logger.debug('targetRecordNode not found')
    return changes
  }
  if (Array.isArray(targetRecordNode)) {
    //
    // 子（ネストしたセル）の場合
    //
    const childRecordNode = getInnerRecordNode(targetRecordNode, innerRowIndex)
    const cs = upsertChildViewRecord(set, recordChange, query, childRecordNode, rootRecordNode, modelSearcher)
    return [...changes, ...cs]
  }
  const cs = upsertRootViewRecord(set, recordChange, query, rootRecordNode, records, modelSearcher)
  return [...changes, ...cs]
}

//
// 以下util系 TODO: 別ファイルに切り出し
//
export function getParentRecord(rootRecordNode: ViewQueryRecordNode, field: ViewQueryField, innerRowIndex: number) {
  if (field.nodePath.length === 1) {
    throw new Error(`parent of rootRecordNode must not be exist`)
  }

  const parentDepthRecords = getRecordNodeFromRootNode(rootRecordNode, field.nodePath.slice(0, -1))

  if (parentDepthRecords === undefined) {
    throw new Error(`parentDepthRecords must be exist`)
  }
  if (!Array.isArray(parentDepthRecords)) {
    if (field.nodePath.length !== 2) {
      throw new Error(`parentDepthRecords must be array`)
    }
    return parentDepthRecords
  }

  return getInnerRecordNode(parentDepthRecords, innerRowIndex)
}

function getTargetRecord(recordNodes: ViewQueryRecordNode[], rowIndex: number, query: ViewQueryList) {
  const changes: ViewRecordFieldChange[] = []
  //
  // 行がない場合は追加してから次の処理へ
  //
  if (recordNodes.length <= rowIndex) {
    const { newRecord, change } = generateNewRootRecord(query)
    if (newRecord === undefined) {
      throw new Error(`invalid new record generated`) // この文脈でundefinedなことはありえないはず
    }
    recordNodes.push(newRecord)
    if (change !== undefined) {
      changes.push(change)
    }
  }

  const recordNode = recordNodes[rowIndex]
  if (recordNode === undefined) {
    throw new Error(`target record not found`)
  }
  return {
    recordNode,
    changes,
  }
}

// 再起的に高さを更新
export function updateHeight(recordNode: ViewQueryRecordNode) {
  for (const tableNode of recordNode.children) {
    for (const childRecord of tableNode.children) {
      updateHeight(childRecord)
    }
  }

  const heights = recordNode.children.map((tableNode) => tableNode.children.map((x) => x.meta.height).sum())
  recordNode.meta.height = Math.max(1, ...heights)
}

export function isNewRecord(record: ViewQueryRecordNode) {
  return record.id === undefined || record.id.startsWith(VIEW_NEW_RECORD_PREFIX)
}

export function updateInnerRow(record: ViewQueryRecordNode) {
  const innerRowIndexStart = 0
  record.meta.innerRowIndexStart = innerRowIndexStart
  record.meta.innerRowIndexEnd = innerRowIndexStart + record.meta.height
  for (const child of record.children) {
    addInnerRowIndexMetadataToTableNode(child, innerRowIndexStart)
  }
}

function addInnerRowIndexMetadataToTableNode(tableNode: ViewQueryTableNode, parentStart: number) {
  const startIndice = tableNode.children.reduce(
    (accumulator, recordNode) => {
      return [...accumulator, accumulator.last()! + recordNode.meta.height]
    },
    [parentStart],
  )

  // eslint-disable-next-line unicorn/no-array-for-each
  tableNode.children.forEach((recordNode, index) => {
    const innerRowIndexStart = startIndice[index]!
    recordNode.meta.innerRowIndexStart = innerRowIndexStart
    recordNode.meta.innerRowIndexEnd = startIndice[index]! + recordNode.meta.height
    for (const child of recordNode.children) {
      addInnerRowIndexMetadataToTableNode(child, innerRowIndexStart)
    }
  })
}

import { isNull } from '@salescore/buff-common'
import { isSameNodeProperty, type ViewConfigField, type ViewConfigSheet, type ViewConfigSorter } from '@salescore/core'

import type { GoalConfig } from './types'

export function generateGoalViewConfig({
  pivotedGoalViewConfig,
  savedGoalConfig,
}: {
  pivotedGoalViewConfig: ViewConfigSheet
  savedGoalConfig: Pick<GoalConfig, 'config'>
}) {
  const savedConfig = savedGoalConfig.config
  if (savedConfig?.type !== 'sheet') {
    return pivotedGoalViewConfig
  }

  const fields = generateGoalViewConfigFields({
    fields: pivotedGoalViewConfig.fields,
    savedFields: savedConfig.fields,
  })

  const sorters = generateGoalViewConfigSorters({
    fields,
    savedSorters: savedConfig.sorters,
  })

  return {
    ...pivotedGoalViewConfig,
    fields,
    sorters,
    filterTree: savedConfig.filterTree,
    meta: savedConfig.meta,
  }
}

function generateGoalViewConfigFields({
  fields,
  savedFields,
}: {
  fields?: ViewConfigField[]
  savedFields?: ViewConfigField[]
}) {
  if (isNull(fields) || isNull(savedFields)) {
    return fields
  }
  const sortedFields = fields.sort((a, b) => {
    const aIndex = savedFields.findIndex((x) => isSameNodeProperty(x.property, a.property))
    const bIndex = savedFields.findIndex((x) => isSameNodeProperty(x.property, b.property))
    if (aIndex === -1) {
      return 1
    }
    if (bIndex === -1) {
      return -1
    }
    return aIndex - bIndex
  })
  return sortedFields.map((x) => {
    return {
      ...x,
      ...savedFields.find((y) => isSameNodeProperty(y.property, x.property)),
    }
  })
}

function generateGoalViewConfigSorters({
  fields,
  savedSorters,
}: {
  fields?: ViewConfigField[]
  savedSorters?: ViewConfigSorter[]
}) {
  return savedSorters?.filter((x) => {
    return fields?.some((y) => isSameNodeProperty(y.property, x.property))
  })
}

import { isPresent } from '@salescore/buff-common'
import { getOrganizationIdFromPath, Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { handleMe, SuspenseWithLoading, useV1ToV2Redirect } from '@salescore/client-common'
import { useRedirect } from '@salescore/frontend-common'
import { Layout } from 'antd'
import type { AppProps } from 'next/app'
import type { ReactNode } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { MemberLayoutBase } from './base/MemberLayoutBase'
import { Sider } from './member/Sider'

const v2DesignPaths = new Set([
  `/o/[organizationId]/v2/settings/goal_configs`,
  `/o/[organizationId]/v2/settings/elt_models/[modelName]/form`,
])

const Body = ({ Component, router }: AppProps): ReactNode => {
  const redirect = useRedirect()
  const { getRedirectPath } = useV1ToV2Redirect()

  const isViewRelated = ['/o/[organizationId]/views', '/o/[organizationId]/v2'].includes(router.pathname)
  const isV2Design = v2DesignPaths.has(router.pathname)

  return handleMe(() => {
    const redirectPath = getRedirectPath()
    if (isPresent(redirectPath)) {
      Posthog.track(POSTHOG_EVENTS.access_v1_url, {
        organizationId: getOrganizationIdFromPath(),
        redirectPath,
      })
      redirect(redirectPath)
      return <></>
    }

    return (
      <SuspenseWithLoading>
        <Layout
          style={{ height: '100%', backgroundColor: isV2Design ? 'white' : undefined }}
          className={isV2Design ? `v2-desgin` : ``}
        >
          {isViewRelated ? <></> : <Sider />}
          {/* {isMobile && <MobileHeader me={me}></MobileHeader> } TODO */}
          {/* インターコム分の余白をとるため、pb-16が必要だが、viewsなどで無駄になっている */}
          <Layout.Content className={`overflow-visible-print h-full overflow-y-auto ${isViewRelated ? '' : 'pb-16'}`}>
            <SwitchTransition>
              <CSSTransition key={router.asPath} timeout={200} classNames="page-transition">
                <SuspenseWithLoading>
                  <Component />
                </SuspenseWithLoading>
              </CSSTransition>
            </SwitchTransition>
          </Layout.Content>
        </Layout>
      </SuspenseWithLoading>
    )
  })
}

export const MemberLayout = (properties: AppProps) => {
  return (
    <MemberLayoutBase
      {...properties}
      body={(properties) => {
        return <Body {...properties} />
      }}
    />
  )
}

import {
  DeleteOutlined,
  EyeOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  SearchOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { faMessageLines } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isSome, isTruthy } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import type { ViewQueryRecordNode } from '@salescore/core'
import { ButtonWithTooltip, organizationHasFeature, useModalAtom } from '@salescore/frontend-common'
import { Button, Dropdown } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { KpiAdditionForm } from '../../../components/view_ui/ViewUIKpiPivot/KpiAdditionForm'
import { convertLeadFormModalAtom, modelRecordsSnapshotsDrawerAtom } from '../../../recoil/navigation/atoms'
import { useNavigationModal, useRowConfigState } from '../../../recoil/navigation/hooks'
import { useAddEmptyRecordMutation } from '../../../recoil/records/mutations/addEmptyRecordMutation'
import { useMeValue, useViewAbilityValue, useViewValue } from '../../../recoil/view/hooks'
import { useViewsRelated } from '../../../recoil/view/selectors/viewsRelatedSelector'
import { contextAtom, openSheetThreadsFormAtom } from '../../recoil/atoms'
import { usePasteWithNewRecord } from '../../recoil/hooks/usePasteWithNewRecord'
import { useClickCurrentCursorMutation } from '../../recoil/mutations/cursor/clickCurrentCursorMutation'
import { getArea, useCursorMutation } from '../../recoil/mutations/useCursorMutation'
import { useRSheetMutation } from '../../recoil/mutations/useRSheetMutation'
import { useCursorSelector } from '../../recoil/selectors/cursorSelector'
import { useRowRelatedSelector } from '../../recoil/selectors/rowRelatedSelector'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import type { RSheetsCursor } from '../../types/CursorTypes'
import { getRecordNodeWithInnerRowIndex } from '../../util/innerRowRelated'
import { RSheetsStyle } from '../../util/RSheetsStyle'
import { ContextMenu as CommonContextMenu, useKpiContextMenu } from '../common/ContextMenu'

export function ContextMenu() {
  const context = useRecoilValue(contextAtom)
  const { cursor, column, row } = useCursorSelector()
  const cursorMutation = useCursorMutation()
  const { debugModal, drillDownModal } = useNavigationModal() // modal系を余計にsubscribeしているので、パフォーマンスが問題になれば個別に参照
  const { isAdmin } = useMeValue()
  const [rowConfig, setRowConfig] = useRowConfigState(row?.id ?? '')
  const rowRelated = useRowRelatedSelector(row?.id ?? '')
  const modelRecordsSnapshotsDrawer = useModalAtom(modelRecordsSnapshotsDrawerAtom)
  const convertLeadFormModal = useModalAtom(convertLeadFormModalAtom)
  const mutation = useRSheetMutation()
  const [addKpiDropdownVisible, setAddKpiDropdownVisible] = useState(false)
  const record =
    row !== undefined && column !== undefined && cursor !== undefined
      ? getRecordNodeWithInnerRowIndex(row, column.node, cursor.main.innerRowIndex)
      : undefined
  const cellValue =
    column?.index !== undefined && cursor?.main !== undefined
      ? rowRelated.getSheetCell(column.index, cursor.main.innerRowIndex)?.value
      : undefined
  const kpiId = context.kpis?.find((x) => x.id === cellValue)?.id
  const kpiContextMenu = useKpiContextMenu({
    kpiId,
    hideContextMenu: () => {
      cursorMutation.hideContextMenu()
    },
    setAddKpiDropdownVisible,
    addKpiDirection: 'down',
  })

  if (context.isGoalView === true) {
    return <></>
  }

  const contextMenuPosition = cursor?.contextMenuPosition
  if (contextMenuPosition === undefined || row === undefined || column === undefined || cursor === undefined) {
    return <></>
  }

  if (context.asKpiTable === true) {
    if (column.type !== 'rowKpi') {
      return <></>
    }
    return (
      <>
        <CommonContextMenu
          menu={kpiContextMenu}
          hideContextMenu={() => {
            setAddKpiDropdownVisible(false)
            cursorMutation.hideContextMenu()
          }}
          style={{
            position: 'fixed',
            width: 180,
            left: contextMenuPosition.x + 10,
            top: contextMenuPosition.y - 10,
          }}
        />
        {addKpiDropdownVisible && kpiId !== undefined && (
          <div
            className="disable-on-click-outside absolute top-0 bg-white p-2"
            style={{
              position: 'fixed',
              width: 320,
              left: contextMenuPosition.x + 191, // right: 0もありだが、固定カラムの右隣で実行すると被る問題がz-indexの関係的に解決できなかった
              top: contextMenuPosition.y - 10,
              backgroundColor: 'white',
              boxShadow: '0 2px 6px 2px rgb(60 64 67 / 15%)',
              borderRadius: 2,
              zIndex: RSheetsStyle.zIndex.contextMenu,
            }}
          >
            <KpiAdditionForm
              hide={() => {
                setAddKpiDropdownVisible(false)
                cursorMutation.hideContextMenu()
              }}
              prevKpiId={kpiId}
            />
          </div>
        )}
      </>
    )
  }

  const deleteRecordsMenuContents = (
    <>
      <DropdownDeleteButton
        column={column}
        onDelete={async () => {
          if (cursor.expand === undefined) {
            return
          }
          const { up, upInnerRowIndex, down, downInnerRowIndex } = getArea(cursor.main, cursor.expand)
          await mutation.onDestroyRowsFromContextMenu(column, up, upInnerRowIndex, down, downInnerRowIndex, false)
        }}
        onDeleteWithAllChildren={async () => {
          if (cursor.expand === undefined) {
            return
          }
          const { up, upInnerRowIndex, down, downInnerRowIndex } = getArea(cursor.main, cursor.expand)
          await mutation.onDestroyRowsFromContextMenu(column, up, upInnerRowIndex, down, downInnerRowIndex, true)
        }}
      >
        <Button className="w-full text-left" type="text" icon={<DeleteOutlined />} danger>
          {t(`削除`)}
        </Button>
      </DropdownDeleteButton>
    </>
  )

  const nonDeleteRecordsMenuContents = (
    <>
      {rowRelated.expandable && rowConfig?.expanded !== true && (
        <Button
          className="w-full text-left"
          type="text"
          icon={<EyeOutlined />}
          onClick={() => {
            setRowConfig((x) => ({
              ...x,
              expanded: true,
            }))
          }}
        >
          {t(`子レコードを全て表示`)}
        </Button>
      )}
      {rowRelated.expandable && rowConfig?.expanded === true && (
        <Button
          className="w-full text-left"
          type="text"
          icon={<EyeOutlined />}
          onClick={() => {
            setRowConfig((x) => ({
              ...x,
              expanded: false,
            }))
          }}
        >
          {t(`子レコードの表示を制限`)}
        </Button>
      )}
      <RecordRelated recordNode={record?.recordNode} column={column} mainCursor={cursor.main} />
      {column.configField !== undefined &&
        column.configField.property.modelName === 'salesforce_lead' &&
        isSome(record?.recordNode?.id) && (
          <Button
            className="w-full text-left"
            type="text"
            icon={<PlayCircleOutlined />}
            onClick={() => {
              convertLeadFormModal.showModal({
                leadIds: [record.recordNode!.id!],
              })
              cursorMutation.hideContextMenu()
            }}
          >
            {t(`取引を開始`)}
          </Button>
        )}
      {isAdmin && (
        <Button
          className={`w-full text-left ${CONSTANT.colorClasses.ADMIN_TEXT_COLOR}`}
          type="text"
          icon={<EyeOutlined />}
          onClick={() => {
            debugModal.showModal({
              content: record?.recordNode,
            })
            cursorMutation.hideContextMenu()
          }}
        >
          {t(`レコード表示`)}
        </Button>
      )}
    </>
  )

  return (
    <div
      style={{
        position: 'fixed',
        width: 220,
        left: contextMenuPosition.x + 10,
        top: contextMenuPosition.y - 10,
        backgroundColor: 'white',
        boxShadow: '0 2px 6px 2px rgb(60 64 67 / 15%)',
        borderRadius: 2,
        zIndex: RSheetsStyle.zIndex.contextMenu,
      }}
    >
      {cursor.expand !== undefined && column.deletable !== false
        ? deleteRecordsMenuContents
        : nonDeleteRecordsMenuContents}
    </div>
  )
}

function RecordRelated({
  recordNode,
  mainCursor,
  column,
}: {
  recordNode: RSheetRecordNode | undefined
  mainCursor: RSheetsCursor['main']
  column: RSheetColumn<RSheetRecordNode>
}) {
  const me = useMeValue()
  const view = useViewValue()
  const { views } = useViewsRelated()
  const mutation = useRSheetMutation()
  const cursorMutation = useCursorMutation()
  const clickCurrentCursorMutation = useClickCurrentCursorMutation()
  const { addRootRecord, addChildRecord } = useAddEmptyRecordMutation()
  const ability = useViewAbilityValue()
  const paste = usePasteWithNewRecord()
  const [openSheetThreadsForm, setOpenSheetThreadsForm] = useRecoilState(openSheetThreadsFormAtom)
  const commentFeatureEnabled = organizationHasFeature(me.organization, 'enable_slack_comment')
  // NOTE: NX様専用フラグ(レコードの追加/削除をメニューから非表示にするために使用)
  const enableNxOnlyFeature =
    organizationHasFeature(me.organization, 'enable_nx_only_feature') && column.node.name === 'salesforce_opportunity'

  if (column.nodeType === 'root' || column.nodeType === undefined) {
    return (
      <>
        {!enableNxOnlyFeature && (
          <>
            <ButtonWithTooltip
              className="w-full text-left"
              disabled={!ability.canSaveRecord || !ability.canCreateNewRecord}
              showTooltip={!ability.canSaveRecord || !ability.canCreateNewRecord}
              tooltipTitle={t(`権限がないため、追加できません`)}
              type="text"
              icon={<PlusOutlined />}
              onClick={async () => {
                const rowIndex = mainCursor.rowIndex
                const newRowIndex = await addRootRecord({ rowIndex: mainCursor.rowIndex })
                if (newRowIndex === undefined) {
                  // 権限の問題で失敗したということのはず
                  return
                }
                cursorMutation.moveToRowIndexAndEditMode(newRowIndex, column)
              }}
            >
              {t(`レコードの追加`)}
            </ButtonWithTooltip>
            <ButtonWithTooltip
              className="w-full text-left"
              disabled={!ability.canSaveRecord || !ability.canCreateNewRecord}
              showTooltip={!ability.canSaveRecord || !ability.canCreateNewRecord}
              tooltipTitle={t(`権限がないため、追加できません`)}
              type="text"
              icon={<PlusSquareOutlined />}
              onClick={async () => {
                await paste({
                  rowIndex: mainCursor.rowIndex,
                  innerRowIndex: mainCursor.innerRowIndex,
                  node: column.node,
                })
              }}
            >
              {t(`レコードを追加してペースト`)}
            </ButtonWithTooltip>
            <DropdownDeleteButton
              column={column}
              onDelete={() => {
                void mutation.onDestroyFromContextMenu(column)
              }}
              onDeleteWithAllChildren={() => {
                void mutation.onDestroyFromContextMenu(column, true)
              }}
            >
              <ButtonWithTooltip
                className="w-full text-left"
                disabled={!ability.canSaveRecord || !ability.canDeleteRecord || column.deletable === false}
                showTooltip={!ability.canSaveRecord || !ability.canDeleteRecord}
                tooltipTitle={t(`権限がないため、削除できません`)}
                type="text"
                danger
                icon={<DeleteOutlined />}
              >
                {t('削除')}
              </ButtonWithTooltip>
            </DropdownDeleteButton>
          </>
        )}
        {commentFeatureEnabled && views.first()?.type === 'sheet' && (
          <ButtonWithTooltip
            className="w-full text-left"
            type="text"
            disabled={!commentFeatureEnabled}
            showTooltip={!commentFeatureEnabled}
            tooltipTitle={t(`コメント機能が有効化されていません`)}
            icon={<FontAwesomeIcon icon={faMessageLines} />}
            onClick={() => {
              const recordId = recordNode?.id
              if (recordId === undefined) {
                return
              }
              setOpenSheetThreadsForm([
                { recordId },
                ...openSheetThreadsForm.filter((form) => form.recordId !== recordId),
              ])
              cursorMutation.hideContextMenu()
            }}
          >
            {t(`レコードにコメントする`)}
          </ButtonWithTooltip>
        )}
      </>
    )
  }

  if (column.nodeType === 'children') {
    return (
      <>
        {!enableNxOnlyFeature && (
          <>
            <ButtonWithTooltip
              className="w-full text-left"
              disabled={!ability.canSaveRecord || !ability.canCreateNewRecord}
              showTooltip={!ability.canSaveRecord || !ability.canCreateNewRecord}
              tooltipTitle={t(`権限がないため、追加できません`)}
              type="text"
              icon={<PlusOutlined />}
              onClick={async () => {
                // eslint-disable-next-line @typescript-eslint/init-declarations
                let recordNode: ViewQueryRecordNode | undefined
                await addChildRecord({
                  rowIndex: mainCursor.rowIndex,
                  innerRowIndex: mainCursor.innerRowIndex,
                  node: column.node,
                  onAfterInsert(x) {
                    recordNode = x // recoilのstate更新はpure functionである必要があり、ここで直接setCursorを呼ぶことができないため、汚い実装だがこの形で更新したレコードを取得する
                  },
                })
                if (recordNode !== undefined) {
                  cursorMutation.setCursor((x) => ({
                    main: {
                      ...mainCursor,
                      innerRowIndex: recordNode!.meta.innerRowIndexStart,
                    },
                  }))
                }
              }}
            >
              {t(`レコードの追加`)}
            </ButtonWithTooltip>
            <ButtonWithTooltip
              className="w-full text-left"
              disabled={!ability.canSaveRecord || !ability.canCreateNewRecord}
              showTooltip={!ability.canSaveRecord || !ability.canCreateNewRecord}
              tooltipTitle={t(`権限がないため、追加できません`)}
              type="text"
              icon={<PlusSquareOutlined />}
              onClick={async () => {
                await paste({
                  rowIndex: mainCursor.rowIndex,
                  innerRowIndex: mainCursor.innerRowIndex,
                  node: column.node,
                })
              }}
            >
              {t(`レコードを追加してペースト`)}
            </ButtonWithTooltip>
          </>
        )}
        {recordNode !== undefined && !enableNxOnlyFeature && (
          <>
            <DropdownDeleteButton
              column={column}
              onDelete={() => {
                void mutation.onDestroyFromContextMenu(column)
              }}
              onDeleteWithAllChildren={() => {
                void mutation.onDestroyFromContextMenu(column, true)
              }}
            >
              <ButtonWithTooltip
                className="w-full text-left"
                disabled={!ability.canSaveRecord || !ability.canDeleteRecord || column.deletable === false}
                showTooltip={!ability.canSaveRecord || !ability.canDeleteRecord}
                tooltipTitle={t(`権限がないため、削除できません`)}
                type="text"
                danger
                icon={<DeleteOutlined />}
              >
                {t(`削除`)}
              </ButtonWithTooltip>
            </DropdownDeleteButton>
          </>
        )}
      </>
    )
  }

  if (column.nodeType === 'parent') {
    if (recordNode === undefined) {
      return (
        <>
          <Button
            className="w-full text-left"
            type="text"
            icon={<SearchOutlined />}
            onClick={() => {
              clickCurrentCursorMutation({})
            }}
          >
            {t(`レコードの参照`)}
          </Button>
        </>
      )
    }
    return (
      <>
        <Button
          className="w-full text-left"
          type="text"
          icon={<StopOutlined />}
          onClick={() => {
            mutation.removeRelation(column)
          }}
        >
          {t(`参照の解除`)}
        </Button>
        {!enableNxOnlyFeature && (
          <>
            <DropdownDeleteButton
              column={column}
              onDelete={() => {
                void mutation.onDestroyFromContextMenu(column)
              }}
              onDeleteWithAllChildren={() => {
                void mutation.onDestroyFromContextMenu(column, true)
              }}
            >
              <ButtonWithTooltip
                className="w-full text-left"
                disabled={!ability.canSaveRecord || !ability.canDeleteRecord || column.deletable === false}
                showTooltip={!ability.canSaveRecord || !ability.canDeleteRecord}
                tooltipTitle={t(`権限がないため、削除できません`)}
                type="text"
                danger
                icon={<DeleteOutlined />}
              >
                {t(`削除`)}
              </ButtonWithTooltip>
            </DropdownDeleteButton>
          </>
        )}
      </>
    )
  }

  return <></>
}

function DropdownDeleteButton({
  children,
  column,
  onDelete,
  onDeleteWithAllChildren,
}: {
  children: ReactNode
  column: RSheetColumn<RSheetRecordNode>
  onDelete: () => void | Promise<void>
  onDeleteWithAllChildren: () => void | Promise<void>
}): ReactNode {
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: '1',
            label: (
              <span
                className="inline-block w-full text-red-500"
                onClick={() => {
                  void onDelete()
                }}
              >
                {t(`レコードの削除`)}
              </span>
            ),
          },
          isTruthy(column.node.children?.isPresent())
            ? {
                key: '2',
                label: (
                  <span
                    className="inline-block w-full text-red-500"
                    onClick={() => {
                      void onDeleteWithAllChildren()
                    }}
                  >
                    {t(`レコードの削除(子孫含む)`)}
                  </span>
                ),
              }
            : undefined,
        ].compact(),
      }}
      placement="topRight"
      trigger={['click']}
      dropdownRender={(item) => {
        return <div className="translate-x-full translate-y-full">{item}</div>
      }}
    >
      {children}
    </Dropdown>
  )
}

import {
  CalendarOutlined,
  CodeSandboxOutlined,
  FieldTimeOutlined,
  FontSizeOutlined,
  NumberOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { PropertyTypeEnum } from '@salescore/client-api'
import { Tag } from 'antd'

const propertyTypes: PropertyTypeEnum[] = [
  PropertyTypeEnum.String,
  PropertyTypeEnum.Numeric,
  PropertyTypeEnum.Integer,
  PropertyTypeEnum.Date,
  PropertyTypeEnum.Datetime,
  PropertyTypeEnum.Time,
  PropertyTypeEnum.Boolean,
  PropertyTypeEnum.Array,
  PropertyTypeEnum.Object,
]

const COLORS: { [k in PropertyTypeEnum]: string } = {
  [PropertyTypeEnum.String]: 'blue',
  [PropertyTypeEnum.Numeric]: 'green',
  [PropertyTypeEnum.Integer]: 'green',
  [PropertyTypeEnum.Date]: 'yellow',
  [PropertyTypeEnum.Datetime]: 'yellow',
  [PropertyTypeEnum.Time]: 'yellow',
  [PropertyTypeEnum.Boolean]: 'default',
  [PropertyTypeEnum.Array]: 'purple',
  [PropertyTypeEnum.Object]: 'purple',
}

export const HUB_PROPERTY_TYPE_ICONS = {
  [PropertyTypeEnum.String]: <FontSizeOutlined />,
  [PropertyTypeEnum.Numeric]: <NumberOutlined />,
  [PropertyTypeEnum.Integer]: <NumberOutlined />,
  [PropertyTypeEnum.Date]: <CalendarOutlined />,
  [PropertyTypeEnum.Datetime]: <FieldTimeOutlined />,
  [PropertyTypeEnum.Time]: <FieldTimeOutlined />,
  [PropertyTypeEnum.Boolean]: <QuestionCircleOutlined />,
  [PropertyTypeEnum.Array]: <UnorderedListOutlined />,
  [PropertyTypeEnum.Object]: <CodeSandboxOutlined />,
}

export const HUB_PROPERTY_TYPE_LABEL: { [k in PropertyTypeEnum]: string } = {
  [PropertyTypeEnum.String]: 'テキスト',
  [PropertyTypeEnum.Numeric]: '数値',
  [PropertyTypeEnum.Integer]: '整数',
  [PropertyTypeEnum.Date]: '日付',
  [PropertyTypeEnum.Datetime]: '日時',
  [PropertyTypeEnum.Time]: '時刻',
  [PropertyTypeEnum.Boolean]: '真偽値',
  [PropertyTypeEnum.Array]: '配列',
  [PropertyTypeEnum.Object]: 'オブジェクト',
}

export const propertyTypeOptions = propertyTypes.map((x) => ({
  value: x,
  label: HUB_PROPERTY_TYPE_LABEL[x],
}))

export const PropertyTypeTag = ({ type }: { type: PropertyTypeEnum | undefined | null }) => {
  if (type === undefined || type === null) {
    return <></>
  }
  return (
    <Tag className="text-md px-3" color={COLORS[type] ?? 'default'} icon={HUB_PROPERTY_TYPE_ICONS[type]}>
      {HUB_PROPERTY_TYPE_LABEL[type] ?? type}
    </Tag>
  )
}

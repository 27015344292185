import type { ViewUIFormList } from '@salescore/core'
import { Empty, Form, Row, Space } from 'antd'
import type { FormListFieldData } from 'antd/es/form/FormList'
import * as React from 'react'

import { ViewUIComponentC } from './ViewUIComponent'

export const ViewUIFormListContext = React.createContext<{
  formListField: FormListFieldData | undefined
}>({
  formListField: undefined,
})

export function ViewUIFormListC({ component }: { component: ViewUIFormList }) {
  return (
    <div className="mt-6 rounded-lg bg-gray-100 px-4 py-2">
      <Form.List name={component.path}>
        {(fields, { add, remove }) => (
          <div>
            <Space>
              <div className="mb-2 font-bold">{component.label}</div>
            </Space>
            {fields.map((formListField, index) => (
              <Row gutter={16} className="mb-4 rounded-lg border border-solid border-gray-100 bg-white p-3">
                <ViewUIFormListContext.Provider value={{ formListField }}>
                  {component.children.map((child) => {
                    return <ViewUIComponentC component={child} />
                  })}
                </ViewUIFormListContext.Provider>
              </Row>
            ))}
            {fields.length === 0 && <Empty className="mb-4" />}
          </div>
        )}
      </Form.List>
    </div>
  )
}

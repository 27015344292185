import type { DataNode } from 'antd/es/tree'
import { selector, useRecoilValue } from 'recoil'

import { favoritesAtom, privateViewGroupTreeAtom, viewGroupTreeAtom } from '../atoms'
import { generateViewGroupTreeKey } from '../functions/convertFlatNodesToAntdTree'

const favoriteRelatedSelector = selector({
  key: 'navigation/favoriteRelatedSelector',
  get: ({ get }) => {
    const favorites = get(favoritesAtom)
    const viewGroupTree = get(viewGroupTreeAtom)
    const privateViewGroupTree = get(privateViewGroupTreeAtom)
    // treeを全操作して、favoritesに相当するノードをpushする(TODO: 非効率すぎるようであれば要検討)
    const favoritedNodes = favorites
      .map((fav) => {
        // eslint-disable-next-line unicorn/no-array-reduce
        const tree = viewGroupTree.reduce(
          (searchResult, node) => {
            // viewId !== viewGroupIdが常に成り立つ前提でdfs
            return searchResult ?? dfs(node, fav.viewId ?? generateViewGroupTreeKey(fav.viewGroupId ?? ''))
          },
          // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
          undefined as DataNode | undefined,
        )
        // eslint-disable-next-line unicorn/no-array-reduce
        const privateTree = privateViewGroupTree.reduce(
          (searchResult, node) => {
            // viewId !== viewGroupIdが常に成り立つ前提でdfs
            return searchResult ?? dfs(node, fav.viewId ?? generateViewGroupTreeKey(fav.viewGroupId ?? ''))
          },
          // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
          undefined as DataNode | undefined,
        )
        // keyがuniqueになるように、全てのkeyにfavのidを追記
        return addPrefix(tree ?? privateTree, fav.id)
      })
      .compact()

    return {
      favorites,
      favoritedNodes,
      findFavorite: (id: string, type: 'view' | 'viewGroup') => {
        return favorites.find((x) => (type === 'view' ? x.viewId : x.viewGroupId) === id)
      },
    }
  },
})

function addPrefix<T extends DataNode | undefined>(node: T, prefix: string): T {
  if (node === undefined) {
    return undefined as T
  }

  return {
    ...node,
    key: [prefix, node.key].join('_'), // removePrefixと統一すること
    children: (node.children ?? []).map((child) => addPrefix(child, prefix)),
  }
}

export function removePrefix(key: string | number | bigint) {
  if (typeof key === 'string') {
    return key.split('_').last()
  }
}

export const useFavoriteRelated = () => {
  return useRecoilValue(favoriteRelatedSelector)
}

function dfs(node: DataNode, key: string): DataNode | undefined {
  if (node.key === key) {
    return node
  }

  // eslint-disable-next-line unicorn/no-array-reduce
  return (node.children ?? []).reduce(
    (searchReuslt, child) => {
      return searchReuslt ?? dfs(child, key)
    },
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    undefined as DataNode | undefined,
  )
}

import { calculateCharLength } from '@salescore/frontend-common'
import { InputNumber } from 'antd'
import { useRef, useState } from 'react'

import { useUpsertSheetRowMutation } from '../../../../recoil/mutations/upsertSheetRowMutation'
import { useCursorSelector } from '../../../../recoil/selectors/cursorSelector'
import { getHeaderColor } from '../../../header/RSheetsNodeBlockHeader'
import type { RSheetsCellInputRenderTypeArgument } from '../InputCell'
import { RSheetsInputCellWrapper } from './InputCellWrapper'

export function RSheetsCellNumberInput({
  recordNode,
  column,
  rowIndex,
  innerRowIndex,
  defaultWidth,
  finishEditMode,
  boxStyle,
}: RSheetsCellInputRenderTypeArgument) {
  const { cursor } = useCursorSelector()
  const columnOnChange = useUpsertSheetRowMutation()
  const initialValue =
    cursor?.editing?.initialPressedKey ?? (recordNode === undefined ? undefined : column.value(recordNode))
  const [value, setValue] = useState(initialValue)
  const reference = useRef<HTMLInputElement>(null)

  setTimeout(() => {
    if (reference.current) {
      reference.current.focus()
    }
  }, 1)

  return RSheetsInputCellWrapper(
    {
      async onChangeFixed() {
        await columnOnChange({ value, rowIndex, innerRowIndex, column })
      },
      finishEditMode,
    },
    ({ handleKeyDown }) => {
      return (
        <div
          style={{
            ...boxStyle,
            // Spreadsheetと似たロジックで、中身の文字列が増えたら幅を広げて全部表示させる。
            // 他のセルの位置に影響を与えずこれを実現するため、position: absoluteにして実現している
            width: Math.max(defaultWidth, calculateCharLength(value as string) * 10),
            padding: '4px 8px', // padingYはボーダーにかぶらなければなんでも良い
            marginTop: 1, // これがないと1pxずれるが、Cell側と比べてなぜ1px分の差があるのか不明
            marginLeft: 1,
            // boxShadow: '1px 4px 7px 2px rgb(60 64 67 / 15%)',
          }}
        >
          <InputNumber
            ref={reference}
            bordered={false}
            value={value as string} // TODO
            onChange={(value) => {
              setValue(value)
            }}
            onKeyDown={(e) => {
              handleKeyDown(e)
            }}
            style={{
              // backgroundColor: 'white',
              backgroundColor: getHeaderColor(column.color).cellColor,
              padding: 0,
              width: '100%',
              height: '100%', // 複数行にしたい場合は対応が必要
              // width: Math.max(defaultWidth - 20, calculateCharLength(value as string) * 10)
              // width: 400,
            }}
          />
        </div>
      )
    },
  )
}

import { ApolloError, getApolloContext } from '@apollo/client'
import { openIntercom } from '@salescore/client-base'
import { useRedirect } from '@salescore/frontend-common'
import { Button, Card, message, Result } from 'antd'
import { t } from 'i18next'
import Link from 'next/link'
import { type ReactNode, Suspense, useContext, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { CardContainer } from '../../components/CardContainer'

export const HandleQuery = ({
  children,
  loadingElement,
}: {
  children: ReactNode
  loadingElement?: ReactNode
}): ReactNode => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        if (error instanceof ApolloError) {
          return <ApolloErrorComponent error={error} />
        } else if (error instanceof Error) {
          return <span>Error: {error.message}</span>
        } else {
          return <span>something went wrong</span>
        }
      }}
    >
      <Suspense fallback={loadingElement ?? <></>}>{children}</Suspense>
    </ErrorBoundary>
  )
}

function ApolloErrorComponent({ error }: { error: ApolloError }): ReactNode {
  openIntercom({})
  const redirect = useRedirect()
  // TODO: errorProviderに依存しない方が良い？
  const { component, effect } = handleApolloError({ error, redirect })

  useEffect(() => {
    if (effect !== undefined) {
      effect()
    }
  }, [])

  // TODO: エラー表示まともにしたい
  return (
    <div className="mt-16 flex justify-center align-middle">
      <Card style={{ width: 600 }}>{component}</Card>
    </div>
  )
}

interface HandledError {
  component: ReactNode
  effect?: () => void
}

function handleApolloError({
  error,
  redirect,
}: {
  error: ApolloError
  redirect: (path: string) => void
}): HandledError {
  const { client } = useContext(getApolloContext())

  if (error.message.includes('Unauthorized')) {
    return {
      component: (
        <Result
          status="403"
          title=""
          subTitle={`不正な認証情報です。再度ログインし直してください。 ${error.message}`}
          extra={
            <div className="text-center">
              <Link href="/sign_out" legacyBehavior>
                <Button type="default">ログイン画面へ</Button>
              </Link>
            </div>
          }
        />
      ),
      effect() {
        // 古いキャッシュを読み込み続けてエラーになるケースがあるため、キャッシュクリアする
        void client?.resetStore()

        if (error.message.startsWith(`この組織へのアクセス権限がありません`)) {
          void message.error(t(`権限がありません。トップページにリダイレクトします。`))
          redirect('/')
        }

        if (error.message.includes(`トークンの有効期限が切れています`)) {
          void message.error(t(`認証の有効期限が切れました。再ログインしてください`))
          redirect('/sign_out')
        }
        if (error.message.startsWith(`存在しないアカウントです`)) {
          // void message.error(`アカウントがありません。リダイレクトします。`) // 新規登録時もここを通りうるのでメッセージを表示しない
          // notifyBugsnag({ error: new UnauthorizedApolloError(error), user: myIdentity, severity: 'info' }) // /sign_upで登録できなければエラーを通知
          redirect('/sign_up')
        }
        if (error.message === 'Unauthorized: User not found') {
          void message.error(t(`SALESCOREの利用には管理者からの登録が必要です。`))
          redirect('/error?type=user_not_found')
        }
      },
    }
  }

  if (error.message.includes('Forbidden')) {
    return {
      component: (
        <CardContainer withLogo={false} size="medium">
          <Result
            status="403"
            title="この操作は許可されていません"
            extra={
              <div className="text-center">
                <Link href="/" legacyBehavior>
                  <Button type="primary">ホーム画面へ</Button>
                </Link>
                <br />
                <br />
                <Link href="/sign_out" legacyBehavior>
                  <Button type="default">ログアウト</Button>
                </Link>
              </div>
            }
          />
        </CardContainer>
      ),
      effect() {
        //  empty
      },
    }
  }

  if (error.message.includes('Not Found') || error.message.includes('NotFound')) {
    return {
      effect() {
        // empty
      },
      component: (
        <Result
          status="404"
          title="このリソースは存在しません"
          extra={
            <div className="text-center">
              <Link href="/" legacyBehavior>
                <Button type="primary">ホーム画面へ</Button>
              </Link>
              <br />
              <br />
              <Link href="/sign_out" legacyBehavior>
                <Button type="default">ログイン画面へ</Button>
              </Link>
            </div>
          }
        />
      ),
    }
  }

  if (error.message.includes('メールアドレス認証が完了していません')) {
    return {
      effect() {
        redirect('/error?type=waiting_verification')
      },
      component: <></>,
    }
  }

  return {
    component: (
      <Result
        status="500"
        title={error.message}
        extra={
          <div className="text-center">
            <Link href="/" legacyBehavior>
              <Button type="primary">ホーム画面へ</Button>
            </Link>
            <br />
            <br />
            <Link href="/sign_out" legacyBehavior>
              <Button type="default">ログイン画面へ</Button>
            </Link>
          </div>
        }
      />
    ),
  }
}

import { useSuspenseQuery } from '@apollo/client'
import type { RecordChangeHistoryFieldsFragment } from '@salescore/client-api'
import { FetchRecordChangeHistoriesDocument } from '@salescore/client-api'
import { CONSTANT } from '@salescore/client-base'
import { SuspenseWithLoading, UserAvatar } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { generateSalesforceHistoryModelName } from '@salescore/core'
import { useModalAtom } from '@salescore/frontend-common'
import { Alert, Drawer, Empty, Timeline } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'

import { modelRecordsSnapshotsDrawerAtom } from '../../../recoil/navigation/atoms'
import { useConnectionsSelector } from '../../../recoil/view/selectors/connectionsSelector'
import { useUsersSelector } from '../../../recoil/view/selectors/usersSelector'

export function RecordChangeHistoriesDrawer() {
  const drawer = useModalAtom(modelRecordsSnapshotsDrawerAtom)

  return (
    <Drawer
      open={drawer.isModalVisible}
      onClose={drawer.hideModal}
      title={
        drawer.content?.columnName === undefined
          ? t(`履歴`)
          : t(`{{columnName}}の履歴`, { columnName: drawer.content.columnName })
      }
    >
      <SuspenseWithLoading>
        <>{drawer.content !== undefined && <BodyWrapper {...drawer.content} />}</>
      </SuspenseWithLoading>
    </Drawer>
  )
}

interface Properties {
  id: string
  modelName: string
  propertyNames: string[]
}

function BodyWrapper(properties_: Properties) {
  const { id, modelName, propertyNames } = properties_
  const { getModelProperty, getModel } = useConnectionsSelector()
  const properties = useMemo(() => {
    return propertyNames.map((propertyName) => getModelProperty(modelName, propertyName)).compact()
  }, [propertyNames])
  if (!modelName.startsWith(`salesforce`)) {
    return <Alert type="warning" message={<div>{t(`この機能はSalesforceのみで有効です。`)}</div>} />
  }

  // trackableな項目があるかをチェック
  const trackableProperites = properties.filter((x) => x.trackable === true)
  if (trackableProperites.isBlank()) {
    return (
      <Alert
        type="warning"
        message={
          <div>
            {t(`Salesforceで項目履歴管理が有効になっていません。`)}
            <br />
            {t(`ハイライト条件機能を使うには、項目履歴管理を有効にする必要があります。`)}
            <br />
            <Trans>
              <a href={CONSTANT.link.helpForHighlight} target="_blank" rel="noopener noreferrer">
                こちら
              </a>
              を参考に設定を行ってください。
            </Trans>
          </div>
        }
      />
    )
  }
  const historyModel = getModel(generateSalesforceHistoryModelName(modelName))
  if (historyModel === undefined) {
    return (
      <div>
        <Alert
          type="warning"
          message={
            <div>
              {t(`履歴オブジェクトが同期されていません。`)}
              <br />
              {t(`ハイライト条件機能を使うには、履歴オブジェクトを同期する必要があります。`)}
              <br />
              <Trans>
                <a href={CONSTANT.link.helpForHighlight} target="_blank" rel="noopener noreferrer">
                  こちら
                </a>
                を参考に設定を行ってください。
              </Trans>
            </div>
          }
        />
      </div>
    )
  }

  return <Body {...properties_} />
}

function Body({ id, modelName, propertyNames }: Properties) {
  const me = recoil.global.useMe()
  const { data } = useSuspenseQuery(FetchRecordChangeHistoriesDocument, {
    variables: {
      recordId: id,
      modelName,
      propertyNames,
      organizationId: me.organization.id,
    },
  })
  const snapshots = data.recordChangeHistories
  const { getModelProperty } = useConnectionsSelector()
  const properties = useMemo(() => {
    return propertyNames.map((propertyName) => getModelProperty(modelName, propertyName)).compact()
  }, [propertyNames])

  if (snapshots.isBlank()) {
    return (
      <Empty
        description={
          <div>
            {/* TODO: ヘルプへの導線 */}
            {properties.some((x) => x.trackable !== true) ? (
              <span>
                <Trans>
                  履歴項目設定が有効ではないため
                  <br />
                  履歴は表示されません。
                </Trans>
              </span>
            ) : (
              <>{t(`履歴がありません`)}</>
            )}
          </div>
        }
      />
    )
  }

  return (
    <div>
      <Timeline
        items={[
          {
            children: (
              <div>
                <span className="font-bold">{t(`最新`)}</span>
                <SnapshotItem snapshot={snapshots.first()!} />
              </div>
            ),
          },
          snapshots.length > 1
            ? {
                children: (
                  <div>
                    <span className="font-bold">
                      {t(`{{length}}件の保存したバージョン`, { length: snapshots.length - 1 })}
                    </span>
                    {snapshots.slice(1).map((snapshot) => {
                      return <SnapshotItem snapshot={snapshot} />
                    })}
                  </div>
                ),
              }
            : undefined,
        ].compact()}
      />
    </div>
  )
}

function SnapshotItem({ snapshot }: { snapshot: RecordChangeHistoryFieldsFragment }) {
  return (
    <div className="mb-5 mt-3">
      <div className="flex justify-between align-middle">
        <div className="font-bold">{dayjs(snapshot.changedAt).format(`YYYY年MM月DD日 HH:mm`)}</div>
        <User salesforceId={snapshot.changedById} fallbackName={snapshot.changedByName ?? t(`不明なユーザー`)} />
      </div>
      <div className="font-bold" style={{ color: '#707070' }}>
        {snapshot.newValue}
      </div>
    </div>
  )
}

function User({ salesforceId, fallbackName }: { salesforceId: string | undefined; fallbackName: string }) {
  const { salesforceUsersMapper } = useUsersSelector()
  const resourceUser = salesforceId === undefined ? undefined : salesforceUsersMapper[salesforceId]?.resourceUser
  const user = resourceUser ?? {
    id: ``,
    name: fallbackName,
  }

  return (
    <div className="flex">
      <UserAvatar user={user} size={'small'} />
      <span className="ml-1">{user.name}</span>
    </div>
  )
}

import type { ViewQueryRecordNodeWithParent } from '@salescore/core'
import type { CSSProperties } from 'react'

import type { FinishEditMode, RSheetColumn, RSheetRecordNode } from '../../../types'
import { RSheetsCellBooleanInput } from './input/BooleanInputCell'
import { RSheetsCellDateInput } from './input/DateInputCell'
import { RSheetsCellDateTimeInput } from './input/DateTimeInputCell'
import { RSheetsCellMultiSelectOptionInput } from './input/MultiSelectOptionInputCell'
import { RSheetsCellNumberInput } from './input/NumberInputCell'
import { RSheetsCellRelationInput } from './input/RelationInputCell'
import { RSheetsCellSelectOptionInput } from './input/SelectOptionInputCell'
import { RSheetsCellStirngInput } from './input/StirngInputCell'
import { RSheetsCellTimeInput } from './input/TimeInputCell'

export interface RSheetsCellInputRenderTypeArgument {
  recordNode: ViewQueryRecordNodeWithParent | undefined
  parentRecordNode: RSheetRecordNode | undefined
  rootRecordNode: RSheetRecordNode
  column: RSheetColumn<RSheetRecordNode>
  rowIndex: number
  innerRowIndex: number
  defaultWidth: number
  boxStyle: CSSProperties
  finishEditMode: FinishEditMode
}

export function InputCell(argument: RSheetsCellInputRenderTypeArgument) {
  const { column, recordNode } = argument
  if (recordNode === undefined && column.nodeType === 'parent') {
    return <RSheetsCellRelationInput {...argument} emptyCell />
  }
  // root,childrenのときは、普通に元のセルのinputを表示する
  if (column.metaType === 'relation') {
    return <RSheetsCellRelationInput {...argument} />
  }

  if ((column.selectOptions ?? []).length > 0) {
    if (column.metaType === 'multi_select') {
      return <RSheetsCellMultiSelectOptionInput {...argument} />
    }
    return <RSheetsCellSelectOptionInput {...argument} />
  }

  switch (column.type) {
    case 'integer' as const: {
      return <RSheetsCellNumberInput {...argument} />
    }
    case 'numeric' as const: {
      return <RSheetsCellNumberInput {...argument} />
    }
    case 'date' as const: {
      return <RSheetsCellDateInput {...argument} />
    }
    case 'datetime' as const: {
      return <RSheetsCellDateTimeInput {...argument} />
    }
    case 'time' as const: {
      return <RSheetsCellTimeInput {...argument} />
    }
    case 'boolean' as const: {
      return <RSheetsCellBooleanInput {...argument} />
    }
    default: {
      return <RSheetsCellStirngInput {...argument} />
    }
  }
}

import { isSome } from '@salescore/buff-common'
import { Col, Form, Row } from 'antd'
import { t } from 'i18next'

import { NodePropertyPicker, type NodeWithModel } from '../common/NodePropertyPicker'
import { ViewFilterOptionFormItem } from './ViewFilterOptionFormItem'
import type { FilterFormItemState } from './ViewFilterTreeForm'
import { ViewFilterTypeFormItem } from './ViewFilterTypeFormItem'
import { ViewFilterValueFormItem } from './ViewFilterValueFormItem'

export const ViewFilterFormItems = ({
  onChange,
  filter,
  propertiesWithNode,
  children,
}: {
  children: JSX.Element
  filter: FilterFormItemState
  onChange: (x: FilterFormItemState, option?: { asInitialization?: boolean }) => void
  propertiesWithNode: NodeWithModel[]
}) => {
  // if (filter.type === 'field') {
  //   // 現状のロジックだとありえないはず
  //   return <></>
  // }

  const property =
    filter.property === undefined
      ? undefined
      : propertiesWithNode.find(
          (p) => p.model.name === filter.property?.modelName && p.property.name === filter.property.propertyName,
        )?.property
  const spans = [isSome(property?.referenceTo) ? 5 : 8, 3, 6, 10]

  return (
    <Row justify="space-between" align="middle">
      <Col span={23}>
        <Row justify="start" align="middle" gutter={8}>
          <Col span={spans[0]}>
            <Form.Item label={t(`絞り込み項目`)} rules={[{ required: true, message: t(`入力してください`) }]}>
              <NodePropertyPicker
                propertiesWithNode={propertiesWithNode}
                picked={filter.property}
                onFinish={(property) => {
                  onChange({
                    // 不整合を防ぐため、propertyが変わったらその他の値も全て削除する
                    property,
                  })
                }}
              />
            </Form.Item>
          </Col>
          {isSome(property?.referenceTo) && (
            <Col span={spans[1]}>
              <ViewFilterOptionFormItem filter={filter} property={property} onChange={onChange} />
            </Col>
          )}
          <Col span={spans[2]}>
            <ViewFilterTypeFormItem filter={filter} property={property} onChange={onChange} />
          </Col>
          <Col span={spans[3]}>
            <ViewFilterValueFormItem filter={filter} property={property} onChange={onChange} />
          </Col>
        </Row>
      </Col>
      <Col span={1}>{children}</Col>
    </Row>
  )
}

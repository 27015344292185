import type { DataNode, EventDataNode } from 'antd/es/tree'
import type { NodeDragEventParams } from 'rc-tree/es/contextTypes'

type ExtendedDataNode<T> = Omit<DataNode, 'children'> & {
  item: T
  children: Array<ExtendedDataNode<T>> | undefined
}
export type ExtendedDataNodeTree<T> = Array<ExtendedDataNode<T>>
type Result<T> =
  | {
      node: ExtendedDataNode<T>
      parentIndex: number
      parentNode: ExtendedDataNodeTree<T>
    }
  | undefined

type Key = string | number | bigint
type DragEvent = NodeDragEventParams & {
  dragNode: EventDataNode<DataNode>
  dragNodesKeys: Key[]
  dropPosition: number
  dropToGap: boolean
}

const dfs = <T>(nodes: ExtendedDataNodeTree<T>, key: string | number | bigint): Result<T> => {
  // 全ての子ツリーについて探索
  for (let index = 0; index < nodes.length; index++) {
    // 対象のキーだったらcallbackを発火
    if (nodes[index]?.key === key) {
      return {
        node: nodes[index]!,
        parentIndex: index,
        parentNode: nodes,
      }
    }
    const children = nodes[index]?.children

    // 深さ優先探索
    if (children === undefined) {
      continue
    }

    const result = dfs(children, key)
    if (result !== undefined) {
      return result
    }
  }
}

const getDescendantIds = <T>(childNode: ExtendedDataNode<T>): Key[] => {
  return [childNode.key, ...(childNode.children ?? []).flatMap((x) => getDescendantIds(x))]
}

export const convertTreeToItems = <T>({
  tree,
  parentPropertyName,
  parentId,
  depth = 1,
}: {
  tree: Array<ExtendedDataNode<T>>
  parentId?: Key | undefined
  parentPropertyName: string
  depth?: number
}) => {
  return tree.flatMap((node): Array<T & { descendantIds: Key[]; depth: number }> => {
    return [
      {
        ...node.item,
        [parentPropertyName]: parentId,
        depth,
        descendantIds: (node.children ?? []).flatMap((x) => getDescendantIds(x)),
      },
      ...convertTreeToItems({ tree: node.children ?? [], parentId: node.key, parentPropertyName, depth: depth + 1 }),
    ]
  })
}

export const getNewTree = <T>(oldTree: ExtendedDataNodeTree<T>, info: DragEvent) => {
  const tree = [...oldTree]
  const dragKey = info.dragNode.key // dragされたnodeのkey
  const dropKey = info.node.key // drop先のnodeのkey
  const dropPos = info.node.pos.split('-') // drop先のnodeの何番目に挿入されたか
  const dropPosition = info.dropPosition - Number(dropPos.at(-1))

  // dragしたnodeを探索
  const searchResult = dfs(tree, dragKey)

  // ありえないはずだが、探索して見つからなかったら元のをreturn
  if (searchResult === undefined) {
    return oldTree
  }
  searchResult.parentNode.splice(searchResult.parentIndex, 1)
  const draggedNode = searchResult.node

  if (!info.dropToGap) {
    // 子要素にいれるとき
    const droppedNode = dfs(tree, dropKey)?.node
    // ありえないはず
    if (droppedNode === undefined) {
      return oldTree
    }
    droppedNode.children = droppedNode.children ?? []
    droppedNode.children.unshift(draggedNode)
  } else if (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
    ((info as any).node.props.children || []).length > 0 && // Has children
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
    (info as any).node.props.expanded && // Is expanded
    dropPosition === 1 // On the bottom gap
  ) {
    const droppedNode = dfs(tree, dropKey)?.node
    // ありえないはず
    if (droppedNode === undefined) {
      return oldTree
    }
    droppedNode.children = droppedNode.children ?? []
    droppedNode.children.unshift(draggedNode)
  } else {
    const result = dfs(tree, dropKey)
    // ありえないはず
    if (result === undefined) {
      return oldTree
    }

    if (dropPosition === -1) {
      result.parentNode.splice(result.parentIndex, 0, draggedNode)
    } else {
      result.parentNode.splice(result.parentIndex + 1, 0, draggedNode)
    }
  }

  return tree
}

import { QuestionCircleOutlined } from '@ant-design/icons'
import type { ModelProperty } from '@salescore/core'
import { Form, Select, Tooltip } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'

import type { FilterFormItemState } from './ViewFilterTreeForm'

export const ViewFilterOptionFormItem = ({
  filter,
  property,
  onChange,
}: {
  onChange: (x: FilterFormItemState) => void
  filter: FilterFormItemState
  property: ModelProperty | undefined
}) => {
  // if (filter.type === 'field') {
  //   return <></>
  // }

  useEffect(() => {
    if (filter.option?.shouldFilterToReferencedEntityName === undefined) {
      onChange({
        ...filter,
        option: {
          shouldFilterToReferencedEntityName: filter.filterType !== 'equal_me', // equal_meが選択されているときだけデフォルトはID
        },
      })
    }
  }, [])
  return (
    <Form.Item
      label={
        <Tooltip
          title={
            <span>
              {t(`参照項目を絞り込み項目に使う際は、IDで絞り込むか参照先の名前で絞り込むかを選択できます。`)}
              <br />
              {t(
                `（例）「会社名」という参照項目で絞り込む時、「株式会社Buff」という名前で絞り込みたい時は「名前」を選択してください。「0011e00000GDzPxAAL」というIDで絞り込みたい時は「ID」を選択してください。`,
              )}
            </span>
          }
          placement="bottom"
        >
          <span className="mr-1">{t(`対象`)}</span>
          <QuestionCircleOutlined />
        </Tooltip>
      }
      rules={[{ required: true, message: t(`入力してください`) }]}
    >
      <Select
        // デフォルトはnameとする。「デフォルトがnameである」というロジックが、core側でハードコーディングされているので注意
        // compileSheetViewConfigFilterTree参照。
        // このロジックに、equal_me周りのViewFilterTypeFormItemのロジックが依存しているので注意
        value={filter.option?.shouldFilterToReferencedEntityName === false ? 'id' : 'name'}
        onChange={(value) => {
          if (value === 'id') {
            onChange({
              ...filter,
              option: {
                shouldFilterToReferencedEntityName: false,
              },
            })
            return
          }
          onChange({
            ...filter,
            option: {
              shouldFilterToReferencedEntityName: true,
            },
          })
        }}
        options={[
          {
            value: 'name',
            label: t(`名前`),
          },
          {
            value: 'id',
            label: 'ID',
          },
        ]}
      />
    </Form.Item>
  )
}

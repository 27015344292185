import { useQuery } from '@apollo/client'
import { isNull, isSome } from '@salescore/buff-common'
import { FetchMeDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { i18nTranslations } from '@salescore/i18n'
import dayjs, { type ConfigType } from 'dayjs'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { I18nextProvider, initReactI18next } from 'react-i18next'

void i18n
  .use(LanguageDetector) // デフォルトで、ブラウザの言語設定に応じた設定を行う。一度でもページを開くと、以前の言語設定の値がキャッシュに保持される
  .use(initReactI18next)
  .init({
    resources: {
      // 自分の用意した翻訳ファイルを任意の場所から読み込む
      ja: {
        translation: i18nTranslations.ja,
      },
      en: {
        translation: i18nTranslations.en,
      },
    },
    fallbackLng: 'ja', // 翻訳が見つからなかったときに表示される言語
    interpolation: {
      escapeValue: false,
    },
    nsSeparator: false,
  })

i18n.services.formatter?.add('datestr', (value: ConfigType, lng, options) => {
  if (isNull(value)) {
    return ''
  }

  if (lng === 'en') {
    return dayjs(value).format(`YYYY-MM-DD`)
  }
  return dayjs(value).format(`YYYY年M月D日`)
})

i18n.services.formatter?.add('datetimestr', (value: ConfigType, lng, options) => {
  if (isNull(value)) {
    return ''
  }

  if (lng === 'en') {
    return dayjs(value).format(`YYYY-MM-DD HH:mm`)
  }
  return dayjs(value).format(`YYYY年M月D日 HH時mm分`)
})

export const I18nProviderWithNoAuth = ({ children }: { children: JSX.Element }) => {
  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      {children}
    </I18nextProvider>
  )
}

export const I18nProvider = ({ children }: { children: JSX.Element }) => {
  const fetchMeQuery = useQuery(FetchMeDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })

  if (isSome(fetchMeQuery.data) && isSome(fetchMeQuery.data.myUser.lang)) {
    // ユーザーの言語設定に応じて、i18nの設定を切り替える
    // LanguageDetectorを使っているとこの変更がlocalStorageに保存されるので、
    // 以降はこのロジックを通らなくても、以前に切り替えた言語設定が使用される
    void i18n.changeLanguage(fetchMeQuery.data.myUser.lang)
  }

  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      {children}
    </I18nextProvider>
  )
}

import { getArea } from '../recoil/mutations/useCursorMutation'
import type { RSheetColumn, RSheetRecordNode } from '../types'
import type { RSheetsCursorPosition } from '../types/CursorTypes'
import { getRecordNodesWithInnerRowIndex } from '../util/innerRowRelated'

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type CellValue = unknown | unknown[]
type ValuesPerColumn = CellValue[]

const transpose = (a: unknown[][]) => a[0]!.map((_, c) => a.map((r) => r[c]))

// ネストしたセルへの対応
const convertCopiedValuesToStringValues = <T extends RSheetRecordNode>(
  columns: Array<RSheetColumn<T>>,
  rows: ValuesPerColumn[],
) => {
  // 全てのカラムが同じノードのとき、単純な行列としてコピーする
  if (columns.map((x) => x.node.path.join(',')).unique().length === 1) {
    const transporsed = columns.map((_, columnIndex) => {
      return rows.flatMap((row) => row[columnIndex]) // ネストしたセルの場合は展開する
    })
    return transpose(transporsed)
  }

  // カラム数1のとき
  if (columns.length === 1) {
    // 選択されたカラムがネストしたカラムだった場合は、flattenして返す
    if (columns[0]!.node.path.length > 1) {
      // eslint-disable-next-line unicorn/no-magic-array-flat-depth
      const flattenRows = rows.every((row) => Array.isArray(row[0])) ? rows.flat(2).map((x) => [x]) : rows
      return flattenRows.map((row) => row.map((columnValue) => convertCopiedValueToStringValue(columnValue)))
    }
    // そうでないとき、普通にrowsをstringに変換してから返す
    return rows.map((row) => row.map((columnValue) => convertCopiedValueToStringValue(columnValue)))
  }

  // カラム数が2以上で、ネストしたカラムを含んでいる場合、ネストしたカラムはJSON値にした上で返す
  return rows.map((row) => {
    return row.map((columnValue: CellValue, index) => {
      const column = columns[index]
      if (column === undefined) {
        return convertCopiedValueToStringValue(columnValue)
      }
      if (Array.isArray(columnValue) && column.node.path.length > 1) {
        return JSON.stringify(columnValue.map((x) => convertCopiedValueToStringValue(x)))
      }
      return convertCopiedValueToStringValue(columnValue)
    })
  })
}

function convertCopiedValueToStringValue(x: unknown): string {
  if (x === null || x === undefined) {
    return ''
  }
  if (Array.isArray(x)) {
    return JSON.stringify(x)
  }
  if (typeof x === 'string') {
    if (x.includes(`\n`)) {
      return JSON.stringify(x)
    }
    return x
  }

  return x as string
}

export const sliceValuesForCopy = (
  data: RSheetRecordNode[],
  columns: Array<RSheetColumn<RSheetRecordNode>>,
  cursor: {
    main: RSheetsCursorPosition
    expand?: RSheetsCursorPosition
  },
) => {
  if (cursor === undefined) {
    return
  }
  const { left, right, up, down, upInnerRowIndex, downInnerRowIndex } = getArea(
    cursor.main,
    cursor.expand ?? cursor.main,
  )
  const selectedRows = data.slice(up, down + 1)
  const selectedColumns = columns.slice(left, right + 1)
  // 行ごとにコピーするべき値を算出
  const selectedRowValues = selectedRows.map((row, rowIndex): ValuesPerColumn => {
    // ある行の列ごとにコピーするべき値を算出
    return selectedColumns.map((column): CellValue => {
      const nodesWithIndex = getRecordNodesWithInnerRowIndex(row, column.node)

      // ネストしたカラムの場合、innerRowIndexを考慮してコピーする（このとき、レスポンスは配列になりうる）
      if (column.node.path.length > 1) {
        // 行の境界の中であれば、全てコピー
        if (rowIndex > 0 && rowIndex < selectedRows.length - 1) {
          return nodesWithIndex
            .map((x) => x.recordNode)
            .map((x) => (x === undefined ? null : (column.label ?? column.value)(x)))
          // 行の境界の先頭
        }
        if (rowIndex === 0) {
          // 1行のみ選択されている場合
          if (selectedRows.length === 1) {
            const targetNodes = nodesWithIndex.filter(
              (x) => upInnerRowIndex <= x.innerRowIndexStart && x.innerRowIndexStart <= downInnerRowIndex,
            )
            return targetNodes
              .map((x) => x.recordNode)
              .map((x) => (x === undefined ? null : (column.label ?? column.value)(x)))
          }
          return nodesWithIndex
            .map((x) => x.recordNode)
            .map((x) => (x === undefined ? null : (column.label ?? column.value)(x)))

          // 行の境界の最後
        }
        const targetNodes = nodesWithIndex.filter((x) => x.innerRowIndexStart < downInnerRowIndex + 1)
        return targetNodes
          .map((x) => x.recordNode)
          .map((x) => (x === undefined ? null : (column.label ?? column.value)(x)))
      }
      // ネストしていない場合は単にvalueをコピー(先頭行で問題ないはず)
      return nodesWithIndex
        .map((x) => x.recordNode)
        .map((x) => (x === undefined ? null : (column.label ?? column.value)(x)))
        .first()
    })
  })

  const stringValues = convertCopiedValuesToStringValues(selectedColumns, selectedRowValues)
  return stringValues
}

export const copy = (
  data: RSheetRecordNode[],
  columns: Array<RSheetColumn<RSheetRecordNode>>,
  cursor: {
    main: RSheetsCursorPosition
    expand?: RSheetsCursorPosition
  },
) => {
  const values = sliceValuesForCopy(data, columns, cursor)
  const text = (values ?? [])
    .map((value) => value.join('\t'))
    .join('\n')
    .replaceAll('\r', '') // \rがあると各所で問題になる？ので注意。この挙動を変えるのであればusePaste周りも変更すること。
  return {
    values,
    text,
  }
}

import { isNull } from '@salescore/buff-common'
import { searchParentViewGroups } from '@salescore/client-common'
import type { SheetConfigOrReference, ViewConfig } from '@salescore/core'
import { selector, useRecoilValue } from 'recoil'

import { viewAtom, viewsContextAtom } from '../atoms'

//
// TODO: recoil-pagesとviewsでatoms,selectorが被っている
//
export const viewsRelatedSelector = selector({
  key: 'view/viewsRelatedSelector',
  get: ({ get }) => {
    const view = get(viewAtom)
    const context = get(viewsContextAtom)
    const viewGroups = context.viewGroups
    const views = context.views
    const viewsForSider = context.viewsForSider
    const viewsMap = views.groupBy((x) => x.id).transformValues((vs) => vs.first()!).data
    const viewGroupsMap = viewGroups.groupBy((x) => x.id).transformValues((vs) => vs.first()!).data
    const viewByType = viewsForSider.groupBy((x) => x.type).data
    const kpiViews = viewByType.kpi ?? []
    const viewGroupsWithParents = viewGroups.map((viewGroup) => {
      return {
        viewGroup,
        parents: searchParentViewGroups(viewGroup.viewGroupId, viewGroupsMap),
      }
    })

    return {
      views,
      viewGroups,
      kpiViews,
      viewGroupsWithParents,
      // viewAtomの型定義が怪しい書き方しているのが根本原因か
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      parentViewGroups: searchParentViewGroups(view?.viewGroupId, viewGroupsMap), // viewがundefinedなことがあるらしい
      searchConfigIfReference: (maybeReference: SheetConfigOrReference | undefined): ViewConfig | undefined => {
        if (maybeReference === undefined) {
          return undefined
        }
        switch (maybeReference.type) {
          case 'reference': {
            return viewsMap[maybeReference.viewId]?.config
          }
          case 'sheet': {
            return maybeReference
          }
        }
      },
      searchView: (viewId: string | undefined) => {
        if (viewId === undefined) {
          return
        }
        return viewsMap[viewId]
      },
      searchViewGroup: (viewGroupId: string | undefined | null) => {
        if (isNull(viewGroupId)) {
          return
        }
        return viewGroupsMap[viewGroupId]
      },
      searchParentViewGroups: (viewGroupId: string | undefined | null) => {
        return searchParentViewGroups(viewGroupId, viewGroupsMap)
      },
    }
  },
})

export const useViewsRelated = () => {
  return useRecoilValue(viewsRelatedSelector)
}

import { useApolloClient } from '@apollo/client'
import { isPresent } from '@salescore/buff-common'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import type { SelectOption } from '@salescore/core'
import { Select } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { z } from 'zod'

import { useUpsertSheetRowMutation } from '../../../../recoil/mutations/upsertSheetRowMutation'
import type { RSheetsCellInputRenderTypeArgument } from '../InputCell'
import { RSheetsInputCellWrapper } from './InputCellWrapper'
import { getOptions, getOptionsBySql } from './SelectOptionInputCell'

export function RSheetsCellMultiSelectOptionInput({
  recordNode,
  parentRecordNode,
  column,
  rowIndex,
  innerRowIndex,
  defaultWidth,
  finishEditMode,
  boxStyle,
}: RSheetsCellInputRenderTypeArgument) {
  const columnOnChange = useUpsertSheetRowMutation()
  const [value, setValue] = useState<Array<string | number>>(() => {
    const value = recordNode === undefined ? undefined : column.value(recordNode)
    const values = Array.isArray(value) ? value : []
    const validated = z.union([z.string(), z.number()]).array().safeParse(values)
    // 2023/05 ELT側の不具合で、Salesforceの複数選択項目が空のとき、['']という1要素の配列になってしまう
    // ELT側で対応すべき処理だが、即時の対応が難しかったため、一旦こちらで対応
    // 同様の処理をCellBodyでもやっている
    // https://github.com/salescore-inc/issues/issues/198
    return validated.success ? validated.data.filter((v) => isPresent(v)) : []
  })
  const reference = useRef<HTMLInputElement>(null)

  setTimeout(() => {
    if (reference.current) {
      reference.current.focus()
    }
  }, 200)

  const [options, setOptions] = useState<SelectOption[]>([])
  const client = useApolloClient()
  const setOptionsAsync = async () => {
    const xs =
      column.dynamicSelectOptionConfig === undefined
        ? getOptions({
            nodeName: column.node.name,
            options: column.selectOptions ?? [],
            recordNode,
            parent: parentRecordNode,
          })
        : await getOptionsBySql({
            recordNode,
            client,
            organizationId: getOrganizationIdFromPath(),
            config: column.dynamicSelectOptionConfig,
          })
    setOptions(xs)
  }
  useEffect(() => {
    void setOptionsAsync()
  }, [])

  const onChangeFixed = async () => {
    await columnOnChange({ value, rowIndex, innerRowIndex, column })
  }

  return RSheetsInputCellWrapper(
    {
      onChangeFixed,
      finishEditMode,
    },
    ({ handleKeyDown }) => {
      return (
        <div
          style={{
            ...boxStyle,
            // Spreadsheetと似たロジックで、中身の文字列が増えたら幅を広げて全部表示させる。
            // 他のセルの位置に影響を与えずこれを実現するため、position: absoluteにして実現している
            // width: '100%',
            width: defaultWidth,
            minWidth: 200,
            backgroundColor: 'white',
            paddingTop: 3,
            marginTop: 1, // これがないと1pxずれるが、Cell側と比べてなぜ1px分の差があるのか不明
            marginLeft: 1,
            // boxShadow: '1px 4px 7px 2px rgb(60 64 67 / 15%)',
          }}
        >
          <div className="absolute left-0 top-0 flex pl-2 pt-2">
            {/* <>
              {value.map((v) => (
                <SelectOptionTag value={v} selectOptions={options} />
              ))}
            </> */}
          </div>
          <Select
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                // enterで選択したいので、Enterはここでハンドリングしない
              } else if (e.key === 'Escape') {
                await onChangeFixed()
                finishEditMode()
                e.stopPropagation()
              } else {
                handleKeyDown(e)
              }
            }}
            value={value}
            mode="multiple"
            maxTagCount="responsive"
            allowClear
            autoFocus={true}
            defaultOpen={true}
            bordered={false}
            showSearch
            options={options}
            style={{
              width: '100%',
              minWidth: 200,
            }}
            // もともとここをオーバーライドし、自前でタグをレンダリングしていたが
            // 検索と相性が悪いのでやめる
            // tagRender={() => {
            //   return <></>
            // }}
            onChange={(changed) => {
              setValue(changed)
            }}
          />
        </div>
      )
    },
  )
}

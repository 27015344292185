import { SaveOutlined, WarningOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useQuery } from '@apollo/client'
import { isNull, isPresent, isSome, isTruthy } from '@salescore/buff-common'
import { FetchPoliciesDocument, type PolicyUserRoleFieldsFragment } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { policyActionSchema } from '@salescore/features'
import { Button, Form, Input, Row, Space, Switch, Tooltip, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { z } from 'zod'

export const PolicyFormSchema = z.object({
  label: z.string(),
  description: z.string().optional(),
  actionsWithPermission: z.object({
    [policyActionSchema.Values['sheet-create']]: z.boolean().optional(),
    [policyActionSchema.Values['sheet-update']]: z.boolean().optional(),
    [policyActionSchema.Values['sheet-delete']]: z.boolean().optional(),
    [policyActionSchema.Values['sheet-create-new-record']]: z.boolean().optional(),
    [policyActionSchema.Values['sheet-save-record']]: z.boolean().optional(),
    [policyActionSchema.Values['sheet-delete-record']]: z.boolean().optional(),
    [policyActionSchema.Values['sheet-open-relation-input-form']]: z.boolean().optional(),
    [policyActionSchema.Values['sheet-download-csv']]: z.boolean().optional(),
    [policyActionSchema.Values['convert-lead']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-create']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-update']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-delete']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-create-new-record']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-save-record']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-delete-record']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-open-relation-input-form']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-download-csv']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-pivot-create']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-pivot-update']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-pivot-delete']]: z.boolean().optional(),
    [policyActionSchema.Values['kpi-pivot-download-csv']]: z.boolean().optional(),
    [policyActionSchema.Values['view-group-create']]: z.boolean().optional(),
    [policyActionSchema.Values['view-group-delete']]: z.boolean().optional(),
    [policyActionSchema.Values['manage-organization-setting']]: z.boolean().optional(),
    [policyActionSchema.Values['manage-connection']]: z.boolean().optional(),
    [policyActionSchema.Values['manage-goal']]: z.boolean().optional(),
    [policyActionSchema.Values['manage-dimension-group']]: z.boolean().optional(),
    [policyActionSchema.Values['manage-users']]: z.boolean().optional(),
    [policyActionSchema.Values['manage-user-groups']]: z.boolean().optional(),
    [policyActionSchema.Values['manage-invitation']]: z.boolean().optional(),
    [policyActionSchema.Values['read-user-activity']]: z.boolean().optional(),
  }),
})
export type PolicyFormValue = z.infer<typeof PolicyFormSchema>

export const PolicyForm = ({
  onFinish,
  loading,
  policyUserRole,
}: {
  onFinish: (value: PolicyFormValue) => Promise<void>
  loading: boolean
  policyUserRole?: PolicyUserRoleFieldsFragment
}) => {
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(isNull(policyUserRole))
  const initialValue = createInitialValue(policyUserRole)
  const { Text } = Typography
  const [form] = useForm()
  const [isAllChecked, setIsAllChecked] = useState<boolean | undefined>()

  useEffect(() => {
    if (isAllChecked === undefined) {
      form.setFieldsValue(initialValue)
    }
  }, [initialValue])

  return (
    <PageHeader>
      <Form form={form} onFinish={onFinish} initialValues={initialValue}>
        <Form.Item name="label" rules={[{ required: true, message: t(`権限セット名を入力してください`) }]}>
          <Input
            size="large"
            bordered={false}
            placeholder={t(`権限セット名を入力`)}
            style={{ fontSize: 24, fontWeight: 'bold' }}
            maxLength={120}
            onChange={(e) => {
              if (isPresent(e.target.value)) {
                setIsCreateButtonDisabled(false)
              } else {
                setIsCreateButtonDisabled(true)
              }
            }}
          />
        </Form.Item>
        <p style={{ fontSize: 16, marginBottom: '2px', fontWeight: 'bold' }}>{t(`説明`)} </p>
        <Form.Item name="description">
          <Input.TextArea placeholder={t(`権限セット名を入力`)} showCount maxLength={240} />
        </Form.Item>
        <Row justify="end">
          <Button
            style={{ marginTop: '8px' }}
            onClick={() => {
              const switchFlag = !isTruthy(isAllChecked)
              const updatedActionsWithPermission = Object.fromEntries(
                Object.entries(PolicyFormSchema.shape.actionsWithPermission.shape).map(([key]) => [key, switchFlag]),
              )
              setIsAllChecked(switchFlag)
              form.setFieldsValue({ actionsWithPermission: updatedActionsWithPermission })
            }}
          >
            {isTruthy(isAllChecked) ? t(`全て解除`) : t(`全て許可`)}
          </Button>
        </Row>
        {/* actionの日本語表記にpolicyActionLabelJaMap+Form.Listを利用したかったが表現の冗長さを避け直書き。
            nameがaction(ケバブケース)をキャメルケースに対応させたもの。sheet-create -> SheetCreate */}
        <p style={{ fontSize: 16, marginBottom: '2px', fontWeight: 'bold' }}>{t(`シート`)} </p>
        <Form.Item
          label={t(`新規作成`)}
          name={['actionsWithPermission', policyActionSchema.Values['sheet-create']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
          tooltip={<>{t(`作成したシートの設定には「更新」権限も必要です`)}</>}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`更新`)}
          name={['actionsWithPermission', policyActionSchema.Values['sheet-update']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`削除`)}
          name={['actionsWithPermission', policyActionSchema.Values['sheet-delete']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`レコードの新規作成`)}
          name={['actionsWithPermission', policyActionSchema.Values['sheet-create-new-record']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
          tooltip={<>{t(`レコードの追加には「レコードの保存」権限も必要です`)}</>}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`レコードの保存`)}
          name={['actionsWithPermission', policyActionSchema.Values['sheet-save-record']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`レコードの削除`)}
          name={['actionsWithPermission', policyActionSchema.Values['sheet-delete-record']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`参照先レコードの新規作成`)}
          name={['actionsWithPermission', policyActionSchema.Values['sheet-open-relation-input-form']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`CSVダウンロード`)}
          name={['actionsWithPermission', policyActionSchema.Values['sheet-download-csv']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`リードの昇格`)}
          name={['actionsWithPermission', policyActionSchema.Values['convert-lead']]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <p style={{ fontSize: 16, marginBottom: '2px', fontWeight: 'bold' }}>{t(`KPI`)} </p>
        <Form.Item
          label={t(`新規作成`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-create']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
          tooltip={<>{t(`作成したKPIの設定には「更新」権限も必要です`)}</>}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`更新`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-update']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`削除`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-delete']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`レコードの新規作成`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-create-new-record']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
          tooltip={<>{t(`レコードの追加には「レコードの保存」権限も必要です`)}</>}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`レコードの保存`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-save-record']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`レコードの削除`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-delete-record']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`参照先レコードの新規作成`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-open-relation-input-form']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`CSVダウンロード`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-download-csv']]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <p style={{ fontSize: 16, marginBottom: '2px', fontWeight: 'bold' }}>{t(`ダッシュボード`)} </p>
        <Form.Item
          label={t(`新規作成`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-pivot-create']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
          tooltip={<>{t(`作成したダッシュボードの設定には「更新」権限も必要です`)}</>}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`更新`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-pivot-update']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`削除`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-pivot-delete']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`CSVダウンロード`)}
          name={['actionsWithPermission', policyActionSchema.Values['kpi-pivot-download-csv']]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <p style={{ fontSize: 16, marginBottom: '2px', fontWeight: 'bold' }}>{t(`フォルダ`)} </p>
        <Form.Item
          label={t(`新規作成`)}
          name={['actionsWithPermission', policyActionSchema.Values['view-group-create']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`削除`)}
          name={['actionsWithPermission', policyActionSchema.Values['view-group-delete']]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <p style={{ fontSize: 16, marginBottom: '2px', fontWeight: 'bold' }}>{t(`全般`)} </p>
        <Form.Item
          label={t(`組織設定`)}
          name={['actionsWithPermission', policyActionSchema.Values['manage-organization-setting']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`連携設定`)}
          name={['actionsWithPermission', policyActionSchema.Values['manage-connection']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`目標設定`)}
          name={['actionsWithPermission', policyActionSchema.Values['manage-goal']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`軸グループ設定`)}
          name={['actionsWithPermission', policyActionSchema.Values['manage-dimension-group']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`ユーザー一覧`)}
          name={['actionsWithPermission', policyActionSchema.Values['manage-users']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`ユーザーグループ設定`)}
          name={['actionsWithPermission', policyActionSchema.Values['manage-user-groups']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`ユーザー招待`)}
          name={['actionsWithPermission', policyActionSchema.Values['manage-invitation']]}
          style={{ marginBottom: '2px' }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t(`利用状況閲覧`)}
          name={['actionsWithPermission', policyActionSchema.Values['read-user-activity']]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        {isSome(policyUserRole) && (
          <Text type="warning">
            <Space>
              <WarningOutlined />
              <span>
                {t(
                  `変更は権限の紐づく全ユーザーに即時反映されます。ユーザーはリロードすると設定した権限が適用されるようになります。`,
                )}
              </span>
            </Space>
          </Text>
        )}
        <Form.Item>
          <Row justify="end">
            <Tooltip title={isCreateButtonDisabled ? t(`権限セット名を入力してください`) : undefined}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                htmlType="submit"
                loading={loading}
                disabled={isCreateButtonDisabled}
              >
                {t(`保存`)}
              </Button>
            </Tooltip>
          </Row>
        </Form.Item>
      </Form>
    </PageHeader>
  )
}

function createInitialValue(policyUserRole: PolicyUserRoleFieldsFragment | undefined) {
  if (isNull(policyUserRole)) {
    return
  }

  // TODO: BEでprincipalで絞り込むようにする
  const fetchPoliciesQuery = useQuery(FetchPoliciesDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
    fetchPolicy: 'cache-and-network',
  })

  const actionsWithPermissionArray = fetchPoliciesQuery.data?.policies
    .filter((policy) => policy.principal.type === 'userRole' && policy.principal.role === policyUserRole.name)
    .map((policy) => {
      return {
        [policy.action]: policy.permission,
      }
    })

  const actionsWithPermission = actionsWithPermissionArray?.reduce((previous, current) => {
    return { ...previous, ...current }
  }, {}) as PolicyFormValue['actionsWithPermission']

  return {
    label: policyUserRole.label,
    description: policyUserRole.description,
    actionsWithPermission,
  }
}

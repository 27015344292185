import { PageHeader } from '@ant-design/pro-layout'
import { useSuspenseQuery } from '@apollo/client'
import { FetchOrganizationDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath, HandleQuery } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { organizationHasFeature } from '@salescore/frontend-common'
import { Alert, Card, Collapse, Divider } from 'antd'
import { t } from 'i18next'
import type { ReactNode } from 'react'

import { SlackIntegrationForm } from '../Slack/SlackIntegrationForm'
import { AllowedIpsForm } from './AllowedIpsForm'
import { OrganizationBasicSettingForm } from './OrganizationBasicSettingForm'
import { OrganizationDetailSettingForm } from './OrganizationDetailSettingForm'
import { OrganizationSubscriptionStatusTable } from './OrganizationSubscriptionStatusTable'

export const OrganizationSettingPage = (): ReactNode => {
  const ability = recoil.global.policy.useCanForFeatures()
  const {
    data: { organization, subscriptionStatus },
  } = useSuspenseQuery(FetchOrganizationDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
    fetchPolicy: 'cache-and-network',
  })

  if (!ability.canManageOrganizationSetting) {
    return (
      <div>
        <Alert message={t(`権限がありません`)} type="warning" />
      </div>
    )
  }
  const commentFeatureEnabled = organizationHasFeature(organization, 'enable_slack_comment')

  return (
    <PageHeader title={t(`組織設定`)}>
      <Card className="mb-4">
        <OrganizationBasicSettingForm organization={organization} />
      </Card>
      {commentFeatureEnabled && <SlackIntegrationForm isOrg={true} organizationId={getOrganizationIdFromPath()} />}
      <Card className="mb-4">
        <OrganizationSubscriptionStatusTable subscriptionStatus={subscriptionStatus} />
        <p className="text-sm text-gray-500">
          {t(`※契約ライセンス数の変更をご希望の場合は、お手数ですが営業担当者までお声がけください`)}
        </p>
      </Card>

      {/* <Card className="mb-4"> */}
      <Collapse defaultActiveKey={[]} className="mb-4">
        <Collapse.Panel header={t(`高度な設定`)} key="detail">
          <OrganizationDetailSettingForm organization={organization} />
          <Divider />
          <HandleQuery>
            <AllowedIpsForm organization={organization} />
          </HandleQuery>
        </Collapse.Panel>
      </Collapse>
      {/* </Card> */}
    </PageHeader>
  )
}

import { ApolloClient, ApolloLink, from, InMemoryCache, type NormalizedCacheObject } from '@apollo/client'
import { VERSION } from '@salescore/buff-common'
import { useEffect, useState } from 'react'

import { cleanTypeNameLink } from './apollo/clearTypeNameLink'
import { errorLink } from './apollo/errorLink'
import { httpLink } from './apollo/httpLink'
import { retryLink } from './apollo/retryLink'

const usingLocalStorage = false

const createAuthLink = (token?: string) => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: token === undefined ? '' : `Bearer ${token}`,
      },
    }))

    return forward(operation)
  })
}

export const createApolloClient = (token?: string) => {
  const cache = new InMemoryCache()

  const client = new ApolloClient({
    cache,
    link: from([cleanTypeNameLink, errorLink, createAuthLink(token), retryLink, httpLink]),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only', // cache-and-networkが選べなかった
      },
    },
    name: 'salescore-client',
    version: VERSION,
  })
  return client
}

export const useApolloClient = (token?: string) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>()

  const awaitSetClient = () => {
    const client = createApolloClient(token)
    setClient(client)
  }

  useEffect(() => {
    awaitSetClient()
  }, [])

  return client
}

import { isSome } from '@salescore/buff-common'
import type { NodePropertyName, ViewConfig, ViewConfigSheet, ViewQueryField } from '@salescore/core'
import { selector, useRecoilValue } from 'recoil'

import { flatNodes } from '../../../state/nodeUtil'
import { configAtom, meAtom } from '../atoms'
import { queryFieldToConfigField } from '../mutations/field/util'
import { connectionsSelector } from './connectionsSelector'

export const configSheetSelector = selector({
  key: `view/configSheetSelector`,
  get({ get }) {
    const config = getSheetConfig(get(configAtom))
    const me = get(meAtom)
    const { getModel, getModelProperty } = get(connectionsSelector)
    const flattenNodes = flatNodes(config.tree)
    const flattenFilterNodeLeafs = (isSome(config.filterTree) ? flatNodes(config.filterTree) : []).flatMap(
      (x) => x.leafs,
    )
    const getNode = (nodeName: string) => {
      return flattenNodes.find((x) => x.name === nodeName)
    }

    return {
      config,
      flattenNodes,
      flattenFilterNodeLeafs,
      getNode,
      getModel,
      getModelProperty,
      getNodePropertyLabel: (nodeProperty: NodePropertyName) => {
        const node = getNode(nodeProperty.nodeName)
        const model = getModel(nodeProperty.modelName)
        const property = getModelProperty(nodeProperty.modelName, nodeProperty.propertyName)
        return [
          node?.ui?.label ?? model?.label ?? model?.name ?? nodeProperty.nodeName,
          property?.label ?? nodeProperty.propertyName,
        ].join(': ')
      },
      getConfigField: (queryField: ViewQueryField) => {
        return queryFieldToConfigField(queryField, config.fields ?? [])
      },
      // 組織とシート固有の AJ 設定を混ぜたもの。これを UI で使うようにする。
      shouldJoinRecordsInApplication:
        config.meta?.shouldJoinRecordsInApplication ?? me.organization.setting.shouldJoinRecordsInApplication,
    }
  },
})

function getSheetConfig(config: ViewConfig): ViewConfigSheet {
  if (config.type === 'sheet') {
    return config
  }

  // TODO: エラーにしたい
  return {
    type: 'sheet',
  }
}

export const useConfigSheetSelector = () => {
  return useRecoilValue(configSheetSelector)
}

import { PlusOutlined } from '@ant-design/icons'
import { isPresent } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import { HUB_PROPERTY_TYPE_ICONS, propertyTypeOptions } from '@salescore/client-common'
import type { CoreModel, ModelProperty, NodePropertyName, ViewConfigTreeNode } from '@salescore/core'
import { getColumnSearchProps } from '@salescore/frontend-common'
import { Button, Input, Row, Table } from 'antd'
import { t } from 'i18next'
import { useMemo, useState } from 'react'

import type { NodeWithModel } from './NodePropertyPicker'

export function NodePropertyPickerModalContent({
  propertiesWithNode,
  onFinish,
}: {
  propertiesWithNode: NodeWithModel[]
  onFinish: (property: NodePropertyName) => void
}) {
  const [searchKey, setSearchKey] = useState('')

  const dataSource = useMemo(() => {
    if (!isPresent(searchKey)) {
      return propertiesWithNode
    }
    const dataSource = propertiesWithNode.filter(
      (x) =>
        // !isIncluded(x, viewQueryFields) &&
        x.property.label.includes(searchKey) ||
        x.property.name.includes(searchKey) ||
        x.model.label.includes(searchKey) ||
        x.model.name.includes(searchKey),
    )
    return dataSource
  }, [propertiesWithNode, searchKey])

  return (
    <div className="">
      <Row className="mb-3 pr-6" align="middle">
        {/* <Space className="font-bold mb-2">
          {isSome(onBack) && <Button type="text" size="small" icon={<LeftOutlined />} onClick={onBack} />}
          <div>{node.meta.label}の項目を追加</div>
        </Space> */}
        <Input.Search
          allowClear
          enterButton={false}
          className=""
          style={{ width: '100%' }}
          onKeyDown={(e) => {
            e.stopPropagation() // stopPropagationしないと、シート側のhandleKeyDownが発火する
          }}
          onChange={(e) => {
            setSearchKey(e.target.value)
          }}
        />
      </Row>

      <Table
        scroll={{ y: 500 }}
        dataSource={dataSource}
        pagination={{ pageSize: 100, simple: true }}
        size="small"
        rowClassName="cursor-pointer hover:opacity-70"
        onRow={(record, rowIndex) => {
          return {
            onClick() {
              onFinish({
                nodeName: record.node.name,
                modelName: record.model.name,
                propertyName: record.property.name,
              })
            },
          }
        }}
        columns={[
          {
            title: <span className="text-xs">{t(`テーブル名`)}</span>,
            dataIndex: 'nodeLabel',
            // width: 110,
            // TODO
            // filters: uniqueNodes.map((node) => ({
            //   text: node.label,
            //   value: node.name,
            // })),
            ...getColumnSearchProps(
              (record: { node: ViewConfigTreeNode; model: CoreModel }) => record.node.ui?.label ?? record.model.label,
            ),
            onFilter: (value, record) => record.node.name === value,
            render(_, record) {
              return (
                <span className="text-xs">
                  {record.nodePathAsLabel?.join(': ') ?? record.node.ui?.label ?? record.model.label}
                </span>
              )
            },
          },
          {
            title: t(`型`),
            dataIndex: 'propertyType',
            width: 50,
            filters: propertyTypeOptions.map((x) => ({
              value: x.value,
              text: x.label,
            })),
            onFilter: (value, record) => record.property.type === value,
            render(_, record) {
              return <span>{HUB_PROPERTY_TYPE_ICONS[record.property.type]}</span>
            },
          },
          {
            dataIndex: 'propertyLabel',
            title: t(`項目名`),
            ...getColumnSearchProps((record: { property: ModelProperty }) => record.property.label),
            render(_, record) {
              return <span>{record.property.label}</span>
            },
          },
          {
            dataIndex: 'addButton',
            width: 80,
            render(_, option) {
              return (
                <span>
                  <Button
                    size="small"
                    type="text"
                    icon={<PlusOutlined />}
                    style={{ color: CONSTANT.colors.primaryColor }}
                  >
                    {t(`選択`)}
                  </Button>
                </span>
              )
            },
          },
        ]}
      />
    </div>
  )
}

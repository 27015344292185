import { z } from 'zod'

import { viewQueryAggregationSchema } from './aggregation'
import { viewQueryListSchema } from './list'
import { viewQueryMultiTablePivotSchema } from './multi_table_pivot'

export * from './aggregation'
export * from './common'
export * from './field'
export * from './filter'
export * from './list'
export * from './multi_table_pivot'
export type * from './response'
export * from './sorter'
export * from './tree'

export const viewQuerySchema = z.union([
  viewQueryListSchema,
  viewQueryAggregationSchema,
  viewQueryMultiTablePivotSchema,
])
export type ViewQuery = z.infer<typeof viewQuerySchema>

import { mutation } from '@salescore/frontend-common'
import { t } from 'i18next'

import { listQueryAtom, viewAbilityAtom } from '../../view/atoms'
import { modelSearcherSelector } from '../../view/selectors/modelSearcherSelector'
import { changesAtom, feedbackMessagesAtom, recordIdsAtom, recordsAtom } from '../atoms'
import { addNewRecords, type NewRecordChange, type OnAfterChange, type OnAfterInsert } from './addNewRecords'
import { type RelationChange, updateRecordNodeRelations } from './updateRecordNodeRelations'
import { type RecordChange, upsertViewRecords } from './upsertViewRecords'

interface Argument {
  newRecordChanges?: NewRecordChange[]
  recordChanges: RecordChange[]
  relationChanges?: RelationChange[]
  onAfterInsert?: OnAfterInsert
  onAfterChange?: OnAfterChange
}

// upsertViewRecordsを実行し、その結果をsetするmutation
export const upsertViewRecordsMutation = mutation<Argument>({
  key: `rsheet/upsertViewRecordsMutation`,
  set({ get, set }, { recordChanges, relationChanges, newRecordChanges, onAfterInsert, onAfterChange }) {
    const ability = get(viewAbilityAtom)
    if (!ability.canSaveRecord) {
      set(feedbackMessagesAtom, [
        {
          message: t(`このシートのレコードを編集する権限がありません`),
          type: 'warn' as const,
        },
      ])
    }
    const query = get(listQueryAtom)
    const records = get(recordsAtom)
    const recordIds = get(recordIdsAtom)
    const modelSearcher = get(modelSearcherSelector)

    // レコードをcloneして参照渡しで更新していく
    // このような形にしないと、前の関数での変更が次の関数に伝わらない。また、関数の中でsetする際にレコードを普通に渡すとfreezeされるので、cloneして渡すこと。
    const newRecords = structuredClone(records)
    // recordIdsについても同じく。（関数の中でset(oldValue => {})の関数を複数回使っても、oldValueは初期値のままなので注意）
    const newRecordIds = [...recordIds]

    const newChanges = addNewRecords(newRecords, newRecordIds, newRecordChanges ?? [], set, onAfterInsert)
    if (newRecordIds.length !== recordIds.length) {
      set(recordIdsAtom, newRecordIds)
    }
    const upsertedChanges = upsertViewRecords({
      records: newRecords,
      view: query,
      recordChanges: recordChanges.map(
        (x): RecordChange => ({
          ...x,
          // NOTE:
          // newChangesがある場合＝新規作成した場合、現状は新規作成はペースト以外では1レコードしか生成されない。
          // 子レコードの新規生成を行う場合に、生成したレコードのinnerRowIndexで更新をかける必要があるのでここで代入する
          innerRowIndex: newChanges.first()?.recordNode.meta.innerRowIndexStart ?? x.innerRowIndex,
        }),
      ),
      modelSearcher,
      set,
    })
    updateRecordNodeRelations(
      newRecords,
      (relationChanges ?? []).map((relationChange, index) => {
        // XXX: relationChangeについても、上記のupsertViewRecordsでのロジックと同じようにinnerRowIndexを操作する必要がある。
        if (newChanges.length !== 1) {
          return relationChange
        }
        return {
          ...relationChange,
          innerRowIndex: newChanges.first()?.recordNode.meta.innerRowIndexStart ?? relationChange.innerRowIndex,
        }
      }),
      set,
    )

    //
    // changesを反映させる
    //
    const changes = [...newChanges.map((x) => x.change), ...upsertedChanges].compact()
    if (changes.isPresent()) {
      const newChange = {
        datetime: new Date().toISOString(),
        rowIndices: [0], // TODO: 結局使ってないので廃止
        snapshot: JSON.stringify(records), // snapshotは以前のものをいれる
        snapshotAfterChange: JSON.stringify(newRecords),
        changes,
      }
      set(changesAtom, (oldChanges) => {
        return [...oldChanges, newChange]
      })
    }
    if (onAfterChange !== undefined) {
      onAfterChange(changes)
    }
  },
})

import type { ViewQuery, ViewQueryResult, ViewQueryResultCursor } from '../../schemas/query'
import { executeViewQueryForAggregationQuery, type SqlClient } from './executeViewQueryForAggregationQuery'
import { executeViewQueryForListQuery } from './executeViewQueryForListQuery'
import { executeViewQueryForMultiTablePivot } from './executeViewQueryForMultiTablePivot'

export type SqlResultRecord = Record<string, unknown>

const DEFAULT_PAGE_SIZE = 100
export const POSTGRES_COLUMN_LENGTH_LIMIT = 63

export type ViewResultCursorOrInitialCursor = Omit<ViewQueryResultCursor, 'nextId'> & {
  nextId?: string | null
}

export class ExecuteViewPostgresError extends Error {
  public originalError: Error

  public constructor(e: Error, message: string) {
    super(message)
    this.name = 'ExecuteViewPostgresError'
    this.originalError = e
  }
}

export const defaultCursor: ViewResultCursorOrInitialCursor = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE, // TODO: positive validation[]
  chunkSize: 0, // chunkSizeは前回取得時の最後の行数で決まるので、デフォルトは0でOK
  nextId: undefined,
}

export const executeViewQuery = async ({
  viewQuery,
  sqlClient,
  kvsClient,
  cursor,
  mustacheParameter,
  dateForDiffHighlight,
  shouldJoinRecordsInApplication,
  enableStreaming,
}: {
  viewQuery: ViewQuery
  sqlClient: SqlClient
  kvsClient?: KeyValueStoreClient
  cursor?: ViewResultCursorOrInitialCursor
  mustacheParameter: Record<string, unknown>
  dateForDiffHighlight?: Date
  shouldJoinRecordsInApplication?: boolean
  enableStreaming?: boolean
}): Promise<ViewQueryResult> => {
  switch (viewQuery.type) {
    case 'aggregation': {
      return await executeViewQueryForAggregationQuery({
        query: viewQuery,
        sqlClient,
        mustacheParameter,
      })
    }
    case 'multiTablePivot': {
      return await executeViewQueryForMultiTablePivot({
        query: viewQuery,
        sqlClient,
        kvsClient,
        mustacheParameter,
        enableStreaming,
      })
    }
    case 'list': {
      return await executeViewQueryForListQuery({
        viewQuery,
        sqlClient,
        cursor,
        mustacheParameter,
        dateForDiffHighlight,
        shouldJoinRecordsInApplication,
      })
    }
    default: {
      const x: never = viewQuery
      throw new Error(`unsupported viewQuery type`)
    }
  }
}

export interface KeyValueStoreClient {
  set: (key: string, value: string, option?: { expireSec: number }, timeout?: number) => Promise<void>
  setHash: (
    key: string,
    field: string,
    value: string,
    option?: { expireSec: number },
    timeout?: number,
  ) => Promise<void>
  get: (key: string, option?: { timeout?: number }) => Promise<string | null>
  getHashAll: (key: string, option?: { timeout?: number }) => Promise<Record<string, unknown>>
}

import { useMutation } from '@apollo/client'
import { isNull, isPresent, isSome } from '@salescore/buff-common'
import { CreateOauthUserSourceDocument, SourceProviderEnum } from '@salescore/client-api'
import { CLIENT_COMMON_CONSTANT, getOrganizationIdFromPath, handleMe, ProviderLogo } from '@salescore/client-common'
import { removeQueryParameterToUrl } from '@salescore/frontend-common'
import { App, Modal, Result } from 'antd'
import { t } from 'i18next'
import { useRouter } from 'next/router'
import { type ReactNode, useState } from 'react'

import { SourceConfigForm } from '../../HubSource/CreateSourceConfirmModal/SourceConfigForm'

export const LATEST_OPENED_SOURCE_KEY = 'LATEST_OPENED_SOURCE_KEY'
export const LATEST_OPENED_AUTHORIZE_URL = 'LATEST_OPENED_AUTHORIZE_URL'

interface Source {
  id: string
  name: string
  provider: SourceProviderEnum
}

const getLatestOpenedSourceId = (provider: string): Source | undefined => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const latestOpenedSource = JSON.parse(localStorage.getItem(LATEST_OPENED_SOURCE_KEY) ?? '{}')

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (latestOpenedSource.provider === provider && isPresent(latestOpenedSource.id)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return latestOpenedSource
    }

    return undefined
  } catch {
    return undefined
  }
}

export const CreateUserSourceConfirmModal = (): ReactNode => {
  const { message } = App.useApp()
  const [sourceConfig, setSourceConfig] = useState<Record<string, unknown>>({})
  const router = useRouter()
  const [createOauthUserSourceMutation, { loading }] = useMutation(CreateOauthUserSourceDocument)
  const { oauthCode, state, provider } = router.query
  const isValidParameter =
    isSome(oauthCode) && isSome(state) && isSome(provider) && oauthCode !== '' && state !== '' && provider !== ''
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const latestOpenedSource = getLatestOpenedSourceId(provider as string)
  const [visibility, setVisibility] = useState(isValidParameter && provider !== 'slack')
  const [sourceName] = useState(latestOpenedSource?.name ?? '')

  const createOauthSourceFromQueryParameter = async (): Promise<void> => {
    if (latestOpenedSource?.id === undefined) {
      void message.error(t(`連携先の情報がブラウザから失われました。お手数ですが再度お試しください`))
      removeQueryParameterToUrl(`oauthCode`)
      return
    }
    await createOauthUserSourceMutation({
      variables: {
        oauthUserSourceInput: {
          // id: undefined, // サーバーサイドでsource.userSourcesを元に求めるので不要
          sourceId: latestOpenedSource.id,
          organizationId: getOrganizationIdFromPath(),
          code: oauthCode as string,
          state: state as string,
          provider: provider as SourceProviderEnum,
          sourceConfig,
          name: sourceName,
          tokenUrl: getTokenUrl(provider as SourceProviderEnum),
        },
      },
      refetchQueries: ['fetchUserSources'],
      onCompleted: () => {
        void message.success(t(`連携を追加しました`))
        // TODO: ユーザーリロードに備えてqueryのリセットをしたい。リロードを走らせずに変更する方法が分からなかったので一旦なし
        setVisibility(false)
      },
      onError: (error) => {
        void message.error(t(`連携に失敗しました`))
        setErrorMessage(error.message)
      },
    })
  }

  return handleMe(({ myUser }) => {
    return (
      <Modal
        key="createModal"
        open={visibility}
        onCancel={() => {
          setVisibility(false)
        }}
        width={500}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        style={{ top: '3%' }}
      >
        {isSome(provider) && (
          <Result
            icon={<ProviderLogo provider={provider as string} />}
            title={
              <div>
                {t(`{{provider}}と連携します`, {
                  provider: CLIENT_COMMON_CONSTANT.i18n.ja.provider[provider as SourceProviderEnum],
                })}
                <br />
                {t(`よろしいですか？`)}
              </div>
            }
            subTitle={`${t(`ログイン中の組織`)}：${myUser.organization.name}`}
            extra={
              isNull(errorMessage)
                ? [
                    <SourceConfigForm
                      key={1}
                      // TODO validation
                      provider={provider as SourceProviderEnum}
                      loading={loading}
                      onSave={async () => {
                        await createOauthSourceFromQueryParameter()
                      }}
                      setSourceConfig={setSourceConfig}
                      sourceConfig={sourceConfig}
                    />,
                    // <Button
                    //   onClick={() => {
                    //     setVisibility(false)
                    //   }}
                    // >
                    //   キャンセル
                    // </Button>,
                  ]
                : [
                    <div key={1} className="text-red-600">
                      {errorMessage}
                    </div>,
                  ]
            }
          />
        )}
      </Modal>
    )
  })
}

function getTokenUrl(provider: SourceProviderEnum): string | undefined {
  if (provider === SourceProviderEnum.Salesforce) {
    const authorizeUrl = localStorage.getItem(LATEST_OPENED_AUTHORIZE_URL)
    if (
      isNull(authorizeUrl) ||
      !authorizeUrl.startsWith(`https://`) ||
      !authorizeUrl.endsWith(`/services/oauth2/authorize`)
    ) {
      return undefined
    }
    return authorizeUrl.replace(`/services/oauth2/authorize`, `/services/oauth2/token`)
  }
  return undefined
}

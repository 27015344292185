import { useRedirect, wrapKeyboardEvent } from '@salescore/frontend-common'
import { type KeyboardEvent, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { recoil } from '../../..'
import { launcherCursorAtom, launcherSearchKeyAtom } from '.'
import type { LauncherCommandV2 } from './useLauncherCommands'

export type LauncherState = ReturnType<typeof useLauncherState>

const MAX_VISIBLE_COMMANDS_NUM = 60
export const useLauncherState = () => {
  const { filterType, moveNextFilter, hide } = recoil.global.launcher.useLauncher()
  const [searchKey, setSearchKey] = useRecoilState(launcherSearchKeyAtom)
  const redirect = useRedirect()
  const [cursor, setCursor] = useRecoilState(launcherCursorAtom)
  const commands = recoil.global.launcher.useLauncherCommands({
    redirect,
  })
  const lowerCaseSearchKeys = searchKey.toLowerCase().split(/\s/)
  const filteredCommands = commands
    .filter((x) => lowerCaseSearchKeys.every((key) => [x.searchKeyword ?? '', x.title].join(' ').includes(key)))
    .filter((x) => (filterType === 'all' && x.type !== 'archived') || x.type === filterType) // typeが一致するか、allのとき(allの時にarchivedは非表示)
    // .mySortBy((x) => Math.max(x.title.indexOf(lowerCaseSearchKey), x.searchKeyword?.indexOf(lowerCaseSearchKey) ?? -1))
    .slice(0, MAX_VISIBLE_COMMANDS_NUM) // あまり増やしすぎると描画が重い？どれくらいが適正か悩ましい。

  const executeCommand = (command: LauncherCommandV2 | undefined) => {
    if (command === undefined) {
      return
    }

    void command.method()
    if (command.options?.hideModalAfterExecution !== false) {
      hide()
    }
  }

  const moveDown = () => {
    setCursor((x) => {
      // 最下段にいるとき
      if (x === filteredCommands.length - 1) {
        return 0
      }
      return x + 1
    })
  }
  const moveUp = () => {
    setCursor((x) => {
      // 最上段にいるとき
      if (x === 0) {
        return filteredCommands.length - 1
      }
      return x - 1
    })
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const wrapped = wrapKeyboardEvent(e)
    switch (e.key) {
      case 'Enter': {
        const selectedCommand = filteredCommands[cursor]
        executeCommand(selectedCommand)
        e.preventDefault()
        e.stopPropagation()
        return
      }
      case 'ArrowDown': {
        moveDown()
        e.preventDefault()
        e.stopPropagation()
        return
      }
      case 'ArrowUp': {
        moveUp()
        e.preventDefault()
        e.stopPropagation()
        return
      }
      case 'Tab': {
        if (e.shiftKey) {
          moveNextFilter({ reverse: true })
        } else {
          moveNextFilter()
        }
        e.preventDefault()
        e.stopPropagation()
        return
      }
    }

    if (wrapped.macCtrlKey) {
      switch (e.key) {
        case 'n': {
          moveDown()
          e.preventDefault()
          e.stopPropagation()
          return
        }
        case 'p': {
          moveUp()
          e.preventDefault()
          e.stopPropagation()
          return
        }
      }
    }

    // TODO: あまり厳密に判定していない
    if (wrapped.commandKey) {
      const metaCommands = filteredCommands.filter((x) => x.shortcut?.includes('command'))
      const selectedCommand = metaCommands.find((x) => x.shortcut?.includes(e.key))
      if (selectedCommand !== undefined) {
        executeCommand(selectedCommand)
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  useEffect(() => {
    setCursor(0)
  }, [searchKey])

  return {
    searchKey,
    filteredCommands,
    // コマンドの表示数の上限を超えて、かつカーソルが下の方にあれば、警告を表示する
    // TODO: 表示側で仮想化を行い、無限に表示できるようにするべき
    maxVisibleCommandExceededWarningVisibility:
      filteredCommands.length === MAX_VISIBLE_COMMANDS_NUM && cursor > MAX_VISIBLE_COMMANDS_NUM - 5,
    cursor,
    setSearchKey,
    handleKeyDown,
    executeCommand,
    setCursor,
  }
}

import { useMutation, useQuery } from '@apollo/client'
import { FetchGoalConfigsDocument, UpdateGoalConfigDocument } from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { NotionLikeSelector, useRedirect } from '@salescore/frontend-common'
import { t } from 'i18next'
import { startTransition, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { goalConfigAtom } from '../../../recoil/view/atoms'
import { useViewAbilityValue, useViewValue } from '../../../recoil/view/hooks'

export function GoalConfigsPicker() {
  const view = useViewValue()
  const { data, refetch } = useQuery(FetchGoalConfigsDocument, {
    variables: { organizationId: getOrganizationIdFromPath(), viewId: view.id },
    fetchPolicy: 'cache-and-network',
  })
  const invalidate = () => {
    startTransition(async () => {
      await refetch()
    })
  }
  const goalConfigs = data?.goalConfigs.sortBy((x) => x.rank) ?? []
  const [goalConfig, setGoalConfig] = useRecoilState(goalConfigAtom)
  const redirect = useRedirect()
  const [update] = useMutation(UpdateGoalConfigDocument)
  const ability = useViewAbilityValue()
  const canManageGoal = recoil.global.policy.useCan('manage-goal')

  useEffect(() => {
    // atomにあるゴールが存在しない(削除済みなど)場合はgoalConfigの先頭をデフォルト値とする
    if (goalConfigs.length > 0 && (goalConfig === undefined || !goalConfigs.some((x) => x.id === goalConfig.id))) {
      setGoalConfig(goalConfigs[0])
    }
  }, [goalConfigs])

  return (
    <>
      <NotionLikeSelector
        disableAddition={!canManageGoal}
        showAddition={true}
        dropdownStyle={{ width: 350 }}
        className="mr-2"
        buttonProps={{ type: 'text' }}
        value={goalConfig?.id}
        options={goalConfigs.map((x) => ({
          value: x.id,
          label: x.name,
          path: canManageGoal ? routes.goalConfigPathV2(x.id) : undefined,
        }))}
        setValue={(value) => {
          const newGoalConfig = goalConfigs.find((x) => x.id === value)
          if (newGoalConfig !== undefined) {
            setGoalConfig(newGoalConfig)
          }
        }}
        placeholder={t(`目標種別`)}
        additionText={t(`目標種別の設定`)}
        onAddButtonClick={() => {
          redirect(routes.goalConfigsPathV2(`dashboardViewId=${view.id}`))
        }}
        onSort={
          ability.canUpdate
            ? async (values: string[]) => {
                // TODO: この形でやるなら、recoilにgoalConfigsをいれたい
                const sortedGoalConfigs = values.map((value) => goalConfigs.find((x) => x.id === value)).compact()
                if (sortedGoalConfigs.isBlank()) {
                  return
                }
                const promises = sortedGoalConfigs.map(async (config, rank) => {
                  return await update({
                    variables: {
                      organizationId: getOrganizationIdFromPath(),
                      goalConfig: {
                        id: config.id,
                        name: config.name,
                        userGroupIdsConfig: config.userGroupIdsConfig,
                        userIdsConfig: config.userIdsConfig,
                        rank,
                      },
                    },
                  })
                })
                const results = await Promise.all(promises)
                invalidate()
              }
            : undefined
        }
      />
    </>
  )
}

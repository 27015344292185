import { isNull, isPresent, isSome } from '@salescore/buff-common'
import { getPeriodsForDashboard, type Period } from '@salescore/client-common'
import dayjs from 'dayjs'
import { selector, useRecoilState } from 'recoil'

import { meAtom } from '../../view/atoms'
import { kpiParameterAtom } from '../atoms'

export const periodSelector = selector<Period | undefined>({
  key: `view/navigation/periodSelector`,
  get({ get }) {
    const { organization } = get(meAtom)
    const periods = getPeriodsForDashboard(organization.setting)
    const parameter = get(kpiParameterAtom)
    const period = periods.find((x) => x.name === parameter.queryParameter?.periodName)

    if (isNull(period)) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      return undefined
    }

    // 日付でカスタムを指定した場合はPeriodにstartAt/endAtが存在しないため
    // parameter.queryParameter から値を取得する
    const startAt = isSome(period.startAt)
      ? period.startAt
      : isPresent(parameter.queryParameter?.startAt)
        ? dayjs(parameter.queryParameter.startAt)
        : undefined

    const endAt = isSome(period.endAt)
      ? period.endAt
      : isPresent(parameter.queryParameter?.endAt)
        ? dayjs(parameter.queryParameter.endAt)
        : undefined

    return {
      ...period,
      startAt,
      endAt,
    }
  },
  set({ get, set }, period) {
    const x = period as Period | undefined
    const { organization } = get(meAtom)
    const periods = getPeriodsForDashboard(organization.setting)
    const picked = periods.find((y) => y.name === x?.name)

    set(kpiParameterAtom, (oldValue) => {
      return {
        ...oldValue,
        queryParameter: {
          ...oldValue.queryParameter,
          periodName: x?.name,
          // カスタムのときだけ、クエリパラメータとして保存する（それ以外の時は相対日付のはずであり、絶対日付して保存したくないので保存しない）
          startAt: isSome(picked?.startAt) ? undefined : x?.startAt?.format(`YYYY-MM-DD HH:mm:ss`),
          endAt: isSome(picked?.endAt) ? undefined : x?.endAt?.format(`YYYY-MM-DD HH:mm:ss`),
        },
      }
    })
  },
})

export const usePeriodSelector = () => {
  return useRecoilState(periodSelector)
}

import { atom, useRecoilState } from 'recoil'

import { recoil } from '../../..'
import { type LauncherCommandType, useLauncherCommands } from './useLauncherCommands'
import { useLauncherState } from './useLauncherState'

const prefix = `global/launcher`
const launcherVisibilityAtom = atom<boolean>({
  key: `${prefix}/launcherVisibilityAtom`,
  default: false,
})
const launcherCommandFilterTypeAtom = atom<LauncherCommandType>({
  key: `${prefix}/launcherCommandFilterTypeAtom`,
  default: 'all',
})
export const launcherSearchKeyAtom = atom<string>({
  key: `${prefix}/searchKey`,
  default: ``,
})
export const launcherCursorAtom = atom<number>({
  key: `${prefix}/cursor`,
  default: 0,
})

const useLauncher = () => {
  const [visibility, setVisibility] = useRecoilState(launcherVisibilityAtom)
  const [filterType, setFilterType] = useRecoilState(launcherCommandFilterTypeAtom)
  const ability = recoil.global.policy.useCanForFeatures()

  return {
    visibility,
    filterType,
    setFilterType,
    moveNextFilter: (option?: { reverse?: boolean }) => {
      const filtersRaw: Array<LauncherCommandType | undefined> = [
        'all',
        'sheet',
        ability.canOpenKpi ? `kpi` : undefined,
        'kpiPivot',
        'page',
        'archived',
      ]
      const filters = filtersRaw.compact()
      const currentFilterIndex = filters.indexOf(filterType)
      const d = option?.reverse === true ? filters.length - 1 : 1
      const nextFilter = filters[(currentFilterIndex + d) % filters.length] ?? `all`
      setFilterType(nextFilter)
    },
    open: (option?: { filterType?: LauncherCommandType }) => {
      setVisibility(true)
      if (option?.filterType !== undefined) {
        setFilterType(option.filterType)
      }
    },
    hide: () => {
      setVisibility(false)
    },
  }
}

// NOTE: recoilへの移行が中途半端でいまいち綺麗に書ききれていないが、一旦この形とする
export const launcher = {
  useLauncher,
  useLauncherCommands,
  useLauncherState,
}

import { DatePicker } from 'antd'
import dayjs, { type Dayjs } from 'dayjs'
import { useRef, useState } from 'react'

import { useUpsertSheetRowMutation } from '../../../../recoil/mutations/upsertSheetRowMutation'
import type { RSheetsCellInputRenderTypeArgument } from '../InputCell'
import { RSheetsInputCellWrapper } from './InputCellWrapper'

export function RSheetsCellDateTimeInput({
  recordNode,
  column,
  rowIndex,
  innerRowIndex,
  defaultWidth,
  finishEditMode,
  boxStyle,
}: RSheetsCellInputRenderTypeArgument) {
  const columnOnChange = useUpsertSheetRowMutation()
  const [value, setValue] = useState<Dayjs | null>(() => {
    const initialValue = recordNode === undefined ? undefined : column.value(recordNode)
    const defaultValue = dayjs().add(1, 'hour').set('minute', 0).set('second', 0)
    if (typeof initialValue !== 'string') {
      return defaultValue
    }
    const m = dayjs(initialValue)
    if (m.isValid()) {
      return m
    }
    return defaultValue
  })
  const reference = useRef<HTMLInputElement>(null)

  setTimeout(() => {
    if (reference.current) {
      reference.current.focus()
    }
  }, 200)

  const onChange = async (value: Dayjs | null) => {
    // フロントエンドではタイムゾーンの取り扱いはせず、writeの際にそれぞれの連携先ごとに処理を行う
    // formatが崩れると、writeの処理が壊れる可能性があるため注意
    // TODO: フロントエンドでも常にタイムゾーン込みで処理した方が良いだろうか？
    const datetimeWithTimezone = value?.format('YYYY-MM-DDTHH:mm:ss')
    setValue(value)
    await columnOnChange({ value: datetimeWithTimezone, rowIndex, innerRowIndex, column })
  }

  return RSheetsInputCellWrapper(
    {
      async onChangeFixed() {
        await onChange(value)
      },
      finishEditMode,
    },
    ({ handleKeyDown }) => {
      return (
        <div
          style={{
            ...boxStyle,
            padding: '10px 10px', // padingYはボーダーにかぶらなければなんでも良い
            marginTop: 0,
            marginLeft: -2,
            backgroundColor: 'white',
          }}
        >
          <DatePicker
            // ref={ref}
            showTime={{ format: 'HH:mm' }}
            minuteStep={5 as const}
            bordered={false}
            autoFocus={true}
            value={value}
            open={true}
            onChange={async (value) => {
              await onChange(value)
              finishEditMode()
            }}
            style={{
              backgroundColor: 'white',
              padding: 0,
              minWidth: '100%',
              height: '100%', // 複数行にしたい場合は対応が必要
            }}
          />
        </div>
      )
    },
  )
}

import { EditOutlined } from '@ant-design/icons'
import type { SourceEntitySchemaFieldsFragment } from '@salescore/client-api'
import { compareFunction, getColumnSearchProps, useModal } from '@salescore/frontend-common'
import { Button, Table } from 'antd'
import { t } from 'i18next'

import { SourceEntitySchemaModal } from './SourceEntitySchemaModal'

export const SourceEntitySchemasTable = ({
  pickedEntitySchemas,
  entitySchemas,
  setPickedEntitySchemas,
}: {
  pickedEntitySchemas: SourceEntitySchemaFieldsFragment[]
  entitySchemas: SourceEntitySchemaFieldsFragment[]
  setPickedEntitySchemas: (xs: SourceEntitySchemaFieldsFragment[]) => void
}) => {
  const propertyModal = useModal<SourceEntitySchemaFieldsFragment>()

  return (
    <>
      <Table
        dataSource={entitySchemas.sortBy((x) => [(x.forcePick ?? false) ? 0 : 1, x.name])}
        pagination={{ defaultPageSize: 100 }}
        scroll={{ y: 'min(65vh, calc(100vh - 350px))' }}
        className="components-table-demo-nested"
        columns={[
          {
            title: t(`オブジェクト名`),
            sorter: (a, b) => compareFunction(a.label, b.label),
            render(_value, record) {
              return (
                <div>
                  {record.label}
                  {/* <span className="text-gray-500"> - {record.name}</span> */}
                </div>
              )
            },
            ...getColumnSearchProps((record: SourceEntitySchemaFieldsFragment) => record.label),
          },
          {
            title: <div className="">{t(`内部名`)}</div>,
            sorter: (a, b) => compareFunction(a.name, b.name),
            render(_value, record) {
              return (
                <div>
                  <span className="text-gray-500">{record.name}</span>
                </div>
              )
            },
            ...getColumnSearchProps((record: SourceEntitySchemaFieldsFragment) => record.name),
          },
          {
            title: t(`項目数(選択中/全て)`),
            sorter: (a, b) => compareFunction(a.properties.length, b.properties.length),
            render(_value, record) {
              const picked = pickedEntitySchemas.find((x) => x.name === record.name)
              return (
                <div>
                  {picked?.properties.length ?? 0} / {record.properties.length}
                </div>
              )
            },
          },
          {
            title: '',
            render(_value, record) {
              return (
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    propertyModal.showModal(record)
                  }}
                >
                  {t(`項目の選択`)}
                </Button>
              )
            },
          },
        ]}
      />
      {propertyModal.content !== undefined && (
        <SourceEntitySchemaModal
          isModalVisible={propertyModal.isModalVisible}
          initialPropertyNames={
            pickedEntitySchemas.find((x) => x.name === propertyModal.content!.name)?.properties.map((x) => x.name) ??
            propertyModal.content.properties.map((x) => x.name)
          }
          entitySchema={propertyModal.content}
          onFinish={(propertyNames) => {
            setPickedEntitySchemas(
              pickedEntitySchemas.map((x) => {
                if (x.name !== propertyModal.content!.name) {
                  return x
                }
                const entitySchema = entitySchemas.find((entitySchema) => entitySchema.name === x.name)!
                return {
                  ...entitySchema,
                  properties: entitySchema.properties.filter((property) => propertyNames.includes(property.name)),
                }
              }),
            )
            propertyModal.hideModal()
          }}
        />
      )}
    </>
  )
}

import { buildBooleanHook, buildReadonlyHook, buildStateHook } from '@salescore/frontend-common'
import { useRecoilValue } from 'recoil'

import {
  aggregationRecordsSelector,
  changesAtom,
  cursorAtom,
  errorsAtom,
  isSavingAtom,
  loadingAtom,
  pageSizeAtom,
  pickedIdsAtom,
  recordsAtom,
  sheetInSaveErrorsAtom,
  viewQueryResultAtom,
} from './atoms'

// initializer
// export const useInitializeMustacheParameter = (value: Record<string, unknown>) => {
//   const setActions = useSetRecoilState(mustacheParameterAtom)
//   useEffect(() => {
//     setActions(value)
//   }, [JSON.stringify(value)])
// }

// readonly
export const useRecordsValue = () => {
  return useRecoilValue(recordsAtom)
}
export const useAggregationRecordsValue = () => {
  return useRecoilValue(aggregationRecordsSelector)
}
export const useCursorValue = buildReadonlyHook(cursorAtom)
export const useChangesValue = buildReadonlyHook(changesAtom)
export const useViewQueryResult = buildReadonlyHook(viewQueryResultAtom)

// read/write
export const usePickedIds = buildStateHook(pickedIdsAtom)
export const usePageSizeState = buildStateHook(pageSizeAtom)
export const useErrorsState = buildStateHook(errorsAtom)
export const useSheetErrorsState = buildStateHook(sheetInSaveErrorsAtom)

// boolean
export const useLoadingState = buildBooleanHook(loadingAtom)
export const useIsSavingState = buildBooleanHook(isSavingAtom)

import type { CoreDslFormLeafPartial, ViewConfigField } from '@salescore/core'
import { Col, Row } from 'antd'

import type { TargetProperty } from './CoreDslForm'
import { CoreDslFormExtraFormItem } from './CoreDslFormExtraFormItem'
import { CoreDslFormOperatorFormItem } from './CoreDslFormOperatorFormItem'
import { CoreDslFormTermFormItem } from './CoreDslFormTermFormItem'

const spans = [7, 6, 7, 4]

export const CoreDslFormLeafFormItems = ({
  onChange,
  leaf,
  availableFields,
  children,
  targetProperty,
}: {
  children: JSX.Element
  leaf: CoreDslFormLeafPartial
  onChange: (x: CoreDslFormLeafPartial) => void
  availableFields: ViewConfigField[]
  targetProperty: TargetProperty
}) => {
  return (
    <Row justify="space-between" align="middle">
      <Col span={23}>
        <Row justify="start" align="middle" gutter={8}>
          <Col span={spans[0]}>
            <CoreDslFormTermFormItem
              availableFields={availableFields}
              targetProperty={targetProperty}
              term={leaf.left}
              operator={leaf.operator}
              onChange={(term) => {
                onChange({
                  ...leaf,
                  left: term,
                })
              }}
            />
          </Col>
          {/* termに応じて出しわけ？ */}
          <Col span={spans[1]}>
            <CoreDslFormOperatorFormItem leaf={leaf} onChange={onChange} />
          </Col>
          <Col span={spans[2]}>
            <CoreDslFormTermFormItem
              availableFields={availableFields}
              targetProperty={leaf.left?.type === 'recordNodeVariable' ? leaf.left : targetProperty}
              term={leaf.right}
              operator={leaf.operator}
              asRight
              asRightAndLeftIsRecordVariable={leaf.left?.type === 'recordNodeVariable'}
              onChange={(term) => {
                onChange({
                  ...leaf,
                  right: term,
                })
              }}
            />
          </Col>
          <Col span={spans[3]}>
            <CoreDslFormExtraFormItem
              leaf={leaf}
              targetProperty={targetProperty}
              onChange={(extra) => {
                onChange({
                  ...leaf,
                  extra,
                })
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={1}>{children}</Col>
    </Row>
  )
}

import { initialize } from './src/initialize'
import * as utilRecord from './src/util/record'
import * as v from './src/validation'

export * from './src/normalize'
export * from './src/pg-emulator/PgEmulator'
export type * from './src/typeUtil'
export * from './src/util/array'
export * from './src/util/cartesianProduct'
export * from './src/util/date'
export * from './src/util/normalizeZodError'
export * from './src/util/number'
export * from './src/util/string'
export * from './src/util/tree'
export * from './src/util/util'
export { validationFunctionSchema } from './src/validation/schema'
export * from './src/version'
export * from './src/zod'

export const validate = v.validate
export const ValidationFunctionSchema = v.ValidationFunctionSchema
export type ValidationFunction = v.ValidationFunction
export type ValidationArgument = v.ValidationArgument

export { findValueInRecord } from './src/util/record'
// utilRecord
// export const toRecord = utilRecord.toRecord // TODO: array側のtoRecordを別で置き換える
export const r = utilRecord.toRecord

// pipe
export function pipe<A>(a: A): A
export function pipe<A, B>(a: A, ab: (a: A) => B): B
export function pipe<A, B, C>(a: A, ab: (a: A) => B, bc: (b: B) => C): C
export function pipe<A, B, C, D>(a: A, ab: (a: A) => B, bc: (b: B) => C, cd: (c: C) => D): D
export function pipe<A, B, C, D, E>(a: A, ab: (a: A) => B, bc: (b: B) => C, cd: (c: C) => D, de: (d: D) => E): E
export function pipe<A, B, C, D, E, F>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
  ef: (e: E) => F,
): F
export function pipe<A, B, C, D, E, F, G>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
  ef: (e: E) => F,
  fg: (f: F) => G,
): G
export function pipe<A, B, C, D, E, F, G, H>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
  ef: (e: E) => F,
  fg: (f: F) => G,
  gh: (g: G) => H,
): H
export function pipe<A, B, C, D, E, F, G, H, I>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
  ef: (e: E) => F,
  fg: (f: F) => G,
  gh: (g: G) => H,
  hi: (h: H) => I,
): I
export function pipe<A, B, C, D, E, F, G, H, I, J>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
  ef: (e: E) => F,
  fg: (f: F) => G,
  gh: (g: G) => H,
  hi: (h: H) => I,
  ij: (index: I) => J,
): J
export function pipe<A, B, C, D, E, F, G, H, I, J, K>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
  ef: (e: E) => F,
  fg: (f: F) => G,
  gh: (g: G) => H,
  hi: (h: H) => I,
  ij: (index: I) => J,
  jk: (index: J) => K,
): K
export function pipe<A, B, C, D, E, F, G, H, I, J, K, L>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
  ef: (e: E) => F,
  fg: (f: F) => G,
  gh: (g: G) => H,
  hi: (h: H) => I,
  ij: (index: I) => J,
  jk: (index: J) => K,
  kl: (k: K) => L,
): L
export function pipe<A, B, C, D, E, F, G, H, I, J, K, L, M>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
  ef: (e: E) => F,
  fg: (f: F) => G,
  gh: (g: G) => H,
  hi: (h: H) => I,
  ij: (index: I) => J,
  jk: (index: J) => K,
  kl: (k: K) => L,
  lm: (l: L) => M,
): M
export function pipe<A, B, C, D, E, F, G, H, I, J, K, L, M, N>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
  ef: (e: E) => F,
  fg: (f: F) => G,
  gh: (g: G) => H,
  hi: (h: H) => I,
  ij: (index: I) => J,
  jk: (index: J) => K,
  kl: (k: K) => L,
  lm: (l: L) => M,
  mn: (m: M) => N,
): N
export function pipe<A, B, C, D, E, F, G, H, I, J, K, L, M, N, O>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
  ef: (e: E) => F,
  fg: (f: F) => G,
  gh: (g: G) => H,
  hi: (h: H) => I,
  ij: (index: I) => J,
  jk: (index: J) => K,
  kl: (k: K) => L,
  lm: (l: L) => M,
  mn: (m: M) => N,
  no: (n: N) => O,
): O

export function pipe<A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
  ef: (e: E) => F,
  fg: (f: F) => G,
  gh: (g: G) => H,
  hi: (h: H) => I,
  ij: (index: I) => J,
  jk: (index: J) => K,
  kl: (k: K) => L,
  lm: (l: L) => M,
  mn: (m: M) => N,
  no: (n: N) => O,
  op: (o: O) => P,
): P
export function pipe(
  a: unknown,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  ab?: Function,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  bc?: Function,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  cd?: Function,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  de?: Function,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  ef?: Function,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  fg?: Function,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  gh?: Function,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  hi?: Function,
): unknown {
  switch (arguments.length) {
    case 1: {
      return a
    }
    case 2: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
      return ab!(a)
    }
    case 3: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
      return bc!(ab!(a))
    }
    case 4: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
      return cd!(bc!(ab!(a)))
    }
    case 5: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
      return de!(cd!(bc!(ab!(a))))
    }
    case 6: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
      return ef!(de!(cd!(bc!(ab!(a)))))
    }
    case 7: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
      return fg!(ef!(de!(cd!(bc!(ab!(a))))))
    }
    case 8: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
      return gh!(fg!(ef!(de!(cd!(bc!(ab!(a)))))))
    }
    case 9: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
      return hi!(gh!(fg!(ef!(de!(cd!(bc!(ab!(a))))))))
    }
    default: {
      // eslint-disable-next-line prefer-rest-params,@typescript-eslint/no-unsafe-assignment
      let returnValue = arguments[0]
      for (let index = 1; index < arguments.length; index++) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,prefer-rest-params,@typescript-eslint/no-unsafe-assignment
        returnValue = arguments[index](returnValue)
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return returnValue
    }
  }
}

export { extendProperties } from './src/extend_properties'

initialize()

import { isNull } from '@salescore/buff-common'
import { selector, useRecoilValue } from 'recoil'

import { type DurationMetrics, durationMetricsListAtom, viewsContextAtom } from '../atoms'

export const durationMetricsSelector = selector({
  key: `view/durationMetricsSelector`,
  get({ get }) {
    const currentDurationMetricsList = get(durationMetricsListAtom)
    const { updateDurationMetricsList, durationMetricsList: contextDurationMetricsList } = get(viewsContextAtom)
    // viewComponentよりも上のレイヤーでも下のレイヤーでもdurationMetricsListにアクセスできるようにする
    const durationMetricsList = contextDurationMetricsList ?? currentDurationMetricsList

    const findLastDurationMetrics = (
      viewId: string,
      searchList: DurationMetrics[] | undefined = durationMetricsList,
    ) => {
      // 基本的に計測済みのmetricsは削除していくので同じviewIdに紐づくmetricsは1つのはずだが、
      // 念の為、最も最近記録されたものを取得する
      return searchList.findLast((metrics) => metrics.viewId === viewId)
    }

    const updateDurationMetrics = (metrics: Partial<DurationMetrics> & { viewId: string }, targetViewId?: string) => {
      const updateViewId = targetViewId ?? metrics.viewId
      updateDurationMetricsList?.((oldList) => [
        ...(oldList.map((x) => (x.viewId === updateViewId ? { ...x, ...metrics } : x)) ?? []),
      ])
    }

    const deleteDurationMetrics = (viewId: string) => {
      updateDurationMetricsList?.((oldList) => oldList.filter((x) => x.viewId !== viewId) ?? [])
    }

    const getPredictedDuration = ({
      viewId,
      durationMetricsList,
    }: {
      durationMetricsList: DurationMetrics[]
      viewId: string
    }) => {
      const targetMetrics = findLastDurationMetrics(viewId, durationMetricsList)
      if (isNull(targetMetrics)) {
        return
      }
      return targetMetrics.isRefetchCacheUsed
        ? targetMetrics.cachedPredictedDuration
        : targetMetrics.noCachedPredictedDuration
    }

    return {
      durationMetricsList,
      deleteDurationMetrics,
      findLastDurationMetrics,
      updateDurationMetrics,
      updateDurationMetricsList,
      getPredictedDuration,
    }
  },
})

export function useDurationMetricsSelector() {
  return useRecoilValue(durationMetricsSelector)
}

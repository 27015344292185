import type { UserActivityFieldsFragment } from '@salescore/client-api'
import { datetimeFormat, getColumnSearchProps } from '@salescore/frontend-common'
import { Table } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'

export function ActivitiesTable({ activities }: { activities: UserActivityFieldsFragment[] }) {
  return (
    <Table
      dataSource={activities}
      pagination={{ pageSize: 50 }}
      columns={[
        {
          dataIndex: 'user.name',
          title: t(`ユーザー名`),
          ...getColumnSearchProps((x: UserActivityFieldsFragment) => {
            return x.user.name
          }),
          render: (x, record) => {
            return <span>{record.user.name}</span>
          },
        },
        {
          dataIndex: 'user.identity.email',
          title: t(`メールアドレス`),
          ...getColumnSearchProps((x: UserActivityFieldsFragment) => {
            return x.user.identity.email
          }),
          render: (x, record) => {
            return <span>{record.user.identity.email}</span>
          },
        },
        {
          dataIndex: 'loginCount',
          title: t(`ログイン回数`),
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.loginCount - b.loginCount,
          render: (x, record) => {
            return <span>{record.loginCount}</span>
          },
        },
        {
          dataIndex: 'lastSignedInAt',
          title: t(`最終ログイン日時`),
          sorter: (a, b) => dayjs(a.lastSignedInAt).unix() - dayjs(b.lastSignedInAt).unix(),
          render: (x, record) => {
            return <span>{datetimeFormat(record.lastSignedInAt)}</span>
          },
        },
        {
          dataIndex: 'viewSheetDaysCount',
          title: t(`SYNC利用日数`),
          sorter: (a, b) => a.viewSheetDaysCount - b.viewSheetDaysCount,
          render: (x, record) => {
            return <span>{record.viewSheetDaysCount}</span>
          },
        },
        {
          dataIndex: 'viewKpiPivotDaysCount',
          title: t(`VISUALIZE利用日数`),
          sorter: (a, b) => a.viewKpiOrKpiPivotDaysCount - b.viewKpiOrKpiPivotDaysCount,
          render: (x, record) => {
            return <span>{record.viewKpiOrKpiPivotDaysCount}</span>
          },
        },
      ]}
    />
  )
}

import { EditOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import {
  DeletePolicyUserRoleDocument,
  FetchPolicyUserRolesDocument,
  FetchUsersDocument,
  type PolicyUserRoleFieldsFragment,
} from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { createDestroyColumn, datetimeFormat, useModal } from '@salescore/frontend-common'
import { Alert, Button, Table } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useState } from 'react'

import { PolicyCreateModal } from './PolicyCreateModal'
import { PolicyEditModal } from './PolicyEditModal'

// TODO: 安定稼働確認後デフォルトポリシーもBE(PolicyUserRoleテーブル)から引くようにする
const defaultPolicies: PolicyUserRoleFieldsFragment[] = [t(`一般ユーザー`), t(`現場マネージャー`), t(`組織管理者`)].map(
  (label, index) => ({
    id: `default-${index}`,
    name: '',
    label,
    description: t(`システムで用意されているロールです。`),
    updatedAt: null,
    updatedById: null,
  }),
)

export const PoliciesTable = (): ReactNode => {
  const { canManagePolicy } = recoil.global.policy.useCanForFeatures()
  const {
    data: { policyUserRoles },
  } = useSuspenseQuery(FetchPolicyUserRolesDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })

  if (!canManagePolicy) {
    return (
      <div>
        <Alert type="warning" message={t(`権限がありません`)} />
      </div>
    )
  }

  return <Body policyUserRoles={policyUserRoles} />
}

function Body({ policyUserRoles }: { policyUserRoles: PolicyUserRoleFieldsFragment[] }): ReactNode {
  const { data: users } = useSuspenseQuery(FetchUsersDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })
  const [loading, setLoading] = useState(false)
  const [deletePolicyUserRoleMutation] = useMutation(DeletePolicyUserRoleDocument)

  const createPolicyModal = useModal()
  const editPolicyModal = useModal<{ policyUserRole: PolicyUserRoleFieldsFragment }>()

  return (
    <>
      <PageHeader
        title={t(`権限セット一覧`)}
        extra={[
          <Button
            key="button-create-policy"
            type="primary"
            onClick={() => {
              createPolicyModal.showModal()
            }}
          >
            {t(`新規作成`)}
          </Button>,
        ]}
      >
        <Table
          rowKey="id"
          size="small"
          pagination={{ defaultPageSize: 100 }}
          dataSource={[...defaultPolicies, ...policyUserRoles]}
          columns={[
            {
              title: t(`権限セット名`),
              dataIndex: 'label',
              width: 200,
              render(_text, record) {
                return <span>{record.label}</span>
              },
            },
            {
              title: t(`説明`),
              dataIndex: 'description',
              render(_text, record) {
                return <span>{record.description}</span>
              },
            },
            {
              title: t(`最終更新日`),
              dataIndex: 'updatedAt',
              width: 200,
              render(_text, record) {
                if (isNull(record.updatedAt)) {
                  return <span>-</span>
                }
                return <span>{datetimeFormat(record.updatedAt)}</span>
              },
            },
            {
              title: t(`最終更新ユーザー`),
              width: 200,
              dataIndex: 'updatedBy',
              render(_, record) {
                if (isNull(record.updatedById)) {
                  return <span>-</span>
                }
                const user = users.users.find((x) => x.id === record.updatedById)
                return <span>{user?.name}</span>
              },
            },
            {
              title: ``,
              key: `edit`,
              width: 150,
              render(_, record) {
                return (
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      editPolicyModal.showModal({ policyUserRole: record })
                    }}
                    disabled={record.id.startsWith('default-')}
                  >
                    {t(`権限の編集`)}
                  </Button>
                )
              },
            },
            createDestroyColumn(
              loading,
              setLoading,
              async (record: PolicyUserRoleFieldsFragment) => {
                if (users.users.some((user) => user.role === record.name)) {
                  throw new Error(
                    t(`割り当て中のユーザーがいるため削除できません。他の権限に変更後に削除してください。`),
                  )
                }
                await deletePolicyUserRoleMutation({
                  variables: {
                    organizationId: getOrganizationIdFromPath(),
                    id: record.id,
                  },
                  refetchQueries: ['fetchPolicyUserRoles'],
                })
              },
              (record: PolicyUserRoleFieldsFragment) => {
                return !record.id.startsWith('default-')
              },
            ),
          ]}
        />
      </PageHeader>
      <PolicyCreateModal modal={createPolicyModal} />
      <PolicyEditModal modal={editPolicyModal} />
    </>
  )
}

import { useSuspenseQuery } from '@apollo/client'
import { FetchViewPickerRelatedDocument, ViewConfigTypeEnum } from '@salescore/client-api'
import { Select } from 'antd'
import { useMemo } from 'react'

import { searchParentViewGroups } from '../misc/searchParentViewGroups'
import { getOrganizationIdFromPath } from '../presentation/common/auth'
import { FolderPathAndCreator } from './FolderPathAndCreator'

export interface ViewPickerOption {
  value: string
  label: string
  path: string[]
  createdBy: string | undefined
}

// Form.Item の子コンポーネントとして使うことを想定している（valueとonChangeは自動挿入される）
// See https://ant.design/components/form#:~:text=After%20wrapped%20by,change%20value%20programmatically
export function ViewPicker({
  value,
  allowPrivate,
  onChange,
  disabled,
}: {
  value?: string
  allowPrivate?: boolean
  onChange?: (value?: string) => void
  disabled?: boolean
}): JSX.Element {
  const { data: fetchViewPickerRelatedResponse } = useSuspenseQuery(FetchViewPickerRelatedDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const views = fetchViewPickerRelatedResponse.viewsForSider
    .filter((x) => x.type === ViewConfigTypeEnum.KpiPivot && (allowPrivate === true || !x.private) && !x.archived)
    .sortBy((x) => x.name)
  const viewGroups = fetchViewPickerRelatedResponse.viewGroups
  const viewGroupsWithParents = useMemo(() => {
    const viewGroupsMap = viewGroups.groupByUniqueKey((x) => x.id)
    const viewGroupsWithParents = viewGroups.map((viewGroup) => {
      return {
        viewGroup,
        parents: searchParentViewGroups(viewGroup.viewGroupId, viewGroupsMap),
      }
    })
    return viewGroupsWithParents
  }, [viewGroups])

  const options = useMemo(
    () =>
      views.map((x) => {
        const viewGroupWithParents = viewGroupsWithParents.find((g) => x.viewGroupId === g.viewGroup.id)
        const path =
          viewGroupWithParents === undefined
            ? []
            : [viewGroupWithParents.viewGroup, ...(viewGroupWithParents.parents ?? [])].map((g) => g.name).reverse()
        return {
          value: x.id,
          label: x.name,
          path,
          createdBy: x.createdBy?.name,
        }
      }),
    [views, viewGroupsWithParents],
  )

  const selectedOption = options.find((o) => o.value === value) ?? options.first()

  if (onChange !== undefined) {
    onChange(selectedOption?.value)
  }

  return (
    <div>
      <Select
        showSearch
        filterOption={(input, option) => ((option?.label ?? '') as string).toLowerCase().includes(input.toLowerCase())}
        optionLabelProp="label"
        onChange={onChange}
        value={selectedOption?.value}
        disabled={disabled}
      >
        {options.map((o) => (
          <Select.Option value={o.value} label={o.label}>
            <div>
              <div className="whitespace-pre-wrap break-words pb-1">{o.label}</div>
              <FolderPathAndCreator path={o.path} createdBy={o.createdBy} />
            </div>
          </Select.Option>
        ))}
      </Select>
      <div className="px-3">
        <FolderPathAndCreator path={selectedOption?.path} createdBy={selectedOption?.createdBy} />
      </div>
    </div>
  )
}

import { t } from 'i18next'

import { JA } from './constant/i18n/ja'
import { SALESCORE_GOAL_MODEL } from './constant/models/SALESCORE_GOAL_MODEL'
import { SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL } from './constant/models/SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL'
import { SALESCORE_USER_MODEL, USER_MODEL_ID_PROPERTY } from './constant/models/SALESCORE_USER_MODEL'
import { SALESCORE_USER_WITH_GROUP_MODEL } from './constant/models/SALESCORE_USER_WITH_GROUP_MODEL'
import type { ViewConfig, ViewConfigDimension, ViewConfigField, ViewConfigKpiPreset } from './schemas/view_config'

const KPI_PIVOT_VALUE_COLUMN_NAME = '_value'
const KPI_PIVOT_ROLE_COLUMN_NAME = '_role'
const KPI_PIVOT_KPI_ID_COLUMN_NAME = '_kpi_id'
const KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME = '_kpi_group'

const KPI_PIVOT_USER_ID_COLUMN_NAME = `salescore_users_id`
const KPI_GROUP_DEFAULT_NAME = `メインKPI`

const SNAPSHOT_COMPARE_MODEL_NAME = 'salescore_$snapshots_$compare'
const WATERFALL_DIMENSION_FIELD_NAME = '$dimension'

const KPI_PIVOT_USER_DIMENSION = (): ViewConfigDimension => ({
  type: 'property',
  key: KPI_PIVOT_USER_ID_COLUMN_NAME,
  property: {
    nodeName: SALESCORE_USER_WITH_GROUP_MODEL.name,
    modelName: SALESCORE_USER_WITH_GROUP_MODEL.name,
    propertyName: USER_MODEL_ID_PROPERTY.name,
  },
  fieldType: 'string',
  label: t(`ユーザー`) ?? `ユーザー`,
})

const KPI_PIVOT_KPI_DIMENSION: ViewConfigDimension = {
  type: 'fieldName',
  key: KPI_PIVOT_KPI_ID_COLUMN_NAME,
  fieldName: KPI_PIVOT_KPI_ID_COLUMN_NAME,
  fieldType: 'string',
  label: `KPI`,
}

const KPI_PIVOT_KPI_GROUP_DIMENSION = (): ViewConfigDimension => ({
  type: 'fieldName',
  key: KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME,
  fieldName: KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME,
  fieldType: 'string',
  label: t(`KPIグループ`) ?? `KPIグループ`,
})

const KPI_PIVOT_KPI_DATE_DIMENSION = (): ViewConfigDimension => ({
  type: 'alias',
  key: `KPI_DATE`,
  alias: `KPI_DATE`,
  fieldType: 'date',
  label: t(`KPI日付`) ?? `KPI日付`, // TODO: どうするのが分かりやすい…？？
})

// XXX: あくまでプロパティなので、これをそのまま使うわけではない
const KPI_PIVOT_KPI_USER_GROUP_DIMENSION = (): ViewConfigDimension => ({
  type: 'property',
  key: 'p_salescore_user_groups_d1_id',
  property: {
    nodeName: 'salescore_user_groups_d1',
    modelName: 'salescore_user_groups',
    propertyName: 'id',
  },
  label: t(`グループ`) ?? `グループ`,
  fieldType: 'string',
})

const KPI_PIVOT_DEFAULT_PRESET = (): ViewConfigKpiPreset => ({
  name: `デフォルト`,
  parameter: {
    pivot: {
      rows: [KPI_PIVOT_USER_DIMENSION()],
      columns: [KPI_PIVOT_KPI_GROUP_DIMENSION(), KPI_PIVOT_KPI_DIMENSION],
    },
  },
})

const PIVOT_EMPTY_PRESET = ({ type }: Pick<ViewConfig, 'type'>): ViewConfigKpiPreset => {
  if (type === 'kpiTimeSeries') {
    // RI の場合は内訳にユーザを指定するとは限らない
    return {
      name: `デフォルト`,
      parameter: {
        pivot: {
          rows: [],
          columns: [],
        },
      },
    }
  }
  return {
    name: `デフォルト`,
    parameter: {
      pivot: {
        rows: [
          KPI_PIVOT_USER_DIMENSION(), // 2023/02 ユーザーはデフォルトで表示したいということになった
        ],
        columns: [],
      },
    },
  }
}

const KPI_PIVOT_SYSTEM_DIMENSIONS = (): ViewConfigDimension[] => [KPI_PIVOT_KPI_DIMENSION, KPI_PIVOT_USER_DIMENSION()]

const WATERFALL_DIMENSION_FIELD = (): ViewConfigField => ({
  type: 'property',
  property: {
    modelName: SNAPSHOT_COMPARE_MODEL_NAME,
    nodeName: SNAPSHOT_COMPARE_MODEL_NAME,
    propertyName: WATERFALL_DIMENSION_FIELD_NAME,
  },
  override: {
    uiColumn: {
      columnDeletable: false,
    },
  },
})
const WATERFALL_DRILL_DOWN_DEFAULT_FIELDS = (): ViewConfigField[] => {
  return [
    {
      type: 'property',
      property: {
        modelName: SNAPSHOT_COMPARE_MODEL_NAME,
        nodeName: SNAPSHOT_COMPARE_MODEL_NAME,
        propertyName: 'id',
      },
    },
    WATERFALL_DIMENSION_FIELD(),
  ]
}

export const CORE_CONSTANT = {
  KPI_PIVOT_TOTAL_STRING: `_TOTAL_`,
  KPI_PIVOT_NULL_STRING: `_NULL_`,
  KPI_PIVOT_USER_ID_COLUMN_NAME,
  KPI_PIVOT_KPI_ID_COLUMN_NAME,
  KPI_PIVOT_KPI_DATE_DIMENSION,
  KPI_PIVOT_USER_DIMENSION,
  KPI_PIVOT_KPI_USER_GROUP_DIMENSION,
  KPI_PIVOT_KPI_DIMENSION,
  KPI_PIVOT_KPI_GROUP_DIMENSION,
  KPI_GROUP_DEFAULT_NAME,
  KPI_PIVOT_SYSTEM_DIMENSIONS,
  KPI_PIVOT_DEFAULT_PRESET,
  PIVOT_EMPTY_PRESET,
  USER_MODEL_NAMES: [
    // どこで持つべきか？
    `salescore_users`,
    `salesforce_user`,
    `hubspot_owners`,
    `zoho_user`,
    // `next_sfa_user_info`,
    `google_sheets_users`,
    // `comdesk_staff`,
    // `senses_users`,
    `microsoft_dynamics_systemusers`,
    `big_query_users`,
  ],
  USER_GROUP_CATEGORY_NAMES: ['チーム'],
  VIEW_NEW_RECORD_PREFIX: 'salescore_new_record',
  KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX: 'kpi-sheet_', // KPIで「一覧」パネルを押した時に作成される擬似ビューのID。このprefix + 元のKPIビューのID、という形にしている
  SHEET_FORM_DYNAMIC_VIEW_ID_PREFIX: 'sheet-form_', // シートからフォームを開いた時の擬似ビューのID。このprefix + 元のビューのID、という形にしている
  KPI_PREVIEW_VIEW_ID_PREFIX: 'kpi-preview_', // KPI プレビューのID。このprefix + 元のKPIビューのID、という形にしている
  KPI_PREVIEW_DRILL_DOWN_VIEW_ID_PREFIX: 'preview_view_id_drill_down_', // KPI プレビューでドリルダウンした際に作成される擬似ビューのID。このprefix + 元のKPIビューのID、という形にしている
  KPI_TIME_SERIES_DRILL_DOWN_VIEW_ID_PREFIX: 'time_series_drill_down_', // KPI タイムシリーズ(RI)でドリルダウンした際に作成される擬似ビューのID。このprefix + 元のKPIビューのID、という形にしている
  // compileで使う値
  KPI_PIVOT_VALUE_COLUMN_NAME,
  KPI_PIVOT_ROLE_COLUMN_NAME,
  KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME,
  // どこで持つべきか…？
  EMOJI: {
    KPI: '📊',
    KPI_PIVOT: '🧭',
  },
  SALESCORE_USER_MODEL,
  SALESCORE_USER_WITH_GROUP_MODEL,
  SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL,
  SALESCORE_GOAL_MODEL,
  I18N: {
    JA,
  },
  SNAPSHOT_VARIABLE_NAME: 'snapshot',
  SNAPSHOT_VARIABLE_DAY_PREFIX: 'day',
  AGGREGATION_NO_GROUPING_ROOT_ID: 'noGroupingRoot',
  AGGREGATION_QUERY_LIMIT: 1000, // 5000にしたとき、NECさんで固まった
  AGGREGATION_ROWS_LIMIT: 1000,
  DIMENSION_FIELD_NAME_PREFIX: 'dimension',
  // SALESCOREカスタムモデルのプレフィックス（どちらかというとELT側の設定だが、clientでも使うのでここで定義。定義場所が悩ましい）
  SALESCORE_CUSTOM_MODEL_PREFIX: 'custom_s_',
  // NOTE: userCustomizedSalescoreSourceの接頭辞をここで定義している
  //       バックエンドでのvalidationはしていないので、悪意あるユーザーがリクエストを改変すれば接頭辞を改変することができるが、
  //       この接頭辞は分かりやすさのために統一しているだけであり、ロジック上は統一された接頭辞は不要なので、改変を気にする必要性はあまりない
  CUSTOM_MODEL_PREFIX: 'custom_d_',
  RENDER_DURATION_SAMPLE_SIZE: 5, // view読み込みにかかる時間を予測するために、過去何件のdurationを参照するか
  SNAPSHOT_MODEL_PREFIX: '$snapshot',
  SNAPSHOT_SEQUENCE_TABLE_SUFFIX: '$sequence',
  SNAPSHOT_TABLE_SURROGATE_KEY: '$id',
  SNAPSHOT_SEQUENCE_ID: '$sequence_id',
  SNAPSHOT_COMPARE_MODEL_NAME,
  WATERFALL_FIRST_PERIOD_NODE_SUFFIX: 'p1',
  WATERFALL_SECOND_PERIOD_NODE_SUFFIX: 'p2',
  WATERFALL_DIMENSION_FIELD_NAME,
  WATERFALL_DIMENSION_FIELD,
  WATERFALL_DRILL_DOWN_DEFAULT_FIELDS,
}

import { CONSTANT } from '@salescore/client-base'
import type { CoreDslFormSnapshotVariable } from '@salescore/core'
import { Alert, Button, InputNumber, Select, Space } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import { Trans } from 'react-i18next'

import type { TargetProperty } from './CoreDslForm'

type DateSpan = 'day' | 'week' | 'month' | 'year'
export const dateSpanMapper: { [k in DateSpan]: string } = {
  day: t(`日`),
  week: t(`週間`),
  month: t(`月`),
  year: t(`年`),
}

export function CoreDslFormSnapshotVariableFormItem({
  onFinish,
  targetProperty,
}: {
  onFinish: (term: CoreDslFormSnapshotVariable) => void
  targetProperty: Omit<TargetProperty, 'model'>
}) {
  const [value, setValue] = useState(1) // TODO: 現在の値の表示
  const [dateSpan, setDateSpan] = useState<DateSpan>('week') // TODO: 現在の値の表示
  if (!targetProperty.nodePropertyName.modelName.startsWith(`salesforce`)) {
    return (
      <Alert style={{ width: 400 }} type="warning" message={<div>{t(`この機能はSalesforceのみで有効です。`)}</div>} />
    )
  }

  if (targetProperty.historyModel === undefined) {
    // TODO: デザイン
    return (
      <div>
        <Alert
          style={{ width: 400 }}
          type="warning"
          message={
            <div>
              {t(`履歴オブジェクトが同期されていません。`)}
              <br />
              {t(`ハイライト条件機能を使うには、履歴オブジェクトを同期する必要があります。`)}
              <br />
              <Trans>
                <a
                  href={CONSTANT.link.helpForHighlight}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    // 上位コンポーネントでpreventDefaultなどしている影響？でなぜかリンクが機能しないので、ここでwindow.open()する
                    window.open(CONSTANT.link.helpForHighlight)
                  }}
                >
                  こちら
                </a>
                を参考に設定を行ってください。
              </Trans>
            </div>
          }
        />
      </div>
    )
  }

  if (targetProperty.property.trackable !== true) {
    // TODO: デザイン
    return (
      <div>
        <Alert
          style={{ width: 400 }}
          type="warning"
          message={
            <div>
              {t(`この項目は、Salesforceで項目履歴管理が有効になっていません。`)}
              <br />
              {t(`ハイライト条件機能を使うには、項目履歴管理を有効にする必要があります。`)}
              <br />
              <Trans>
                <a
                  href={CONSTANT.link.helpForHighlight}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    // 上位コンポーネントでpreventDefaultなどしている影響？でなぜかリンクが機能しないので、ここでwindow.open()する
                    window.open(CONSTANT.link.helpForHighlight)
                  }}
                >
                  こちら
                </a>
                を参考に設定を行ってください。
              </Trans>
            </div>
          }
        />
      </div>
    )
  }

  return (
    <Space>
      <InputNumber
        value={value}
        onChange={(v) => {
          setValue(v ?? 0)
        }}
      />
      <Select
        style={{ width: 90 }}
        onClick={(e) => {
          e.stopPropagation()
        }}
        options={[
          {
            value: 'day',
            label: t(`日前`),
          },
          {
            value: 'week',
            label: t(`週間前`),
          },
          {
            value: 'month',
            label: t(`ヶ月前`),
          },
          {
            value: 'year',
            label: t(`年前`),
          },
        ]}
        onChange={(v) => {
          setDateSpan(v)
        }}
        value={dateSpan}
      />
      <Button
        type="primary"
        onClick={() => {
          onFinish({
            type: 'snapshotVariable',
            dateSpan,
            before: value,
            property: targetProperty.property,
            nodePropertyName: targetProperty.nodePropertyName,
          })
        }}
      >
        {t(`決定`)}
      </Button>
    </Space>
  )
}

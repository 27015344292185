import { Logo } from './Logo'

const SIZES = {
  small: 400,
  medium: 600,
}

export const CardContainer = ({
  children,
  withLogo = true,
  size = 'small',
}: {
  children: JSX.Element
  withLogo?: boolean
  size?: 'small' | 'medium'
}) => {
  return (
    <div className="flex size-full flex-col items-center justify-center text-center align-middle">
      {withLogo && (
        <div className="mb-8 w-64">
          <Logo />
        </div>
      )}
      <div
        style={{ width: SIZES[size], maxWidth: '100%', maxHeight: 800 }}
        className="overflow-y-scroll rounded-2xl bg-white p-8"
      >
        {children}
      </div>
    </div>
  )
}

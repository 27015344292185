import { PageHeader } from '@ant-design/pro-layout'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import {
  FetchUserGroupsDocument,
  UpdateUserGroupByCsvDocument,
  UpdateUserGroupIdsByCsvDocument,
} from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { getOrganizationIdFromPath, UploadCsvFileByBase64Button } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { CsvDownloadButton, useBooleanState, useRedirect } from '@salescore/frontend-common'
import { Alert, message, Radio, Space, Table } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

const USER_GROUP_LABEL = `ユーザーグループ名`
const USER_LABEL = `ユーザー名`
const USER_MAIL = `メールアドレス`

const columns = [USER_LABEL, `user_id`, USER_GROUP_LABEL, `user_group_id`, USER_MAIL]

export const ImportUserGroupIdsByCsvForm = () => {
  const redirect = useRedirect()
  const me = recoil.global.useMe()
  const [updateUserGroupIdsByCsv] = useMutation(UpdateUserGroupIdsByCsvDocument)
  const { data } = useSuspenseQuery(FetchUserGroupsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })
  const [updateUserGroupByCsv] = useMutation(UpdateUserGroupByCsvDocument)
  const [operationType, setOperationType] = useState<string>('updateUserGroupId')
  const userGroups = data.userGroups
  const loading = useBooleanState()
  const [errors, setErrors] = useState<
    Array<{
      index: number
      message: string
      record: unknown
    }>
  >([])
  const [imported, setImported] = useState<
    Array<{
      index: number
      record: unknown
    }>
  >([])

  return (
    <PageHeader
      title={<div>{t(`グループの一括設定`)}(csv)</div>}
      onBack={() => {
        redirect(routes.userGroupsSettingsPathV2())
      }}
    >
      <div>
        {t(`グループ設定をcsv形式でインポートすることができます。`)}
        <br />
        {t(
          `ユーザーの所属を変更する場合は、以下サンプルファイルをダウンロードして「user_group_id」の列を埋めてください。`,
        )}
        <div className="mb-8 mt-2">
          <CsvDownloadButton
            text={t(`サンプルファイルをダウンロード`)}
            filename={t(`ユーザーのグループ設定`)}
            headers={[...columns]}
            data={() => {
              const usersWithUserGroup = userGroups.flatMap((userGroup) =>
                userGroup.users.map((user) => ({ user, userGroup })),
              )
              return usersWithUserGroup.map((x) => {
                return [x.user.name, x.user.id, x.userGroup.name, x.userGroup.id.replace('no-team', ''), x.user.email]
              })
            }}
          />
        </div>
        {t(`グループ名を変更する場合は、以下グループ一覧をダウンロードして「グループ名」の列を変更してください`)}
        <br />
        <Space className="mb-8 mt-2">
          {/* ユーザー一覧側のcsv出力と合わせること（微妙に違いがあると紛らわしいため） */}
          <CsvDownloadButton
            key="button-csv-download"
            text={t(`グループ一覧をダウンロード`)}
            data={userGroups.map((x) => [x.id.replace('no-team', ''), x.user_group_id, x.name])}
            filename={t(`グループ一覧`)}
            headers={[t(`グループID(user_group_id)`), t(`親グループID`), t(`グループ名`)]}
          />
        </Space>
        <Alert
          // showIcon
          type="info"
          className="mb-4"
          description={
            <div>
              {t(`・文字コードをUTF-8にしてアップロードしてください。`)}
              <br />
              {t(
                `・テンプレートのcsvの列は並び替え可能です。列名は変えないでください。不要な列が入っているとエラーになります。`,
              )}
            </div>
          }
        />
        {t(`変更したい種類を選択してください`)}
        <br />
        <Radio
          onClick={() => {
            setOperationType('updateUserGroupId')
          }}
          checked={operationType === 'updateUserGroupId'}
        >
          ユーザーの所属の変更
        </Radio>
        <Radio
          onClick={() => {
            setOperationType('updateUserGroupName')
          }}
          checked={operationType === 'updateUserGroupName'}
        >
          グループ名の変更
        </Radio>
        <div className="mt-4" style={{ width: 400 }}>
          <UploadCsvFileByBase64Button
            loading={loading.isTrue}
            onLoad={async (base64: string, decoded: string) => {
              if (decoded === undefined) {
                return
              }
              void message.info(t(`CSVのアップロードを開始しました`))
              try {
                loading.setTrue()
                if (operationType === 'updateUserGroupId') {
                  const { data: result } = await updateUserGroupIdsByCsv({
                    variables: {
                      organizationId: getOrganizationIdFromPath(),
                      csv: decoded,
                    },
                  })
                  const res = result?.updateUserGroupIdsByCsv
                  if (isNull(res)) {
                    void message.error(`${t(`エラーが発生しました。`)} ${JSON.stringify(result)}`)
                    return
                  }
                  if (res.successCount > 0) {
                    void message.success(
                      t(`{{successCount}}件のレコードを更新しました。`, { successCount: res.successCount }),
                    )
                  }
                  if (res.errors.length > 0) {
                    void message.error(
                      t(`{{errorLength}}件のエラーが発生しました。`, { errorLength: res.errors.length }),
                    )
                  }
                  setErrors(res.errors)
                  setImported(res.success)
                } else {
                  const { data: result } = await updateUserGroupByCsv({
                    variables: {
                      organizationId: getOrganizationIdFromPath(),
                      csv: decoded,
                    },
                  })
                  const res = result?.updateUserGroupByCsv
                  if (isNull(res)) {
                    void message.error(`${t(`エラーが発生しました。`)} ${JSON.stringify(result)}`)
                    return
                  }
                  if (res.successCount > 0) {
                    void message.success(
                      t(`{{successCount}}件のレコードを更新しました。`, { successCount: res.successCount - 1 }),
                    )
                  }
                  if (res.errors.length > 0) {
                    void message.error(
                      t(`{{errorLength}}件のエラーが発生しました。`, { errorLength: res.errors.length }),
                    )
                  }
                  setErrors(res.errors)
                  setImported(res.success)
                }
              } catch (error) {
                if (error instanceof Error) {
                  void message.error(`${t(`エラーが発生しました。`)} ${error.message}`)
                } else {
                  void message.error(t(`エラーが発生しました。`))
                }
              } finally {
                loading.setFalse()
              }
            }}
          />
        </div>
      </div>
      {errors.isPresent() && (
        <div>
          <Table
            title={() => <>{t(`エラー行(最大100行まで表示)`)}</>}
            dataSource={errors}
            columns={[
              {
                key: `index`,
                dataIndex: `index`,
                title: t(`行番号`),
              },
              {
                key: `message`,
                dataIndex: `message`,
                title: t(`エラー`),
              },
              {
                key: `record`,
                dataIndex: `record`,
                title: t(`該当行`),
                render: (value) => {
                  return <div>{JSON.stringify(value)}</div>
                },
              },
            ]}
          />
        </div>
      )}
      {imported.isPresent() && (
        <div>
          <Table
            title={() => <>{t(`インポートした行(最大100行まで表示)`)}</>}
            dataSource={imported}
            columns={[
              {
                key: `index`,
                dataIndex: `index`,
                title: t(`行番号`),
              },
              {
                key: `user_group_id`,
                dataIndex: `user_group_id`,
                title: t(`グループID`),
                render: (value, record) => {
                  return <div>{(record.record as Record<string, string>).user_group_id}</div>
                },
              },
              operationType === 'updateUserGroupId'
                ? {
                    key: `user_id`,
                    dataIndex: `user_id`,
                    title: t(`ユーザーID`),
                    render: (value, record) => {
                      return <div>{(record.record as Record<string, string>).user_id}</div>
                    },
                  }
                : {
                    key: `user_group_name`,
                    dataIndex: `user_group_name`,
                    title: t(`グループ名`),
                    render: (value, record) => {
                      return <div>{(record.record as Record<string, string>).user_group_name}</div>
                    },
                  },
            ]}
          />
        </div>
      )}
    </PageHeader>
  )
}

import { calculateCharLength } from '@salescore/frontend-common'
import { useEffect, useRef, useState } from 'react'

import { useUpsertSheetRowMutation } from '../../../../recoil/mutations/upsertSheetRowMutation'
import type { RSheetsCellInputRenderTypeArgument } from '../InputCell'
import { RSheetsInputCellWrapper } from './InputCellWrapper'

const MAX_WIDTH = 500
const LINE_HEIGHT = 22
const HEIGHT_PADDING = 20
const LINE_CHARACTERS = 64 // 微妙なバランスを頑張ってとっている

export function RSheetsCellStirngInput({
  recordNode,
  column,
  rowIndex,
  innerRowIndex,
  defaultWidth,
  finishEditMode,
  boxStyle,
}: RSheetsCellInputRenderTypeArgument) {
  const columnOnChange = useUpsertSheetRowMutation()
  const initialValue = recordNode === undefined ? undefined : column.value(recordNode)
  const [value, setValue] = useState<string>(typeof initialValue === 'string' ? initialValue : '')
  const [composing, setComposing] = useState(false)

  const reference = useRef<HTMLTextAreaElement>(null)
  const lines = value.split('\n')
  const rows = lines.length + lines.map((text) => Math.floor(calculateCharLength(text) / LINE_CHARACTERS)).sum()

  useEffect(() => {
    setTimeout(() => {
      if (reference.current) {
        reference.current.focus()
        reference.current.setSelectionRange(1000, 1000)
      }
    }, 10)
  }, [])

  return RSheetsInputCellWrapper(
    {
      async onChangeFixed() {
        await columnOnChange({ value, rowIndex, innerRowIndex, column })
      },
      finishEditMode,
    },
    ({ handleKeyDown }) => {
      return (
        <div
          style={{
            ...boxStyle,
            // Spreadsheetと似たロジックで、中身の文字列が増えたら幅を広げて全部表示させる。
            // 他のセルの位置に影響を与えずこれを実現するため、position: absoluteにして実現している
            width: Math.min(Math.max(defaultWidth, ...lines.map((line) => calculateCharLength(line) * 10)), MAX_WIDTH),
            height: rows * LINE_HEIGHT + HEIGHT_PADDING,
            padding: '6px 10px', // padingYはボーダーにかぶらなければなんでも良い
            marginTop: 1, // これがないと1pxずれるが、Cell側と比べてなぜ1px分の差があるのか不明
            marginLeft: 1,
            // boxShadow: '1px 4px 7px 2px rgb(60 64 67 / 15%)',
            backgroundColor: 'white',
          }}
        >
          <textarea
            // bordered={false}
            ref={reference}
            value={value} // TODO
            onChange={(e) => {
              setValue(e.target.value)
            }}
            // 漢字変換中かどうかの判定
            onCompositionStart={(e) => {
              setComposing(true)
            }}
            onCompositionEnd={(e) => {
              setComposing(false)
            }}
            onKeyDown={(e) => {
              if (!composing) {
                handleKeyDown(e)
              }
            }}
            rows={rows}
            style={{
              backgroundColor: 'white',
              border: 'none',
              padding: 0,
              paddingBottom: 10,
              outline: 'none',
              width: '100%',
              height: '100%', // 複数行にしたい場合は対応が必要
              // width: Math.max(defaultWidth - 20, calculateCharLength(value as string) * 10)
              // width: 400,
            }}
          />
        </div>
      )
    },
  )
}

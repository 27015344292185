import type { NodePath, ViewQueryRecordNode } from '../../schemas/query'

export type ViewQueryRecordNodeWithParent = ViewQueryRecordNode & {
  parent?: ViewQueryRecordNodeWithParent
}

// recordsは元のnodeのchildrenの配列オブジェクトそのものではないので注意。pushなどしても元のrootには反映されない。
export function getRecordNodesByPath(
  recordNode: ViewQueryRecordNodeWithParent,
  nodePath: NodePath,
): ViewQueryRecordNodeWithParent[] {
  if (nodePath.length === 0) {
    // ありえないはず
    return []
  }

  if (nodePath.length === 1) {
    // 指定しているパスがルートであればそのまま返す
    return [recordNode]
  }

  const childTableNode = recordNode.children.find((x) => x.nodeName === nodePath[1]!)
  if (childTableNode === undefined) {
    return []
  }
  return childTableNode.children
    .flatMap((childRecordNode) => getRecordNodesByPath(childRecordNode, nodePath.slice(1)))
    .compact()
    .map((childRecordNode) => ({
      ...childRecordNode,
      parent: recordNode,
    }))
}

// 各親のattrbiutesに重複がない前提でマージする（主にKPI用）
export function flatRecordNodes(
  recordNode: ViewQueryRecordNode,
  parents: ViewQueryRecordNode[],
): Array<Record<string, unknown>> {
  if (recordNode.children.isBlank()) {
    const xs = [...parents, recordNode].map((x) => x.attributes)
    return [xs.reduce<Record<string, unknown>>((accumulator, x) => ({ ...accumulator, ...x }), {})]
  }

  return recordNode.children.flatMap((childTableNode) => {
    return childTableNode.children.flatMap((childRecordNode) =>
      flatRecordNodes(childRecordNode, [...parents, recordNode]),
    )
  })
}

export function getRecordNodeParents(recordNode: ViewQueryRecordNodeWithParent): ViewQueryRecordNode[] {
  if (recordNode.parent === undefined) {
    return [recordNode]
  }

  return [recordNode, ...getRecordNodeParents(recordNode.parent)]
}

export function getRowRecordIds(row: ViewQueryRecordNode): string[] {
  const ids: Array<string | undefined> = []
  const records = [row]
  while (records.length > 0) {
    const record = records.shift()
    if (record === undefined) {
      continue
    }
    ids.push(record.id)
    for (const table of record.children) {
      for (const record of table.children) {
        records.push(record)
      }
    }
  }
  return ids.compact()
}

import {
  ClearOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  FilterOutlined,
  PlusOutlined,
  SettingOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import { CopyViewDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import type { ViewConfigKpiPivot } from '@salescore/core'
import { useMessage, useOnClickOutside } from '@salescore/frontend-common'
import { Button, Popconfirm } from 'antd'
import { t } from 'i18next'
import { type CSSProperties, useEffect, useRef } from 'react'
import { useSetRecoilState } from 'recoil'

import { useKpiPivotNavigationModal, useKpiPivotParameter, useNavigationModal } from '../../../recoil/navigation/hooks'
import { hasChangeToViewSchemaAtom } from '../../../recoil/view/atoms'
import {
  useViewAbilityValue,
  useViewConfigKpiPivot,
  useViewsContextValue,
  useViewValue,
} from '../../../recoil/view/hooks'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import { RSheetsStyle } from '../../util/RSheetsStyle'

export interface ContextMenuItem {
  text: string | JSX.Element
  icon: JSX.Element
  disabled?: boolean
  className?: string
  onClick?: () => void
  confirmMessage?: string | JSX.Element
  tooltipMessage?: string
}

interface ContextMenuProperties {
  menu: ContextMenuItem[]
  style: CSSProperties
  hideContextMenu: () => void
}

const baseStyle = {
  backgroundColor: 'white',
  boxShadow: '0 2px 6px 2px rgb(60 64 67 / 15%)',
  borderRadius: 2,
  zIndex: RSheetsStyle.zIndex.contextMenu,
}

export function ContextMenu({ menu, style, hideContextMenu }: ContextMenuProperties) {
  const reference = useRef<HTMLDivElement>(null)
  useOnClickOutside(reference, () => {
    hideContextMenu()
  }, ['anticon', 'disable-on-click-outside'])
  useEffect(() => {
    if (reference.current !== null) {
      reference.current.focus()
    }
  }, [reference.current])

  return (
    <div
      ref={reference}
      style={{
        ...baseStyle,
        ...style,
      }}
    >
      {menu.map((item) =>
        item.confirmMessage === undefined ? (
          <Button
            type="text"
            style={{ width: '100%', textAlign: 'left' }}
            icon={item.icon}
            disabled={item.disabled}
            onClick={item.onClick}
            className={item.className ?? ''}
          >
            {item.text}
          </Button>
        ) : (
          <Popconfirm
            overlayClassName="disable-on-click-outside"
            title={item.confirmMessage}
            onConfirm={item.onClick}
            disabled={item.disabled}
          >
            <Button
              type="text"
              style={{ width: '100%', textAlign: 'left' }}
              icon={item.icon}
              disabled={item.disabled}
              className={item.className ?? ''}
            >
              {item.text}
            </Button>
          </Popconfirm>
        ),
      )}
    </div>
  )
}

interface KpiContextMenuProperties {
  kpiId: string | undefined
  hideContextMenu: () => void
  setAddKpiDropdownVisible: (visibility: boolean) => void
  addKpiDirection: 'right' | 'down'
}

export function useKpiContextMenu({
  kpiId,
  hideContextMenu,
  setAddKpiDropdownVisible,
  addKpiDirection,
}: KpiContextMenuProperties) {
  const { drillDownModal } = useNavigationModal()
  const [kpiParameter, setKpiParameter] = useKpiPivotParameter()
  const { kpiBulkDuplicateModal } = useKpiPivotNavigationModal()
  const ability = useViewAbilityValue()
  const setHasChangeToViewSchema = useSetRecoilState(hasChangeToViewSchemaAtom)
  const [copy] = useMutation(CopyViewDocument)
  const view = useViewValue()
  const [config, setConfig] = useViewConfigKpiPivot()
  const context = useViewsContextValue()
  const message = useMessage()

  if (kpiId === undefined) {
    return []
  }

  const addKpi = async (previousKpiId: string, kpiViewId: string) => {
    const oldKpis = config?.kpis ?? []
    const previousKpiIndex = [oldKpis.findIndex((x) => x.viewId === previousKpiId) ?? -1, -1].max()! + 1
    const previousKpi = oldKpis[previousKpiIndex]
    const newKpi: ViewConfigKpiPivot['kpis'][0] = {
      viewId: kpiViewId,
      kpiGroupName: previousKpi?.kpiGroupName,
    }
    const kpis = [...oldKpis.slice(0, previousKpiIndex), newKpi, ...oldKpis.slice(previousKpiIndex)].uniqueBy(
      (x) => x.viewId,
    )
    try {
      await context.onAddResource({ viewIds: [kpiViewId] })

      const newConfig = {
        ...config!,
        kpis,
      }

      setConfig(newConfig)

      // WORKAROUND:
      // configの変更の保存ロジックは、変更後にhasChangeToViewSchemaAtomをtrueにすることで、
      // それをトリガーにuseEffectが呼ばれてその中でupdateViewを実行するのが一般的。
      // （ここのロジックはuseWatchを参照）
      // しかし、KPIを新規作成するとき、別タブが開いてしまう影響でこのuseEffectが呼ばれず、updateViewが実行されない。
      // そのため、ここで明示的にupdateViewを行う
      // 権限がない場合はここまで辿り着かず、またサーバーサイドでエラーになる想定
      await context.updateView({
        id: view.id,
        config: newConfig,
      })
    } catch {
      message.error(t(`エラーが発生しました`))
    }
  }

  return [
    {
      icon: <EditOutlined />,
      onClick: () => {
        drillDownModal.showModal({
          viewId: kpiId,
          type: `any`,
        })
        hideContextMenu()
      },
      text: t(`KPI設定`),
    },
    {
      icon: <PlusOutlined />,
      onClick: () => {
        setAddKpiDropdownVisible(true)
      },
      text: addKpiDirection === 'right' ? t(`右にKPIを追加`) : t(`下にKPIを追加`),
      disabled: !ability.canUpdate,
    },
    {
      icon: <EyeInvisibleOutlined />,
      onClick: () => {
        setKpiParameter((oldValue) => {
          const kpiIds = new Set((config?.kpis ?? []).map((x) => x.viewId))
          return {
            ...oldValue,
            invisibleKpiIds: [...(oldValue.invisibleKpiIds ?? []), kpiId].unique().filter((id) => kpiIds.has(id)), // 削除されているkpiがinvisibleに残り続けることがあるので、ここで除去してしまう
          }
        })
        hideContextMenu()
      },
      text: t(`KPIを非表示`),
    },
    {
      icon: <CopyOutlined />,
      onClick: async () => {
        try {
          const { data: result } = await copy({
            variables: {
              organizationId: getOrganizationIdFromPath(),
              id: kpiId,
            },
          })
          const savedView = result?.copyView
          if (isNull(savedView)) {
            message.warning(t(`エラーが発生しました`))
            return
          }

          message.success(t(`「{{name}}」を作成しました。`, { name: savedView.name }))
          await context.refetchViews()
          await addKpi(kpiId, savedView.id)
          hideContextMenu()
        } catch (error) {
          if (error instanceof Error) {
            message.error(`${t(`エラーが発生しました。`)}${error.message}`)
          } else {
            message.error(t(`エラーが発生しました`))
          }
        }
      },
      text: t(`KPIを複製`),
      disabled: !ability.canCreate,
    },
    {
      icon: <CopyOutlined />,
      onClick: () => {
        kpiBulkDuplicateModal.showModal()
      },
      text: t(`KPIを一括複製`),
      disabled: !ability.canCreate,
    },
    {
      icon: <DeleteOutlined />,
      onClick: () => {
        const deleteKpi = config?.kpis.find((x) => x.viewId === kpiId)
        const deleteKpisGroupCount = config?.kpis.filter((x) => x.kpiGroupName === deleteKpi?.kpiGroupName).length ?? 0
        setConfig({
          ...config!,
          kpis:
            config?.kpis
              .map((x) => {
                // KPI削除後にKPIグループが空になる場合、グループが残るようにviewIdに重複しない仮の値を入れる
                if (x.viewId === deleteKpi?.viewId) {
                  return deleteKpisGroupCount > 1
                    ? undefined
                    : {
                        viewId: `${view.id}-${deleteKpi.kpiGroupName}-noneKpiGroup`,
                        kpiGroupName: deleteKpi.kpiGroupName,
                      }
                }
                return x
              })
              .compact() ?? [],
        })
        setHasChangeToViewSchema(true)
        hideContextMenu()
      },
      text: t(`KPIを削除`),
      disabled: !ability.canUpdate,
      className: `text-red-500`,
      confirmMessage: t(`本当に削除しますか？`),
    },
  ]
}

interface useHeaderColumnContextMenuProperties {
  column: RSheetColumn<RSheetRecordNode>
  hideContextMenu: () => void
}

export function useHeaderColumnContextMenu({ column, hideContextMenu }: useHeaderColumnContextMenuProperties) {
  const [kpiParameter, setKpiParameter] = useKpiPivotParameter()
  const [config, setConfig] = useViewConfigKpiPivot()
  const setHasChangeToViewSchema = useSetRecoilState(hasChangeToViewSchemaAtom)

  function setKpiParameterOrder(key: string, order: 'asc' | 'desc') {
    setKpiParameter({
      ...kpiParameter,
      sorter: {
        columnKeys: [{ key, order }].compact(),
      },
    })
    setHasChangeToViewSchema(true)
    hideContextMenu()
  }

  const field = column.field
  if (field === undefined) {
    return []
  }
  return [
    column.sortState === 'asc'
      ? undefined
      : {
          icon: <SortAscendingOutlined />,
          onClick: () => {
            setKpiParameterOrder(field.name, 'asc')
          },
          text: t(`昇順で並び替え`),
        },
    column.sortState === 'desc'
      ? undefined
      : {
          icon: <SortDescendingOutlined />,
          onClick: () => {
            setKpiParameterOrder(field.name, 'desc')
          },
          text: t(`降順で並び替え`),
        },
    column.sortState === undefined
      ? undefined
      : {
          icon: <ClearOutlined />,
          onClick: () => {
            setKpiParameter({
              ...kpiParameter,
              sorter: {
                columnKeys: undefined,
              },
            })
            setHasChangeToViewSchema(true)
            hideContextMenu()
          },
          text: t(`並び替えを解除`),
        },
    {
      icon: <ClearOutlined />,
      onClick: () => {
        setConfig({
          ...config!,
          ui: {
            ...config!.ui,
            appearance: undefined,
          },
        })
        setHasChangeToViewSchema(true)
        hideContextMenu()
      },
      text: t(`列幅設定のリセット`),
    },
  ].compact()
}

interface useHeaderRowContextMenuProperties {
  setDimensionFilterDropdownVisible: (visibility: boolean) => void
  setDimensionSorterDropdownVisible: (visibility: boolean) => void
  setAddRowDimensionDropdownVisible: (visibility: boolean) => void
  hideContextMenu: () => void
  rowIndex: number
  column: RSheetColumn<RSheetRecordNode>
}

export function useHeaderRowContextMenu({
  setDimensionFilterDropdownVisible,
  setDimensionSorterDropdownVisible,
  setAddRowDimensionDropdownVisible,
  hideContextMenu,
  rowIndex,
  column,
}: useHeaderRowContextMenuProperties) {
  const [kpiPivotParameter, setKpiPivotParameter] = useKpiPivotParameter()
  const { kpiPivotKpisModal } = useKpiPivotNavigationModal()

  return [
    // TODO: 行のフィルタと列のフィルタを分ける
    column.type === 'rowKpi'
      ? undefined
      : {
          text: t(`フィルタ設定`),
          icon: <FilterOutlined />,
          onClick: () => {
            setDimensionFilterDropdownVisible(true)
            setDimensionSorterDropdownVisible(false)
            setAddRowDimensionDropdownVisible(false)
          },
        },
    // TODO: ここから削除できるようにする
    // column.isFiltered === true
    //   ? {
    //       text: `フィルタの削除`,
    //       icon: <ClearOutlined />,
    //       onClick: () => {
    //         // filterMutations.removeFilterByField({ field })
    //         hide()
    //       },
    //     }
    //   : undefined,
    column.type === 'rowKpi'
      ? undefined
      : {
          text: t(`手動で並び替え`),
          icon: <SortAscendingOutlined />,
          onClick: () => {
            setDimensionSorterDropdownVisible(true)
            setDimensionFilterDropdownVisible(false)
            setAddRowDimensionDropdownVisible(false)
          },
        },
    column.type === 'rowKpi'
      ? {
          text: t(`KPIグループ設定`),
          icon: <SettingOutlined />,
          onClick: () => {
            kpiPivotKpisModal.showModal()
          },
        }
      : undefined,
    {
      text: t(`分析軸を追加`),
      icon: <PlusOutlined />,
      onClick: () => {
        setAddRowDimensionDropdownVisible(true)
        setDimensionFilterDropdownVisible(false)
        setDimensionSorterDropdownVisible(false)
      },
    },
    {
      text: t(`分析軸を削除`),
      icon: <DeleteOutlined />,
      className: `text-red-500`,
      onClick: () => {
        setKpiPivotParameter({
          ...kpiPivotParameter,
          pivot: {
            ...kpiPivotParameter.pivot,
            rows: [
              ...kpiPivotParameter.pivot.rows.slice(0, rowIndex),
              ...kpiPivotParameter.pivot.rows.slice(rowIndex + 1),
            ],
          },
        })
        // setHasChangeToViewSchema(true) // configの中で現在の軸設定は保存していないので、ここでhasChangeを呼ぶ必要はない。
        hideContextMenu()
      },
    },
  ].compact()
}

import { useMutation } from '@apollo/client'
import { isSome, isTruthy } from '@salescore/buff-common'
import { CreatePolicyUserRoleDocument, ReplaceCustomRolePoliciesDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath, Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { SuspenseWithLoading } from '@salescore/client-common'
import type { PolicyAction } from '@salescore/features'
import type { useModal } from '@salescore/frontend-common'
import { message, Modal } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { PolicyForm, PolicyFormSchema, type PolicyFormValue } from './PolicyForm'

export const PolicyCreateModal = ({ modal }: { modal: ReturnType<typeof useModal> }) => {
  return (
    <Modal
      open={modal.isModalVisible}
      onCancel={modal.hideModal}
      width={'60%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      destroyOnClose
    >
      <SuspenseWithLoading>
        <Body
          onAfterFinish={() => {
            modal.hideModal()
          }}
        />
      </SuspenseWithLoading>
    </Modal>
  )
}

function Body({ onAfterFinish }: { onAfterFinish: () => void }) {
  const [loading, setLoading] = useState(false)
  const [createPolicyUserRoleMutation] = useMutation(CreatePolicyUserRoleDocument)
  const [replaceCustomRolePoliciesMutation] = useMutation(ReplaceCustomRolePoliciesDocument)

  const onFinish = async (value: PolicyFormValue) => {
    const validated = PolicyFormSchema.safeParse(value)
    if (!validated.success) {
      void message.warning(t(`入力に誤りがあります`))
      return
    }

    setLoading(true)
    try {
      const result = await createPolicyUserRoleMutation({
        variables: {
          organizationId: getOrganizationIdFromPath(),
          policyUserRole: {
            label: validated.data.label,
            description: validated.data.description ?? '',
          },
        },
        refetchQueries: ['fetchPolicyUserRoles'],
      })

      const actionsWithPermission = Object.entries(validated.data.actionsWithPermission).map(([action, permission]) => {
        return {
          action: action as PolicyAction,
          permission: isTruthy(permission),
        }
      })

      if (isSome(result.data) && result.data.createPolicyUserRole.name) {
        const customRolePolicies = await replaceCustomRolePoliciesMutation({
          variables: {
            organizationId: getOrganizationIdFromPath(),
            customRolePolicies: {
              userRole: result.data.createPolicyUserRole.name,
              actionsWithPermission,
            },
          },
          refetchQueries: ['fetchPolicies'],
        })
        Posthog.track(POSTHOG_EVENTS.save_custom_role, {
          organizationId: getOrganizationIdFromPath(),
          config: customRolePolicies.data?.replaceCustomRolePolicies,
        })
      } else {
        throw new Error(`権限セットを作成できませんでした`)
      }
      void message.success(t(`権限セットを作成しました`))
    } catch {
      void message.error(t(`エラーが発生しました`))
    } finally {
      onAfterFinish()
      setLoading(false)
    }
  }

  return <PolicyForm onFinish={onFinish} loading={loading} />
}

import { isPresent } from '@salescore/buff-common'
import type { ViewQueryFilter, ViewQueryFilterNode, ViewQueryList } from '@salescore/core'
import Mustache from 'mustache'
import { selector, useRecoilValue, useSetRecoilState } from 'recoil'

import { listQueryAtom } from '../../view/atoms'
import { searchQueryAtom } from '../atoms'

export const searchQuerySelector = selector({
  key: `records/searchQuerySelector`,
  get({ get }) {
    const query = get(listQueryAtom)
    const searchableFields = query.fields.filter((field) => field.meta.searchable === true)
    const searchQuery = get(searchQueryAtom)

    return {
      searchQuery,
      searchableFields,
      searchQueryFilterTree: getSearchQueryFilterTree(query, searchQuery ?? ''),
    }
  },
})

export const useSearchQuerySelector = () => {
  return useRecoilValue(searchQuerySelector)
}

export const useSetSearchQuery = () => {
  return useSetRecoilState(searchQueryAtom)
}

function getSearchQueryFilterTree(query: ViewQueryList, searchQuery: string): ViewQueryFilterNode | undefined {
  if (searchQuery.length === 0) {
    return undefined
  }

  const queries = searchQuery.split(/\s/)
  const queryTemplate = query.extra?.searchQuery // `"salesforce_account"."name" = '{{ query }}'` の形を期待

  if (isPresent(queryTemplate)) {
    return {
      logicalOperator: 'and' as const,
      leafs: queries.map((query): ViewQueryFilter => {
        return {
          nodePaths: [], // TODO,
          read: {
            sql: Mustache.render(queryTemplate, { query }),
          },
        }
      }),
      children: [],
    }
  }

  return getSearchQueryFilterTreeByFields(query, searchQuery)
}

function getSearchQueryFilterTreeByFields(query: ViewQueryList, searchQuery: string): ViewQueryFilterNode | undefined {
  const searchableFields = query.fields.filter((field) => field.meta.searchable === true)
  if (searchableFields.isBlank()) {
    return undefined
  }
  const queries = searchQuery.split(/\s/)
  const filterNodesForQuery = queries.map((query): ViewQueryFilterNode => {
    const filters = searchableFields.map((field): ViewQueryFilter => {
      // TODO: queryのvalidation
      return {
        nodePaths: [field.nodePath],
        read: {
          sql: `${field.read.labelSql ?? field.read.sql} LIKE '%${query}%'`,
        },
      }
    })

    // いずれか1つのfieldに、クエリが含まれる
    return {
      logicalOperator: 'or',
      leafs: filters,
      children: [],
    }
  })

  // 全ての検索語句が含まれる
  return {
    logicalOperator: 'and',
    leafs: [],
    children: filterNodesForQuery,
  }
}

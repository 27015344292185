import { isNull, normalizeNumber, parseJsonIfValid, r } from '@salescore/buff-common'
import type { EltChange, EltChangeResult, EltChangeResultsChunk, EltChangesChunk } from '@salescore/core'
import { z } from 'zod'

import type { User } from '../user/types'
import type { UserGroup } from '../user_group/types'
import { GOAL_DIMENSION_EMPTY_VALUE } from './constant'
import { pivotedGoalModelWithoutProperties } from './generatePivotedGoalModel'
import { getUserOrUserGroupIds } from './getUserOrUserGroupIds'
import type { GoalConfig } from './types'

export interface GoalChange {
  key: {
    goal_config_id: string
    date: Date
    user_id: string
    dimension1: string
    dimension2: string
    dimension3: string
    dimension4: string
    dimension5: string
    kpi_id: string
  }
  value: number
  deleted?: boolean
}

export async function writeGoalChangesChunk({
  changesChunk,
  goalConfigs,
  users,
  userGroups,
  write,
}: {
  changesChunk: EltChangesChunk
  goalConfigs: GoalConfig[]
  users: User[]
  userGroups: UserGroup[]
  write: (xs: GoalChange[]) => Promise<void>
}): Promise<EltChangeResultsChunk> {
  if (changesChunk.modelName !== pivotedGoalModelWithoutProperties.name) {
    throw new Error(`modelName must bet pivoted goal model`)
  }

  const goalChanges = changesChunk.changes.map((change) =>
    convertChangeToGoalChange({ change, goalConfigs, users, userGroups }),
  )
  const success = goalChanges.map((x) => (x.success ? x : undefined)).compact()
  const errors = goalChanges.map((x) => (x.success ? undefined : x)).compact()
  const errorResults = errors.map((error): EltChangeResult => {
    return {
      status: 'error',
      change: error.change,
      error: error.message,
    }
  })
  try {
    // writeでエラーは起きないはず。万が一発生するようなときは、やや雑だがまとめてエラーを返す
    await write(success.flatMap((x) => x.records))
    return {
      modelName: changesChunk.modelName,
      results: [
        ...success.map(
          (x): EltChangeResult => ({
            status: 'success',
            change: x.change,
            result: {
              id: x.change.id,
            },
          }),
        ),
        ...errorResults,
      ],
    }
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : `書き込みエラーが発生しました`
    return {
      modelName: changesChunk.modelName,
      results: [
        ...success.map(
          (x): EltChangeResult => ({
            status: 'unknown',
            error: errorMessage,
            change: x.change,
          }),
        ),
        ...errorResults,
      ],
    }
  }
}

function convertChangeToGoalChange({
  change,
  goalConfigs,
  users,
  userGroups,
}: {
  change: EltChange
  goalConfigs: GoalConfig[]
  users: User[]
  userGroups: UserGroup[]
}) {
  if (change.type !== 'update') {
    return {
      success: false as const,
      message: `目標レコードの作成・削除はできません`, // NOTE: 削除はできてもいいかも。いったんUI側で削除は禁止している。
      change,
    }
  }

  const dimensionsRaw = parseJsonIfValid(change.id) // idがJSON値になっている
  const dimensions = z
    .tuple([z.string(), z.string(), z.string(), z.string(), z.string(), z.string(), z.string(), z.string()])
    .safeParse(dimensionsRaw)
  if (!dimensions.success) {
    return {
      success: false as const,
      message: `dimensionsが不正です。${JSON.stringify(dimensionsRaw)}`, // 実装が正しければありえない
      change,
    }
  }
  const kpis = z.record(z.string(), z.unknown()).safeParse(change.data)
  if (!kpis.success) {
    return {
      success: false as const,
      message: 'kpisが不正です', // 実装が正しければありえない
      change,
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [goal_config_id, dateString, user_id, dimension1, dimension2, dimension3, dimension4, dimension5] =
    dimensions.data
  // 以下、実装が正しければありえないが、一応チェックする
  const goalConfig = goalConfigs.find((x) => x.id === goal_config_id)
  if (goalConfig === undefined) {
    return {
      success: false as const,
      message: '目標設定が見つかりません',
      change,
    }
  }
  // userTypeが指定されており、含まれないユーザーであり、かつ削除系じゃないとき（値がnullのときは削除であり、これは常に許す）
  const isDeleteChange = r(kpis.data)
    .values()
    .every((x) => x === null)
  const userOrUserGroupIds = getUserOrUserGroupIds({ goalConfig, users, userGroups })
  if (goalConfig.userType !== 'none' && !userOrUserGroupIds.includes(user_id) && !isDeleteChange) {
    return {
      success: false as const,
      message: `目標設定に含まれないユーザーです。${goalConfig.userType}, ${user_id}`,
      change,
    }
  }
  // dateStringはYYYY-MM-DDフォーマットで、これをnew Dateするとタイムゾーンなしの時刻となる
  // validateDate側がタイムゾーンを考慮しており、この辺の整合性がうまく撮れてないのでいったんvalidationしない
  const date = new Date(dateString)
  // const validatedDate = validateDateByGoalConfig(date, goalConfig)
  // if (validatedDate === undefined) {
  //   return {
  //     success: false as const,
  //     message: `日付設定が不正です。${dateString}`,
  //     change,
  //   }
  // }
  if (!isValidDimension(dimension1, goalConfig.goalDimension1)) {
    return {
      success: false as const,
      message: `目標軸1が不正です。${dimension1}`,
      change,
    }
  }
  if (!isValidDimension(dimension2, goalConfig.goalDimension2)) {
    return {
      success: false as const,
      message: `目標軸2が不正です。${dimension2}`,
      change,
    }
  }
  if (!isValidDimension(dimension3, goalConfig.goalDimension3)) {
    return {
      success: false as const,
      message: `目標軸3が不正です。${dimension3}`,
      change,
    }
  }
  if (!isValidDimension(dimension4, goalConfig.goalDimension4)) {
    return {
      success: false as const,
      message: `目標軸4が不正です。${dimension4}`,
      change,
    }
  }
  if (!isValidDimension(dimension5, goalConfig.goalDimension5)) {
    return {
      success: false as const,
      message: `目標軸5が不正です。${dimension5}`,
      change,
    }
  }
  return {
    success: true as const,
    change,
    records: r(kpis.data)
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .map((kpi_id, rawValue): GoalChange | undefined => {
        if (kpi_id === '') {
          // ユーザーからの入力ではありえないはずだが一応チェック。kpiに関しては、存在しないIDを設定しても、使われないままレコードが残り続けるだけである
          return undefined
        }
        const value = normalizeNumber(rawValue) // 基本的には数値が入ってくるはず。RSheetを使っているかぎり、カンマなどもありえないはず
        return {
          key: {
            goal_config_id,
            date,
            user_id,
            dimension1,
            dimension2,
            dimension3,
            dimension4,
            dimension5,
            kpi_id,
          },
          value: value ?? 0, // valueがnullだったらdeleteするが、型を簡単にするため0をいれてしまっている。
          deleted: isNull(value),
        }
      })
      .compact(),
  }
}

function isValidDimension(dimension: string, goalDimension: GoalConfig['goalDimension1']) {
  if (isNull(goalDimension)) {
    return dimension === GOAL_DIMENSION_EMPTY_VALUE
  }
  // 2023/02/02 EMPTYは許可したくないが、移行スクリプトのミスで一部空文字が含まれてしまっており、一旦許可することにする
  return (
    goalDimension.selectOptionsV3.map((x) => x.value).includes(dimension) || dimension === GOAL_DIMENSION_EMPTY_VALUE
  )
}

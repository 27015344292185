import { isTruthy } from '@salescore/buff-common'
import dayjs from 'dayjs'

import { CORE_CONSTANT } from '../../constant'
import { generateFieldName } from '../../functions/view/compileViewConfig/sheet/compileSheetViewConfigFields'
import type {
  ArrayNode,
  ExpressionNode,
  FunctionNode,
  LiteralNode,
  Operator,
  VariableNode,
} from '../syntax/expression_ast'
import type { CoreDslFormLeafPartial, CoreDslFormNodeStatePartial, CoreDslFormSnapshotVariable } from './schema'

//
// CORE言語のUIフォーム用ASTを、実際のASTに変換する
//
export function convertFormToAst(formNode: CoreDslFormNodeStatePartial): ExpressionNode | undefined {
  const nodes = [
    ...formNode.leafs.map((leaf) => convertLeaf(leaf)),
    ...formNode.children.map((child) => convertFormToAst(child)), // TODO: and, orが混在するときの括弧が適切にできていないかも？
  ].compact()
  return recursiveLogicalOperator(formNode.logicalOperator === 'and' ? '&&' : '||', nodes)
}

function recursiveLogicalOperator(operator: '&&' | '||', nodes: ExpressionNode[]): ExpressionNode | undefined {
  if (nodes.length === 0) {
    return undefined
  }
  const right = recursiveLogicalOperator(operator, nodes.slice(1))
  if (right === undefined) {
    return nodes.first()
  }

  return {
    type: 'operator',
    operator,
    left: nodes.first()!,
    right,
  }
}

function convertLeaf(leaf: CoreDslFormLeafPartial): ExpressionNode | undefined {
  const left = convertTerm(leaf.left)
  if (left === undefined) {
    return undefined
  }
  //
  // rightが不要なoperatorの処理
  //
  if (leaf.operator === 'null' || leaf.operator === 'not_null') {
    return {
      type: 'operator',
      operator: leaf.operator === 'null' ? '=' : '!=',
      left,
      right: {
        type: 'literal',
        literalType: 'null',
        value: null,
      },
    }
  }
  if (leaf.operator === 'present' || leaf.operator === 'blank') {
    return {
      type: 'func',
      name: leaf.operator,
      args: [left],
    }
  }

  const right = convertRightTerm(leaf.operator, leaf.right)
  if (right === undefined) {
    return undefined
  }

  //
  // 特殊なoperatorの処理（AST側では関数になるもの）
  //
  if (leaf.operator === 'in' || leaf.operator === 'not_in') {
    const inExp: ExpressionNode = {
      type: 'func',
      name: 'in',
      args: [left, right],
    }
    return leaf.operator === 'in'
      ? inExp
      : {
          type: 'func',
          name: 'not',
          args: [inExp],
        }
  }
  if (leaf.operator === 'include' || leaf.operator === 'not_include') {
    const includeExp: ExpressionNode = {
      type: 'func',
      name: 'include',
      args: [left, right, generateLiteralNode(isTruthy(leaf.extra?.shouldConsiderCase))],
    }
    return leaf.operator === 'include'
      ? includeExp
      : {
          type: 'func',
          name: 'not',
          args: [includeExp],
        }
  }
  if (leaf.operator === 'starts_with' || leaf.operator === 'not_starts_with') {
    const startsWithExp: ExpressionNode = {
      type: 'func',
      name: 'startsWith',
      args: [left, right, generateLiteralNode(isTruthy(leaf.extra?.shouldConsiderCase))],
    }
    return leaf.operator === 'starts_with'
      ? startsWithExp
      : {
          type: 'func',
          name: 'not',
          args: [startsWithExp],
        }
  }
  if (leaf.operator === 'overlap' || leaf.operator === 'not_overlap') {
    const overlapExp: ExpressionNode = {
      type: 'func',
      name: 'overlap',
      args: [left, right],
    }
    return leaf.operator === 'overlap'
      ? overlapExp
      : {
          type: 'func',
          name: 'not',
          args: [overlapExp],
        }
  }

  //
  // 特殊な処理
  // 文字列型が選択肢で、選択肢の比較を行うときは、内部的にはrank関数を付与した上で比較させる
  //
  const leftProerpty = leaf.left?.type === 'snapshotVariable' ? leaf.left.property : undefined
  const leftOptions = leftProerpty?.selectOptions ?? []
  if (leftOptions.isPresent() && ['>', '<', '>=', '<='].includes(leaf.operator ?? '')) {
    return {
      type: 'operator',
      operator: leaf.operator as '<',
      left: {
        type: 'func',
        name: 'rank',
        args: [left, generateArrayNode(leaf.extra?.sortedValues ?? [])],
      },
      right: {
        type: 'func',
        name: 'rank',
        args: [right, generateArrayNode(leaf.extra?.sortedValues ?? [])],
      },
    }
  }

  //
  // 特殊な処理
  // 文字列型の比較の場合、大文字小文字を無視するかどうかを考慮するので、equal関数を使う
  //
  if (leftProerpty?.type === 'string' && (leaf.operator === '=' || leaf.operator === '!=')) {
    const equalExp: ExpressionNode = {
      type: 'func',
      name: 'equal',
      args: [left, right, generateLiteralNode(isTruthy(leaf.extra?.shouldConsiderCase))],
    }
    return leaf.operator === '=' ? equalExp : { type: 'func', name: 'not', args: [equalExp] }
  }

  //
  // 通常のoperatorの処理
  //
  const operator = convertOperator(leaf.operator)
  if (operator === undefined) {
    return undefined
  }

  //
  // 特殊な処理
  // 右辺のリテラルが日付型でdateTimeTypeがmonth,yearのときは、その関数を左辺と右辺に適用する必要がある
  //
  const rightLiteral = leaf.right?.type === 'literal' ? leaf.right : undefined
  if (
    rightLiteral?.literalType === 'date' &&
    (rightLiteral.dateTimeType === 'week' ||
      rightLiteral.dateTimeType === 'month' ||
      rightLiteral.dateTimeType === 'year')
  ) {
    return {
      type: 'operator',
      operator,
      left: {
        type: 'func',
        name: rightLiteral.dateTimeType,
        args: [left],
      },
      right: {
        type: 'func',
        name: rightLiteral.dateTimeType,
        args: [right],
      },
    }
  }
  // 同上。日付型であり、dateTimeTypeがrelativeのときは、relativeに対応する関数を適用する必要がある
  // （例）「左辺が先月に等しい」という条件のとき、そのままだと x = dateAdd(today(), -1, 'month') のような値になり、
  //      すなわち x = '2023-08-01' のような値になるが、これだと2023-08-02が一致しなくなってしまう。
  //      これを month(x) = month('2023-08-01')にすることで、月で一致させる
  if (rightLiteral?.literalType === 'date' && rightLiteral.dateTimeType === 'relative') {
    const maybeDateTimeType = rightLiteral.value.split(' ')[1]
    if (maybeDateTimeType === 'week' || maybeDateTimeType === 'month' || maybeDateTimeType === 'year') {
      return {
        type: 'operator',
        operator,
        left: {
          type: 'func',
          name: maybeDateTimeType,
          args: [left],
        },
        right: {
          type: 'func',
          name: maybeDateTimeType,
          args: [right],
        },
      }
    }
  }

  return {
    type: 'operator',
    operator,
    left,
    right,
  }
}

function generateArrayNode(xs: unknown[]): ArrayNode {
  return {
    type: 'array',
    elements: xs.map((x) => generateLiteralNode(x)),
  }
}

function generateLiteralNode(x: unknown): LiteralNode {
  const type = typeof x
  switch (type) {
    case 'string': {
      return {
        type: 'literal',
        literalType: 'string',
        value: x as string,
      }
    }
    case 'number': {
      return {
        type: 'literal',
        literalType: 'number',
        value: x as number,
      }
    }
    case 'boolean': {
      return {
        type: 'literal',
        literalType: 'boolean',
        value: x as boolean,
      }
    }
  }
  // TODO
  return {
    type: 'literal',
    literalType: 'string',
    value: x as string,
  }
}

function convertOperator(
  operator: Exclude<
    CoreDslFormLeafPartial['operator'],
    | 'in'
    | 'not_in'
    | 'not_null'
    | 'null'
    | 'blank'
    | 'present'
    | 'include'
    | 'not_include'
    | 'starts_with'
    | 'not_starts_with'
    | 'overlap'
    | 'not_overlap'
  >,
): Operator | undefined {
  if (operator === undefined) {
    return undefined
  }
  return operator
}

function convertTerm(
  term: CoreDslFormLeafPartial['left'],
): LiteralNode | ArrayNode | VariableNode | FunctionNode | undefined {
  if (term === undefined) {
    return undefined
  }
  const type = term.type
  switch (type) {
    case 'literal': {
      const literalType = term.literalType
      switch (literalType) {
        case 'string': {
          return {
            type: 'literal',
            literalType: 'string',
            value: term.value,
          }
        }
        case 'number': {
          return {
            type: 'literal',
            literalType: 'number',
            value: term.value,
          }
        }
        case 'boolean': {
          return {
            type: 'literal',
            literalType: 'boolean',
            value: term.value,
          }
        }
        case 'date': {
          if (term.dateTimeType === 'relative') {
            const [maybeRelativeDay, maybeDateType] = term.value.split(' ') as [string, string]
            return {
              type: 'func',
              name: 'dateAdd',
              args: [
                {
                  type: 'func',
                  name: 'today',
                  args: [],
                },
                {
                  type: 'literal',
                  literalType: 'number',
                  value: Number.parseInt(maybeRelativeDay),
                },
                {
                  type: 'literal',
                  literalType: 'string',
                  value: maybeDateType,
                },
              ],
            }
          }
          return {
            type: 'literal',
            literalType: 'date',
            value: dayjs(term.value),
          }
        }
        case 'array': {
          return {
            type: 'array',
            elements: term.value.map((value) => ({
              type: 'literal',
              literalType: 'string',
              value,
            })),
          }
        }
        default: {
          const x: never = literalType
          return undefined
        }
      }
    }
    case 'snapshotVariable': {
      const day = getDaysFromSnapshotConfigBefore(term)
      const variable: VariableNode = {
        type: 'variable',
        path:
          day === 0
            ? [generateFieldName(term.nodePropertyName)]
            : [
                CORE_CONSTANT.SNAPSHOT_VARIABLE_NAME,
                `${CORE_CONSTANT.SNAPSHOT_VARIABLE_DAY_PREFIX}${day}`,
                term.property.name,
              ],
      }
      if (term.property.type === 'date' || term.property.type === 'datetime') {
        return {
          type: 'func',
          name: 'date',
          args: [variable],
        }
      }
      return variable
    }
    case 'recordNodeVariable': {
      const variable: VariableNode = {
        type: 'variable',
        path: [generateFieldName(term.nodePropertyName)],
      }
      if (term.property.type === 'date' || term.property.type === 'datetime') {
        return {
          type: 'func',
          name: 'date',
          args: [variable],
        }
      }
      return variable
    }
    default: {
      const x: never = type
      return undefined
    }
  }
}

function convertRightTerm(
  operator: CoreDslFormLeafPartial['operator'],
  term: CoreDslFormLeafPartial['right'],
): LiteralNode | ArrayNode | VariableNode | FunctionNode | undefined {
  if (operator === undefined) {
    return undefined
  }
  switch (operator) {
    case 'not_null': {
      return {
        type: 'literal',
        literalType: 'null',
        value: null,
      }
    }
  }
  return convertTerm(term)
}

function getDaysFromSnapshotConfigBefore(x: CoreDslFormSnapshotVariable) {
  const dateSpan = x.dateSpan
  switch (dateSpan) {
    case 'day': {
      return x.before
    }
    case 'week': {
      return x.before * 7
    }
    case 'month': {
      // 1月の数は不定なので「三ヶ月前」が何日分なのかは現在の日時をベースに求める必要がある
      return dayjs().diff(dayjs().subtract(x.before, 'month'), 'day')
    }
    case 'year': {
      return dayjs().diff(dayjs().subtract(x.before, 'year'), 'day')
    }
    default: {
      const x: never = dateSpan
      return 0
    }
  }
}

import type { OrganizationFieldsFragment, V2Organization } from '@salescore/client-api'
import { CardContainer, OrganizationAvatar, signInAs } from '@salescore/client-common'
import { useStateWithLocalStorage } from '@salescore/frontend-common'
import { Avatar, Button, List, Space, Table } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { z } from 'zod'

const SelectUserSmall = ({
  myIdentity,
}: {
  myIdentity: {
    users: Array<{
      name: string
      organization: Pick<V2Organization, 'id' | 'name' | 'createdAt'>
    }>
  }
}): JSX.Element => {
  return (
    <CardContainer size="medium">
      <>
        <div className="mb-4">{t(`ログインする組織を選択してください。`)}</div>
        <Table
          rowKey="id"
          showHeader={false}
          pagination={false}
          columns={[
            {
              render(_text, record) {
                return (
                  <Space size="middle">
                    <OrganizationAvatar organization={record.organization} />
                    <div>
                      {record.organization.name}
                      <br />
                      <span className="text-xs text-gray-500">{record.name}</span>
                    </div>
                  </Space>
                )
              },
            },
            {
              render(_text, record) {
                return (
                  <div className="w-full text-center">
                    <Button
                      type="primary"
                      data-e2e={`org-button-id-${record.organization.id}`}
                      onClick={() => {
                        signInAs(record.organization)
                      }}
                    >
                      {t(`ログイン`)}
                    </Button>
                  </div>
                )
              },
            },
          ]}
          dataSource={myIdentity.users.mySortBy((x) => x.organization.createdAt).reverse()}
        />
      </>
    </CardContainer>
  )
}

export const OrganizationBlock = ({
  groupName,
  organizations,
  onClick,
}: {
  groupName: string
  organizations: Array<Pick<V2Organization, 'id' | 'name' | 'imageUrl'>>
  onClick: (x: Pick<OrganizationFieldsFragment, 'id'>) => void
}): JSX.Element => {
  return (
    <div className="mb-6">
      <div className="mb-2 text-gray-400">{groupName}</div>
      <List
        grid={{
          gutter: 1,
          column: 3,
          xs: 1,
          sm: 2,
          md: 3,
          // lg: 4,
          // xl: 6,
          // xxl: 8,
        }}
        dataSource={organizations}
        renderItem={(organization) => (
          <Space
            className="mb-6 cursor-pointer hover:opacity-80"
            onClick={() => {
              onClick(organization)
            }}
          >
            <Avatar src={organization.imageUrl} shape="square" />
            <div
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 200,
              }}
            >
              {organization.name}
            </div>
          </Space>
        )}
      />
    </div>
  )
}

const BUFF_IDS = new Set(['4', '19', '20'])
const SelectUserLarge = ({
  myIdentity,
}: {
  myIdentity: {
    users: Array<{
      organization: Pick<V2Organization, 'id' | 'name' | 'createdAt' | 'imageUrl'>
    }>
  }
}): JSX.Element => {
  const [recentlySignInIds, setRecentlySignInIds] = useStateWithLocalStorage<string[]>(
    `/SelectUserLarge/RecentlyUsedIds`,
    [],
    z.string().array(),
  )
  const organizations = myIdentity.users.map((user) => user.organization)
  const organizationGroupByYearMonth = organizations
    .groupBy((x) => dayjs(x.createdAt).format(`YYYY-MM`))
    .toArray()
    .sortBy((x) => x[0])
  const buffOrganizations = organizations.filter((x) => BUFF_IDS.has(x.id))
  const recentlySignInOrganizations = organizations
    .filter((x) => recentlySignInIds.includes(x.id))
    .mySortBy((x) => recentlySignInIds.indexOf(x.id))

  const onClick = (organization: Pick<OrganizationFieldsFragment, 'id'>): void => {
    signInAs(organization)
    setRecentlySignInIds([organization.id, ...recentlySignInIds].unique().slice(0, 9))
  }

  return (
    <div className="flex items-center justify-center align-middle">
      <div className="m-8 h-[calc(100vh-50px)] w-[900px] overflow-y-auto rounded-md bg-white p-8">
        {buffOrganizations.length > 0 && (
          <OrganizationBlock onClick={onClick} organizations={buffOrganizations} groupName={t(`Buff環境`)} />
        )}
        {recentlySignInOrganizations.length > 0 && (
          <OrganizationBlock
            onClick={onClick}
            organizations={recentlySignInOrganizations}
            groupName={t(`直近ログイン`)}
          />
        )}
        {organizationGroupByYearMonth.map(([yearmonth, organizations], index) => {
          return (
            <OrganizationBlock
              key={index}
              onClick={onClick}
              organizations={organizations.filter((x) => !BUFF_IDS.has(x.id))}
              groupName={yearmonth === `2021-04` ? t(`2021-04以前`) : yearmonth}
            />
          )
        })}
      </div>
    </div>
  )
}

export const SelectUser = ({
  myIdentity,
}: {
  myIdentity: {
    users: Array<{
      name: string
      organization: Pick<V2Organization, 'id' | 'name' | 'createdAt' | 'imageUrl'>
    }>
  }
}): JSX.Element => {
  if (myIdentity.users.length < 5) {
    return <SelectUserSmall myIdentity={myIdentity} />
  }

  return <SelectUserLarge myIdentity={myIdentity} />
}

import { PageHeader } from '@ant-design/pro-layout'
import { isNull, isSome, isTruthy, r } from '@salescore/buff-common'
import type { ViewFieldsFragment } from '@salescore/client-api'
import {
  type AdditionalFilterLeaf,
  CORE_CONSTANT,
  generateAggregationDayFilterLeaf,
  generateFieldName,
  generateKpiTimeSeriesKpiFragment,
  type GoalConfig,
  type ModelSearcher,
  type NodePropertyName,
  type SheetConfigOrReference,
  type ViewConfig,
  type ViewConfigCustomFilter,
  type ViewConfigDimension,
  type ViewConfigDimensionFilter,
  type ViewConfigFilter,
  type ViewConfigKpi,
  type ViewConfigKpiParameter,
  type ViewConfigKpiTimeSeries,
  type ViewConfigPivot,
  type ViewConfigSheet,
} from '@salescore/core'
import { Divider, Empty, Space, Tag } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import type { DrillDownParameterForKpiPivot } from '../recoil/navigation/atoms'
import { useKpiPivotParameter, useNavigationModal } from '../recoil/navigation/hooks'
import { additionalConfigAtom } from '../recoil/records/atoms'
import { goalConfigAtom, sharedUrlAtom } from '../recoil/view/atoms'
import { useMeValue, useViewsContextValue, useViewValue } from '../recoil/view/hooks'
import { useConnectionsSelector } from '../recoil/view/selectors/connectionsSelector'
import { useKpiFormSelectors } from '../recoil/view/selectors/kpiFormSelector'
import { modelSearcherSelector } from '../recoil/view/selectors/modelSearcherSelector'
import { useViewsRelated } from '../recoil/view/selectors/viewsRelatedSelector'
import { DurationTimerStart } from './misc/DurationTimer'
import { dayOfWeekOptions } from './view_ui/ViewUIRi/Form/AggregationDayFormInput'
import { ViewComponent, type ViewsContext } from './ViewComponent'
import { getDimensionsWithValue } from './ViewDrillDownModal'

interface AdditionalFilterLeafWithOverride extends AdditionalFilterLeaf {
  override?: { label?: string }
}

// TODO: 煩雑になってきたので、ユースケース毎にコンポーネントを分離させてもいいかも
export function ViewDrillDownModalContentForKpi({
  kpiView,
  parameter,
  onFinish,
  viewsContextOverride,
}: {
  kpiView: ViewFieldsFragment & { config: ViewConfigKpi }
  parameter: DrillDownParameterForKpiPivot
  onFinish?: () => Promise<void>
  viewsContextOverride?: Partial<ViewsContext>
}) {
  const { organization } = useMeValue()
  const modelSearcher = useRecoilValue(modelSearcherSelector)
  const { drillDownModal } = useNavigationModal()
  const { form } = useKpiFormSelectors()
  const currentView = useViewValue()
  const additionalConfig = useRecoilValue(additionalConfigAtom)
  const goalConfig = useRecoilValue(goalConfigAtom)
  const viewsContext = useViewsContextValue()
  const [kpiPivotParameter] = useKpiPivotParameter()
  const { searchConfigIfReference } = useViewsRelated()
  const viewConfigPivot = getViewConfigPivot(currentView, parameter, kpiPivotParameter)
  const aggregationDayFilterLeaf = getAggregationFilterLeaf(currentView.config, modelSearcher)
  const waterfallPeriodFilterLeaf = generateWaterfallPeriodFilterLeaf(currentView.config, parameter, form.sheet)
  const dimensionsWithValue = getDimensionsWithValue(parameter, viewConfigPivot)
  const sharedUrl = useRecoilValue(sharedUrlAtom)

  const originalViewId = kpiView.id.replace(CORE_CONSTANT.KPI_PREVIEW_VIEW_ID_PREFIX, '')
  const drillDownViewId = kpiView.id.startsWith(CORE_CONSTANT.KPI_PREVIEW_VIEW_ID_PREFIX)
    ? `${CORE_CONSTANT.KPI_PREVIEW_DRILL_DOWN_VIEW_ID_PREFIX}${originalViewId}`
    : kpiView.id

  // KPIのモーダルなので、ここでsheetの情報を取得することは本来は不要だが、
  //
  const sheetViewConfig = searchConfigIfReference(kpiView.config.sheet)
  if (sheetViewConfig === undefined || sheetViewConfig.type !== 'sheet') {
    return <Empty description={<div>{t(`シートが見つかりません。`)}</div>} />
  }

  // additionalFilterTreesをここで求めようと思っていたが、compile側に移行した。表示で必要になったら要検討
  // const actualMeasure = kpiView.config.measures.find((x) => x.measureRole === 'actual')
  // const measure = (sheetViewConfig.fields ?? [])
  //   .flatMap((x) => x.measures ?? [])
  //   .find((x) => x.name === actualMeasure?.measureWithDimension.measureName)
  // const additionalFilterTrees: ViewConfigAdditionalConfig['filterTrees'] =
  //   measure?.type === 'preset' && measure.filterTree !== undefined
  //     ? [
  //         {
  //           type: 'measure',
  //           tree: measure.filterTree,
  //         },
  //       ]
  //     : undefined
  const additionalFilterLeafs = dimensionsWithValue
    .map((x): ViewConfigDimensionFilter | undefined => {
      if (x.value?.value === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING) {
        return undefined
      }
      if (x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key) {
        return undefined
      }
      if (x.key === CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_DIMENSION().key) {
        return undefined
      }

      return {
        type: `dimension`,
        dimension: x.dimension,
        filterValue: x.value?.value === CORE_CONSTANT.KPI_PIVOT_NULL_STRING ? undefined : x.value?.value,
        filterType: x.value?.value === CORE_CONSTANT.KPI_PIVOT_NULL_STRING ? 'null' : 'equal',
        filterValueLabel: x.value?.value === CORE_CONSTANT.KPI_PIVOT_NULL_STRING ? `なし` : x.value?.label,
        filterDateTimeType: x.dimension.dateSpan,
        option: {
          isDrillDownFilter: true,
        },
      }
    })
    .compact()
  const additionalFilterLeafsWithPrevious: AdditionalFilterLeafWithOverride[] =
    parameter.additionalFilterLeafsWithPrev ?? [
      ...(additionalConfig.filterLeafs ?? []),
      ...additionalFilterLeafs.map((leaf) => ({ type: 'drillDown' as const, leaf })),
      ...(isSome(aggregationDayFilterLeaf) ? [{ type: 'drillDown' as const, leaf: aggregationDayFilterLeaf }] : []),
      ...(isSome(waterfallPeriodFilterLeaf)
        ? [{ type: 'drillDown' as const, leaf: waterfallPeriodFilterLeaf, override: { label: '横軸' } }]
        : []),
      // 軸フィルタも明示した方がいい気がするが、ダッシュボードによっては煩雑になりそう。一旦非表示
      // ...(kpiPivotParameter.dimensionFilterLeafs?.map(leaf => ({ leaf, type: 'dimension' as const })) ?? []),
    ]

  const kpiOrKpiFragment =
    currentView.config.type === 'kpiTimeSeries' && !isTruthy(currentView.config.option?.asWaterfall)
      ? generateKpiTimeSeriesKpiFragment(currentView.config, { additionalConfig })
      : kpiView.config

  return (
    <PageHeader
      title={
        <Space className="px-4 py-2">
          {kpiView.name}
          {/* TODO: treeをいい感じに表示する？そもそも表示する必要はあるだろうか？ */}
          {additionalFilterLeafsWithPrevious.map((leaf) => (
            <AdditionalFilterLeafTag leaf={leaf.leaf} config={sheetViewConfig} override={leaf.override} />
          ))}
          {/* periodNameの表示 */}
          {r(parameter.queryParameter ?? kpiPivotParameter.queryParameter ?? {})
            .values()
            .compact()
            .map((x) => {
              // カスタム(Period)では startAt/endAt が時間込みで表示されるため除外する
              const deleteRegexp = /\d{2}:\d{2}:\d{2}$/
              return x.replace(deleteRegexp, '')
            })
            .map((x) => (
              <Tag>{t(x) ?? x}</Tag>
            ))}
        </Space>
      }
      style={{
        padding: 0,
      }}
      className="w-full overflow-hidden"
    >
      <Divider style={{ margin: 0 }} />
      <div
        style={{
          height: 'calc(100vh - 150px)', // 画面一杯に表示するために、100vhからモーダル・タイトルをざっくり引いた値を指定
        }}
      >
        <ViewComponent
          view={{
            ...kpiView,
            config: kpiOrKpiFragment,
            id: kpiView.id.startsWith(CORE_CONSTANT.KPI_PREVIEW_VIEW_ID_PREFIX)
              ? `${CORE_CONSTANT.KPI_PREVIEW_DRILL_DOWN_VIEW_ID_PREFIX}${originalViewId}`
              : kpiView.id, // TODO
          }}
          parameter={parameter.queryParameter ?? kpiPivotParameter.queryParameter ?? {}}
          additionalConfig={{
            filterLeafs: additionalFilterLeafsWithPrevious.map((x) => ({ type: x.type, leaf: x.leaf })),
            previousDimmensions: kpiPivotParameter.pivot.rows,
            kpiParameter: {
              pivot: {
                rows: kpiPivotParameter.pivot.rows,
                columns: [], // TODO: 元々 rows も空にしていた。 columns も非空にできたらしたら良いのかもしれない。
              },
              dimensionFilterLeafs: kpiPivotParameter.dimensionFilterLeafs,
              queryParameter: parameter.queryParameter ?? kpiPivotParameter.queryParameter ?? {},
              goalConfig,
            },
          }}
          onFinish={onFinish}
          viewsContext={{
            ...viewsContext,
            ...viewsContextOverride,
            isDrillDown: true,
            prevView: currentView,
            kpiPivotPresetName: parameter.kpiPivotPresetName,
            onChangesChange: (hasChanges) => {
              viewsContext.onChangesChange(hasChanges)
              drillDownModal.setInnerState({ hasChanges })

              // 一度でも変更があればtrueにする
              if (hasChanges && isSome(viewsContext.setIsChangedDrillDownModal)) {
                viewsContext.setIsChangedDrillDownModal(true)
              }
            },
            isChangedDrillDownModal: viewsContext.isChangedDrillDownModal,
            setIsChangedDrillDownModal: viewsContext.setIsChangedDrillDownModal,
          }}
          sharedUrl={sharedUrl}
        />
      </div>
      <DurationTimerStart viewId={drillDownViewId} organizationId={organization.id} trigger="drillDownModalOpened" />
    </PageHeader>
  )
}

function AdditionalFilterLeafTag({
  leaf,
  config,
  override,
}: {
  leaf: ViewConfigFilter
  config: ViewConfigSheet
  override?: { label?: string }
}) {
  const { getModelProperty, getModel } = useConnectionsSelector()
  const { value, label } = useMemo(() => {
    const value = leaf.filterValueLabel ?? leaf.filterValue

    if (leaf.type === 'property') {
      if (leaf.property.propertyName === 'id') {
        const model = getModel(leaf.property.modelName)
        return {
          value,
          label: override?.label ?? model?.label ?? `id`,
        }
      }

      const property = getModelProperty(leaf.property.modelName, leaf.property.propertyName)
      return {
        label: override?.label ?? property?.label ?? value,
        value,
      }
    }

    if (leaf.type === 'dimension') {
      return {
        value,
        label: override?.label ?? leaf.dimension.label,
      }
    }

    if (leaf.type === 'custom') {
      const defaultLabel = t('カスタムフィルタ')
      return {
        label: override?.label ?? leaf.label ?? defaultLabel,
        value,
      }
    }

    const field = (config.fields ?? []).find((x) => generateFieldName(x.property) === leaf.fieldName)
    const property = getModelProperty(field?.property.modelName, field?.property.propertyName)
    return {
      label: override?.label ?? property?.label ?? value,
      value,
    }
  }, [leaf, config, override])

  return (
    <Tag>
      <>
        {label}: {value}
      </>
    </Tag>
  )
}

function generateKpiDateDimension(
  date: ViewConfigKpi['date'],
  timeSpan?: GoalConfig['timeSpanType'],
): ViewConfigDimension | undefined {
  if (date === undefined) {
    return undefined
  }
  return {
    type: 'property',
    property: date.property,
    fieldType: 'date',
    label: t('横軸'),
    key: `${date.property.modelName}.${date.property.propertyName}`,
    dateSpan: timeSpan === 'none' ? undefined : timeSpan,
  }
}

function getAggregationFilterLeaf(
  config: ViewConfig,
  modelSearcher: ModelSearcher,
): ViewConfigCustomFilter | undefined {
  if (config.type !== 'kpiTimeSeries' || isTruthy(config.option?.asWaterfall)) {
    return
  }

  const leaf = generateAggregationDayFilterLeaf({
    config,
    modelSearcher,
  })

  if (isNull(leaf)) {
    return
  }

  return {
    ...leaf,
    label: t('集計対象日'),
    filterValueLabel: getAggregationDayLabel({ config }),
  }
}

function getAggregationDayLabel({ config }: { config?: ViewConfigKpiTimeSeries }): string | undefined {
  const aggregationDay = config?.kpiFragment?.aggregationDay
  if (isNull(aggregationDay)) {
    return
  }

  switch (aggregationDay.timeSpan) {
    case 'month': {
      return t('毎月{{value}}日', { value: aggregationDay.filterValue })
    }
    case 'week': {
      const valueLabel = dayOfWeekOptions.find((v) => v.value === aggregationDay.filterValue)?.label
      return t('毎週{{value}}', { value: valueLabel })
    }
  }
}

function getViewConfigPivot(
  currentView: ViewFieldsFragment,
  parameter: DrillDownParameterForKpiPivot,
  kpiParameter: ViewConfigKpiParameter,
): ViewConfigPivot {
  if (currentView.config.type !== 'kpiTimeSeries') {
    return kpiParameter.pivot
  }

  if (isTruthy(currentView.config.option?.asWaterfall)) {
    // 滝グラフの期間1, 期間2のドリルダウンの絞り込みは別途で生成する
    if (
      parameter.dimensionValues.rows.first()?.value === CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX ||
      parameter.dimensionValues.rows.first()?.value === CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX
    ) {
      return kpiParameter.pivot
    }

    return {
      rows: [
        {
          type: 'property',
          property: {
            modelName: CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME,
            nodeName: CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME,
            propertyName: CORE_CONSTANT.WATERFALL_DIMENSION_FIELD_NAME,
          },
          label: t('項目'),
          fieldType: 'string',
          key: `${CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME}.${CORE_CONSTANT.WATERFALL_DIMENSION_FIELD_NAME}`,
        },
      ],
      columns: [],
    }
  }

  return {
    rows: [
      generateKpiDateDimension(
        currentView.config.kpiFragment?.date,
        currentView.config.goalConfigFragment?.timeSpanType,
      ),
      parameter.dimensionValues.rows.length === 2 ? currentView.config.breakdownProperty : undefined,
    ].compact(),
    columns: [],
  }
}

function generateWaterfallPeriodFilterLeaf(
  config: ViewConfig,
  parameter: DrillDownParameterForKpiPivot,
  sheet?: SheetConfigOrReference,
): ViewConfigFilter | undefined {
  const parameterValue = parameter.dimensionValues.rows.first()?.value
  if (
    parameterValue !== CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX &&
    parameterValue !== CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX
  ) {
    return
  }

  if (sheet?.type !== 'sheet') {
    return
  }

  const rootSnapshotNodeName = sheet.tree?.name
  if (isNull(rootSnapshotNodeName)) {
    return
  }

  const rootSnapshotSequenceNodeProperty: NodePropertyName = {
    modelName: CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME,
    nodeName: CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME,
    propertyName: `${rootSnapshotNodeName}_${CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX}_created_at_${parameterValue}`,
  }

  return {
    type: 'property',
    property: rootSnapshotSequenceNodeProperty,
    filterType: 'equal',
    filterValue: getDimensionRowValue(config, parameterValue),
    filterValueLabel: parameterValue === CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX ? t('期間1') : t('期間2'),
    filterDateTimeType: 'date',
  }
}

function getDimensionRowValue(config: ViewConfig, valueKey: string): string | undefined {
  if (config.type !== 'kpiTimeSeries') {
    return
  }

  const startAt = config.filters?.startPeriod?.startAt
  const endAt = config.filters?.endPeriod?.endAt

  if (valueKey === CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX) {
    return startAt
  }

  if (valueKey === CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX) {
    return endAt
  }

  return valueKey
}

import { DeleteOutlined } from '@ant-design/icons'
import { isNull, isSome, isTruthy } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import { ClosableResizableSiderWithPercent, DelayWrapper } from '@salescore/client-common'
import { ButtonWithTooltip } from '@salescore/frontend-common'
import { Alert, Button, Col, Form, Row, Space } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import { DurationTimerEnd } from '../../../components/misc/DurationTimer'
import { KpiFormChildNodeFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormChildNodeFormItem'
import { KpiFormDashboardIdsFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormDashboardIdsFormItem'
import { KpiFormDateFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormDateFormItem'
import { KpiFormDescriptionFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormDescriptionFormItem'
import { KpiFormDetailFormItems } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormDetailFormItems'
import { KpiFormExtraFilterTreeFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormExtraFilterTreeFormItem'
import { KpiFormFilterTreeFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormFilterTreeFormItem'
import { KpiFormIconFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormIconFormItem'
import { KpiFormMeasureFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormMeasureFormItem'
import { KpiFormNameFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormNameFormItem'
import { KpiFormPreview } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormPreview'
import { KpiFormRootNodeFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormRootNodeFormItem'
import { KpiFormSaveButton } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormSaveButton'
import { KpiFormSheetFieldsFormAddDimeisions } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormSheetFieldsFormAddDimensions'
import { KpiFormSheetFieldsFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormSheetFieldsFormItem'
import { KpiFormSteps } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormSteps'
import { KpiFormUserFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormUserFormItem'
import { KpiFormViewGroupIdFormItem } from '../../../components/view_ui/ViewUIKpiPivot/KpiForm/KpiFormViewGroupIdFormItem'
import { ViewLoading } from '../../../components/ViewLoading'
import { CLIENT_VIEW_CONSTANT } from '../../../constants/constants'
import { loadingProgressAtom } from '../../../recoil/view/atoms'
import { useMeValue, useViewAbilityValue, useViewsContextValue, useViewValue } from '../../../recoil/view/hooks'
import { generateInitialKpiForm } from '../../../recoil/view/mutations/initializeViewMutation'
import { useCachedViewsSelector } from '../../../recoil/view/selectors/cachedViewSelector'
import { useKpiFormEffects, useKpiFormSelectors } from '../../../recoil/view/selectors/kpiFormSelector'
import { VisualizeOnlySection } from './VisualizeOnlySection'

function useLatestSheetConfig(viewId: string) {
  const { views } = useViewsContextValue()
  const latestView = views.find((x) => x.id === viewId)
  const latestSheetConfig =
    latestView?.config.type === 'kpi' && latestView.config.sheet?.type === 'sheet' ? latestView.config.sheet : undefined

  return { latestSheetConfig }
}

export function KpiForm() {
  const { organization } = useMeValue()
  const view = useViewValue()
  const setLoadingProgress = useSetRecoilState(loadingProgressAtom)
  const { setSheetConfig } = useKpiFormSelectors()
  const { currentCachedView } = useCachedViewsSelector()
  const { latestSheetConfig } = useLatestSheetConfig(view.id)

  useEffect(() => {
    // KPIシートパネルで設定が変更されていた場合などに不整合が起きないよう、常に最新のKPIシートの設定をフォームに反映する
    if (isSome(latestSheetConfig) && !isTruthy(currentCachedView?.isModifiedKpiForm)) {
      setSheetConfig(latestSheetConfig)
    }
  }, [latestSheetConfig])

  useEffect(() => {
    setLoadingProgress((progress) => ({
      ...progress,
      completed: true,
    }))
  }, [])

  return (
    <DelayWrapper delay={CLIENT_VIEW_CONSTANT.RENDER_DELAY_AFTER_LOAD} fallback={<ViewLoading context={{ view }} />}>
      {/* ローディング画面で読み込みが完了したことを通知してからViewを表示するために、描画タイミングを読み込み完了からごく短時間遅らせている */}
      <Body />
    </DelayWrapper>
  )
}

function Body() {
  const view = useViewValue()
  const ability = useViewAbilityValue() // TODO: KPIフォームの各種のFormItemについてもdisableをするべきだが、実装がやや大変なので一旦保留
  useKpiFormEffects()

  return (
    <ClosableResizableSiderWithPercent
      siderKey="KpiForm"
      showMinimum
      widthPercent={40}
      position="right"
      sider={({ close }) => {
        return (
          <div className="flex size-full flex-col">
            <div
              className="flex items-center justify-between px-8 py-2 align-middle"
              style={{ borderBottom: `1px solid ${CONSTANT.colors.border}` }}
            >
              <div className="text-lg font-bold">{t(`KPIのプレビュー`)}</div>
              <Button type="text" onClick={close}>
                {t(`閉じる`)}
              </Button>
            </div>
            <div className="min-h-0 flex-1">
              <KpiFormPreview />
            </div>
          </div>
        )
      }}
      body={() => {
        return (
          <div className="h-full overflow-y-auto p-6">
            {!ability.canUpdate && (
              <Alert className="mb-4" showIcon type="warning" message={t(`権限がないため編集できません`)} />
            )}
            <KpiFormSteps />
            <Form
              initialValues={{
                name: view.name,
              }}
              // wrapperCol={{ span: 8 }}
              onKeyDown={(e) => {
                // シート側のkeyDownが発火しないように必要
                e.stopPropagation()
              }}
              // 本来であればonFinishを使いたいところだが、ロジックをSaveButtonコンポーネントに寄せることを意識して、ここでは使わない
            >
              <VisualizeOnlySection>
                <>
                  <StepContent targetStep={0}>
                    <KpiFormNameFormItem />
                    <KpiFormDescriptionFormItem />
                    <KpiFormIconFormItem />
                    <KpiFormViewGroupIdFormItem />
                    <KpiFormDashboardIdsFormItem />
                  </StepContent>
                  <StepContent targetStep={1}>
                    <KpiFormRootNodeFormItem withLabelIcon />
                    <KpiFormChildNodeFormItem withLabelIcon />
                  </StepContent>
                  <StepContent targetStep={2}>
                    <KpiFormMeasureFormItem required />
                    <KpiFormUserFormItem />
                    <KpiFormDateFormItem />
                    <KpiFormFilterTreeFormItem />
                  </StepContent>
                  <StepContent targetStep={3}>
                    <KpiFormSheetFieldsFormItem />
                    <KpiFormSheetFieldsFormAddDimeisions />
                    <KpiFormExtraFilterTreeFormItem />
                    <KpiFormDetailFormItems />
                    {/* <Space className="flex flex-row-reverse">
                    <KpiFormSaveButton />
                    <KpiFormDeleteButton />
                    複製のボタン、どこに置こうか？
                    {view !== undefined && <KpiFormCopyButton />}
                  </Space> */}
                  </StepContent>
                </>
              </VisualizeOnlySection>
            </Form>
            <DurationTimerEnd />
          </div>
        )
      }}
    />
  )
}

function StepContent({ targetStep, children }: { targetStep: 0 | 1 | 2 | 3; children: JSX.Element[] }) {
  const ability = useViewAbilityValue()
  const { organization } = useMeValue()
  const { views } = useViewsContextValue()
  const view = useViewValue()
  const { step, setStep, stepStatus, formModified, setForm, setFormModified } = useKpiFormSelectors()

  return (
    <div style={{ display: targetStep === step ? 'block' : 'none' }}>
      {children}
      <Row justify="space-between">
        <Col>
          {formModified && (
            <ButtonWithTooltip
              tooltipTitle={t(`権限がないのでKPI設定の変更を破棄できません`)}
              showTooltip={!ability.canUpdate}
              disabled={!formModified || !ability.canUpdate}
              icon={<DeleteOutlined />}
              danger
              onClick={() => {
                // viewAtomはタブ切り替え時にしか更新されないので、最新のconfigを参照するために
                // viewsContext内のviewsを使用する
                const currentView = views.find((x) => x.id === view.id)
                if (isNull(currentView) || currentView.config.type !== 'kpi') {
                  // ありえないはず
                  return
                }
                const kpiForm = generateInitialKpiForm({ viewName: currentView.name, config: currentView.config })
                setForm(kpiForm)
                setFormModified(false)
              }}
            >
              {t('KPI設定変更を破棄')}
            </ButtonWithTooltip>
          )}
        </Col>
        <Col>
          <Space>
            {/* 愚直に書いた方が楽なので、愚直にやる */}
            {step === 0 ? (
              <>
                <Button
                  type="primary"
                  disabled={!stepStatus.isStep2Enabled}
                  onClick={() => {
                    setStep(1)
                  }}
                  data-e2e="kpi-form-step-0-next-button"
                >
                  {t(`次へ`)}
                </Button>
              </>
            ) : step === 1 ? (
              <>
                <Button
                  type="default"
                  onClick={() => {
                    setStep(0)
                  }}
                  data-e2e="kpi-form-step-1-back-button"
                >
                  {t(`前へ`)}
                </Button>
                <Button
                  type="primary"
                  disabled={!stepStatus.isStep3Enabled}
                  onClick={() => {
                    setStep(2)
                  }}
                  data-e2e="kpi-form-step-1-next-button"
                >
                  {t(`次へ`)}
                </Button>
              </>
            ) : step === 2 ? (
              <>
                <Button
                  type="default"
                  onClick={() => {
                    setStep(1)
                  }}
                  data-e2e="kpi-form-step-2-back-button"
                >
                  {t(`前へ`)}
                </Button>
                <Button
                  type="primary"
                  disabled={!stepStatus.isStep4Enabled}
                  onClick={() => {
                    setStep(3)
                  }}
                  data-e2e="kpi-form-step-2-next-button"
                >
                  {t(`次へ`)}
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="default"
                  onClick={() => {
                    setStep(2)
                  }}
                  data-e2e="kpi-form-step-3-back-button"
                >
                  {t(`前へ`)}
                </Button>
                <KpiFormSaveButton />
              </>
            )}
          </Space>
        </Col>
      </Row>
    </div>
  )
}

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { compareFunction } from '@salescore/buff-common'
import { DeleteEltModelDocument, type EltModelFieldsFragment, FetchEltModelsDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { getColumnSearchProps } from '@salescore/frontend-common'
import { App, Button, Card, Spin, Table, Tag } from 'antd'
import { t } from 'i18next'
import { Suspense } from 'react'

import { ModelLabel } from './ModelLabel'
import { PropertyButton } from './PropertyButton'
import { SalescoreModelForm } from './SalescoreModelForm'

export const SalescoreModelTable = (): JSX.Element => {
  const { modal, message } = App.useApp()
  const { data, refetch, loading } = useQuery(FetchEltModelsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })

  const eltModels = data?.eltModels ?? []

  const [deleteEltModel] = useMutation(DeleteEltModelDocument)
  const sortedEltModels = eltModels.filter((x) => x.type === 'userCustomizedSalescoreSource').sortBy((x) => x.name)

  return (
    <Card
      extra={
        <Button
          key="2"
          type={'primary'}
          icon={<PlusOutlined />}
          onClick={() => {
            const m = modal.confirm({
              width: 'min(80%, 1440px)',
              title: t(`SALESCOREオブジェクトの新規作成`),
              content: (
                <Suspense fallback={<Spin />}>
                  <SalescoreModelForm
                    onAfterFinish={() => {
                      void refetch()
                      m.destroy()
                    }}
                    onAfterCancel={() => {
                      m.destroy()
                    }}
                  />
                </Suspense>
              ),
              footer: <></>,
            })
          }}
        >
          {t(`SALESCOREオブジェクトの新規作成`)}
        </Button>
      }
    >
      <Table
        loading={loading}
        dataSource={sortedEltModels}
        pagination={{
          pageSize: 100,
        }}
        scroll={{ y: 'min(85vh, calc(100vh - 250px))' }}
        columns={[
          {
            title: t(`オブジェクト名`),
            key: `label`,
            sorter: (a, b) => compareFunction(a.model.label, b.model.label),
            ...getColumnSearchProps<EltModelFieldsFragment>((eltModel) => eltModel.model.label),
            render: (_, eltModel) => {
              return <ModelLabel model={eltModel.model} />
            },
          },
          {
            title: t(`内部名`),
            key: `name`,
            sorter: (a, b) => compareFunction(a.name, b.name),
            ...getColumnSearchProps<EltModelFieldsFragment>((eltModel) => eltModel.name),
            render: (_, eltModel) => {
              return <Tag>{eltModel.name}</Tag>
            },
          },
          {
            title: ``,
            key: `properties`,
            width: 100,
            render: (_, eltModel) => <PropertyButton eltModel={eltModel} />,
          },
          {
            title: ``,
            key: `edit`,
            width: 100,
            render: (_: unknown, eltModel: EltModelFieldsFragment) => {
              return (
                <Button
                  type="text"
                  className="text-blue-500"
                  icon={<EditOutlined />}
                  onClick={() => {
                    const m = modal.info({
                      width: 'min(80%, 1440px)',
                      content: (
                        <Suspense fallback={<Spin />}>
                          <SalescoreModelForm
                            editingEltModel={eltModel}
                            onAfterFinish={() => {
                              m.destroy()
                            }}
                            onAfterCancel={() => {
                              m.destroy()
                            }}
                          />
                        </Suspense>
                      ),
                      footer: <></>,
                    })
                  }}
                >
                  {t(`編集`)}
                </Button>
              )
            },
          },
          {
            title: ``,
            key: `delete`,
            width: 100,
            render: (_: unknown, eltModel: EltModelFieldsFragment) => {
              return (
                <Button
                  type="text"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    void modal.confirm({
                      content: (
                        <div className="text-red-500">
                          {t(`削除を行うと今までに保存したデータが全て失われます。`)}
                          <br />
                          {t(`この操作は取り消せません。`)}
                          <br />
                          {t(`本当に削除しますか？`)}
                        </div>
                      ),
                      okText: t('削除する'),
                      onOk: () => {
                        void deleteEltModel({
                          variables: {
                            organizationId: getOrganizationIdFromPath(),
                            id: eltModel.id,
                          },
                          onCompleted: () => {
                            void message.success(t(`削除しました`))
                            void refetch()
                          },
                          onError: (error) => {
                            void message.error(t(`エラーが発生しました`) + `: ${error.message}`)
                          },
                        })
                      },
                    })
                  }}
                >
                  {t(`削除`)}
                </Button>
              )
            },
          },
        ]}
      />
    </Card>
  )
}

import type {
  CoreDslFormLeafPartial,
  CoreDslFormNodeStatePartial,
  CoreModel,
  ModelProperty,
  NodePropertyName,
  ViewConfigField,
} from '@salescore/core'
import { useState } from 'react'

import { CoreDslFormNode } from './CoreDslFormNode'

export interface TargetProperty {
  property: ModelProperty
  model: CoreModel
  nodePropertyName: NodePropertyName
  historyModel?: CoreModel
}

export const generateDefaultFormNode = (targetProperty: TargetProperty): CoreDslFormNodeStatePartial => {
  const defaultFormValue: CoreDslFormNodeStatePartial = {
    logicalOperator: 'and',
    leafs: [
      {
        left: {
          type: 'snapshotVariable',
          property: targetProperty.property,
          nodePropertyName: targetProperty.nodePropertyName,
          before: 0,
          dateSpan: 'day',
        },
      },
    ],
    children: [],
  }
  return defaultFormValue
}

export const CoreDslForm = ({
  targetProperty,
  initialValue,
  availableFields,
  isAdvancedMode,
  // onAfterFinish,
  onChange,
}: {
  targetProperty: TargetProperty
  initialValue: CoreDslFormNodeStatePartial | undefined
  availableFields: ViewConfigField[]
  isAdvancedMode?: boolean
  // onAfterFinish: (ast: ExpressionNodeWithDeprecated | undefined) => void
  onChange?: (ast: CoreDslFormNodeStatePartial | undefined) => void
}) => {
  const [formState, set] = useState<CoreDslFormNodeStatePartial>(() => {
    return initialValue ?? generateDefaultFormNode(targetProperty)
  })
  // 一応ViewFilterTreeFormのように単体でも使えるし他フォームに埋め込まれても使える形で作ったが、
  // 現状だと単体で使うことはなく、ConditionalEffectsFormのみで使われるので、ややこしくなっているかも
  const setFormState = (f: (x: CoreDslFormNodeStatePartial) => CoreDslFormNodeStatePartial) => {
    set((x) => {
      const newValue = f(x)
      if (onChange !== undefined) {
        onChange(newValue)
      }
      return newValue
    })
  }

  // const onFinish = () => {
  //   const ast = convertFormToAst(formState)
  //   onAfterFinish(ast)
  // }

  return (
    <>
      <CoreDslFormNode
        targetProperty={targetProperty}
        isAdvancedMode={isAdvancedMode ?? false}
        formNode={formState}
        availableFields={availableFields}
        setLeaf={(index: number, leaf: CoreDslFormLeafPartial | undefined) => {
          setFormState((x) => {
            const newLeafs = [...x.leafs.slice(0, index), leaf, ...x.leafs.slice(index + 1)].compact()
            return {
              ...x,
              leafs: newLeafs,
            }
          })
        }}
        setChildren={(index: number, newFilterNode: CoreDslFormNodeStatePartial | undefined) => {
          setFormState((x) => {
            const newChildren: CoreDslFormNodeStatePartial[] = [
              ...x.children.slice(0, index),
              newFilterNode,
              ...x.children.slice(index + 1),
            ].compact()
            return {
              ...x,
              children: newChildren,
            }
          })
        }}
        onLogicalOperatorChange={(logicalOperator: 'and' | 'or') => {
          setFormState((x) => {
            return { ...x, logicalOperator }
          })
        }}
        // onDestroy={() => {
        //   setFilterNodeState(initialState)
        // }}
      />
      {/* {onAfterFinish !== undefined && (
        <Row justify="end">
          <Button
            type="primary"
            onClick={() => {
              onFinish()
            }}
          >
            適用
          </Button>
        </Row>
      )} */}
    </>
  )
}

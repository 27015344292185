//
// 文字列型以外の型を強制的に文字列にする。undefinedとnullはそのままにする
//

export function castString(value: unknown): string | null | undefined {
  if (value === undefined || value === null) {
    return value
  }
  if (typeof value === 'string') {
    return value
  }

  return String(value)
}

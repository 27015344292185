import { isPresent, isSome } from '@salescore/buff-common'
import type { SourceProviderEnum } from '@salescore/client-api'
import { Button, Input } from 'antd'
import Form from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import { t } from 'i18next'
import { type Dispatch, type SetStateAction, useEffect } from 'react'

import { MICROSOFT_DYNAMICS_HOST_URL_KEY } from '../source_forms/MicrosoftDynamicsSourceForm'

interface Argument {
  loading: boolean
  onSave: () => Promise<void>
  sourceConfig: Record<string, unknown>
  setSourceConfig: Dispatch<SetStateAction<Record<string, unknown>>>
}

const SHEET_URL_PREFIX = 'https://docs.google.com/spreadsheets/d/'
const extractSheetId = (url: string) => {
  if (!url.startsWith(SHEET_URL_PREFIX)) {
    return
  }
  return url.replace(SHEET_URL_PREFIX, '').split('/')[0]
}

const GoogleSheetForm = ({ loading, onSave, setSourceConfig, sourceConfig }: Argument) => {
  return (
    <Form layout="vertical">
      <FormItem label={t(`スプレッドシートのURL`)} required>
        <Input
          onChange={(e) => {
            const url = e.target.value
            const spreadsheetId = extractSheetId(url)
            if (isSome(spreadsheetId)) {
              setSourceConfig({
                spreadsheetId,
              })
            } else {
              setSourceConfig({
                spreadsheetId: undefined,
              })
            }
          }}
        />
      </FormItem>

      <Button type="primary" loading={loading} onClick={onSave} disabled={!isPresent(sourceConfig.spreadsheetId)}>
        {t(`連携する`)}
      </Button>
    </Form>
  )
}

const MicrosoftDynamicsForm = ({ loading, onSave, setSourceConfig, sourceConfig }: Argument) => {
  const value = localStorage.getItem(MICROSOFT_DYNAMICS_HOST_URL_KEY)
  const host = value?.replace('.crm7.', '.api.crm7.')

  useEffect(() => {
    // ex. https://orge4f38956.api.crm7.dynamics.com
    setSourceConfig({ host })
  }, [])

  return (
    <Form layout="vertical">
      <FormItem label={t(`(確認)API URL`)}>
        <Input readOnly disabled value={host ?? ''} />
      </FormItem>

      <Button type="primary" loading={loading} onClick={onSave}>
        {t(`連携する`)}
      </Button>
    </Form>
  )
}

export const SourceConfigForm = ({
  loading,
  onSave,
  provider,
  setSourceConfig,
  sourceConfig,
}: Argument & { provider: SourceProviderEnum }) => {
  switch (provider) {
    case 'google_sheets': {
      return (
        <GoogleSheetForm
          onSave={onSave}
          loading={loading}
          sourceConfig={sourceConfig}
          setSourceConfig={setSourceConfig}
        />
      )
    }
    case 'microsoft_dynamics': {
      return (
        <MicrosoftDynamicsForm
          onSave={onSave}
          loading={loading}
          sourceConfig={sourceConfig}
          setSourceConfig={setSourceConfig}
        />
      )
    }
    default: {
      return (
        <Button type="primary" loading={loading} onClick={onSave}>
          {t(`連携する`)}
        </Button>
      )
    }
  }
}

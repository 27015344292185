import { PropertyTypeEnum, type SourceProviderEnum } from '@salescore/client-api'
import { HUB_PROPERTY_TYPE_LABEL, SourceProviderIcon } from '@salescore/client-common'
import type { CoreModel, ModelProperty } from '@salescore/core'
import { Cascader } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'

export function PropertyCascader({
  models,
  value,
  placeholder,
  className,
  onChange,
}: {
  models: CoreModel[]
  value?: { propertyName: string; modelName: string }
  placeholder?: string
  className?: string
  onChange: (x: { propertyName: string; modelName: string }) => void
}) {
  const options = useMemo(() => {
    return models
      .sortBy((x) => x.name)
      .filter((x) => !x.name.startsWith(`salescore_`))
      .map((model) => {
        const maybeProvider = model.name.split('_').first()! // TODO: modelにproviderを含めたい
        return {
          value: model.name,
          label: (
            <span className="whitespace-normal break-all">
              <SourceProviderIcon provider={maybeProvider as SourceProviderEnum} /> {model.label}
            </span>
          ),
          children: model.properties
            .groupBy((x) => propertyToTypeName(x))
            .map((typeJa, ps) => {
              return {
                value: typeJa,
                label: typeJa,
                children: ps.map((property) => {
                  return {
                    value: property.name,
                    label: property.label,
                    // searchKey: [model.name, property.name].join(' ')
                  }
                }),
              }
            })
            .sortBy((x) => -rankTypeName(x.label)),
        }
      })
  }, [models])

  return (
    <Cascader
      className={className}
      style={{ width: '100%' }}
      expandTrigger="hover"
      options={options}
      showSearch={{
        filter: (inputValue, optionAsList) => {
          // const searchKeyword = optionAsList[2]?.searchKey ?? ''
          const searchKeyword = [optionAsList[0]?.label ?? '', optionAsList[2]?.label ?? ''].join(' ')

          return inputValue.split(/\s/).every((input) => searchKeyword.includes(input.toLowerCase()))
        },
      }}
      placeholder={placeholder ?? t(`選択してください`)}
      onChange={(path: unknown[]) => {
        const modelName = path[0]! as string
        const propertyName = path[2]! as string
        onChange({ modelName, propertyName })
      }}
      value={undefined} // 現状のユースケースだと常に何も表示しない想定。必要があれば改修
      displayRender={(labels, selectedOptions) => {
        return <></>
      }}
      dropdownRender={(menus) => {
        return <div className="property-cascader">{menus}</div>
      }}
    />
  )
}

function propertyToTypeName(property: ModelProperty) {
  if ((property.selectOptions ?? []).length > 0) {
    return t('選択肢')
  }
  if ((property.referenceTo ?? []).length > 0) {
    return t('参照ID')
  }

  if (property.type === 'integer' || property.type === 'numeric') {
    return t('数値')
  }
  if (property.type === PropertyTypeEnum.Date || property.type === PropertyTypeEnum.Datetime) {
    return t('日付')
  }
  return HUB_PROPERTY_TYPE_LABEL[property.type] ?? property.type
}

// XXX: ラベル値に依存した並び替えになっていてあまりよくないが、現状これで問題ないのと、今後の改修でデグレしたとしても大きな問題にならないのでこれで
function rankTypeName(typeName: string): number {
  return (
    {
      [t('テキスト')]: 10,
      [t('選択肢')]: 9,
      [t('数値')]: 8,
      [t('日付')]: 7,
      [t('参照ID')]: -1,
    }[typeName] ?? 0
  )
}

import { selector, useRecoilValue } from 'recoil'

import { CELL_WIDTH } from '../../components/body/RSheetRow'
import { HEADER_RIGHT_WIDTH_MARGIN } from '../../components/header/RSheetsHeaderRow'
import { contextAtom } from '../atoms'
import { columnsModel } from '../models/propModels'

export const columnRelatedSelector = selector({
  key: 'columnRelatedSelector',
  get: ({ get }) => {
    const columns = get(columnsModel)
    const context = get(contextAtom)

    const leftFixedColumns = columns.slice(0, context?.fixedLeftColumnIndex ?? 0)
    const notFixedColumns = columns.slice(context?.fixedLeftColumnIndex ?? 0)

    const maxColumnIndex = columns.length - 1

    const widths = [...columns.map((x) => x.width ?? CELL_WIDTH), HEADER_RIGHT_WIDTH_MARGIN]
    const widthsSum = widths.sum()

    return {
      columns,
      leftFixedColumns,
      notFixedColumns,
      widths,
      widthsSum,
      maxColumnIndex,
    }
  },
})

export const useColumnsRelatedSelector = () => {
  return useRecoilValue(columnRelatedSelector)
}

import { ApolloProvider } from '@apollo/client'
import { openIntercom, Posthog, useApolloClient } from '@salescore/client-base'
import { ErrorProvider } from '@salescore/client-common'
import { Layout } from 'antd'
import type { AppProps } from 'next/app'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { I18nProviderWithNoAuth } from '../providers/I18nProvider'
import { DefaultHead } from './base/DefaultHead'

const NotAuthenticatedApolloProvider = ({ children }: { children: JSX.Element }) => {
  const client = useApolloClient()

  if (client === undefined) {
    return <></>
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

const Body = (properties: AppProps) => {
  const { Component, router } = properties
  openIntercom({})
  Posthog.trackAccess(router.pathname, {})

  return (
    <I18nProviderWithNoAuth>
      <ErrorProvider router={router}>
        <DefaultHead />
        <Layout className="h-full bg-gray-900">
          <SwitchTransition>
            <CSSTransition key={router.asPath} timeout={200} classNames="page-transition">
              <Component />
            </CSSTransition>
          </SwitchTransition>
        </Layout>
      </ErrorProvider>
    </I18nProviderWithNoAuth>
  )
}

export const NoMemberLayout = (properties: AppProps) => {
  return (
    <NotAuthenticatedApolloProvider>
      <Body {...properties} />
    </NotAuthenticatedApolloProvider>
  )
}

import { useSetRecoilState } from 'recoil'

import { addChildNodeMutation } from './tree/addChildNode'
import { editNodeMutation } from './tree/editNodeMutation'
import { setNodeMutation } from './tree/setNodeMutation'
import { setRootNodeMutation } from './tree/setRootNode'

export const useTreeMutations = () => {
  return {
    setRootNode: useSetRecoilState(setRootNodeMutation),
    addChildNode: useSetRecoilState(addChildNodeMutation),
    editNode: useSetRecoilState(editNodeMutation),
    setNode: useSetRecoilState(setNodeMutation),
  }
}

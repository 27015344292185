import { isNull } from '@salescore/buff-common'
import type { ViewConfigField } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'
import { useSetRecoilState } from 'recoil'

import { configSheetAtom } from '../atoms'
import { addFieldByPropertyMutation } from './field/addFieldByProperty'
import { addFieldsByFieldsMutation } from './field/addFieldsByFields'
import { addMeasureMutation, removeMeasureMutation } from './field/fieldMeasureMutations'
import { moveFieldMutation } from './field/moveFieldMutation'
import { removeFieldMutation } from './field/removeFieldMutation'
import { setFieldMutation } from './field/setFieldMutation'
import { setQueryFieldMutation } from './field/setQueryFieldMutation'
import { setSearchableFieldsMutation } from './field/setSearchableFieldsMutation'
import { setUiColumnMutation } from './field/setUiColumnMutation'
import { isSameNodeProperty } from './field/util'
import { setConfigMutation } from './setConfigMutation'

const setFieldDefaultValuesMutation = mutation({
  key: `view/setFieldDefaultValuesMutation`,
  set({ get, set }, { fieldsWithDefaultValue }: { fieldsWithDefaultValue: ViewConfigField[] }) {
    const config = get(configSheetAtom)
    set(setConfigMutation, {
      ...config,
      fields: config.fields?.map((field) => {
        const fieldWithDefaultValue = fieldsWithDefaultValue.find((x) => isSameNodeProperty(x.property, field.property))
        if (isNull(fieldWithDefaultValue)) {
          return field
        }
        return {
          ...field,
          override: {
            ...field.override,
            queryField: fieldWithDefaultValue.override?.queryField,
          },
        }
      }),
    })
  },
})

export const useFieldMutations = () => {
  return {
    addByProperty: useSetRecoilState(addFieldByPropertyMutation),
    addByFields: useSetRecoilState(addFieldsByFieldsMutation),
    moveField: useSetRecoilState(moveFieldMutation),
    remove: useSetRecoilState(removeFieldMutation),
    setFieldDefaultValues: useSetRecoilState(setFieldDefaultValuesMutation),
    setSearchableFields: useSetRecoilState(setSearchableFieldsMutation),
    setUiColumn: useSetRecoilState(setUiColumnMutation),
    setQueryField: useSetRecoilState(setQueryFieldMutation),
    setField: useSetRecoilState(setFieldMutation),
    addMeasure: useSetRecoilState(addMeasureMutation),
    removeMeasure: useSetRecoilState(removeMeasureMutation),
  }
}

import type { SubscriptionStatusFieldsFragment } from '@salescore/client-api'
import { Table } from 'antd'
import { t } from 'i18next'

export function OrganizationSubscriptionStatusTable({
  subscriptionStatus,
}: {
  subscriptionStatus: SubscriptionStatusFieldsFragment[]
}) {
  return (
    <>
      <Table
        title={() => t(`契約情報`)}
        pagination={false}
        className="mb-4"
        dataSource={subscriptionStatus}
        columns={[
          {
            dataIndex: `plan`,
            title: t(`ライセンス`),
            render: (_, record) => {
              return <span>{record.subscription.plan.toLocaleUpperCase()}</span>
            },
          },
          {
            dataIndex: `numberOfSeats`,
            title: t(`契約中ライセンス数`),
          },
          {
            dataIndex: `numberOfUsers`,
            title: t(`ユーザー数(+招待中ユーザー数)`),
            render: (_, record) => {
              return (
                <span>
                  {record.numberOfUsers} (+{record.numberOfInvitations})
                </span>
              )
            },
          },
          {
            dataIndex: `numberOfRestSeats`,
            title: t(`招待可能ユーザー数`),
          },
        ]}
      />
    </>
  )
}

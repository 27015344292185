import {
  CORE_CONSTANT,
  type ViewConfigAdditionalConfig,
  type ViewConfigField,
  type ViewConfigKpiParameter,
  type ViewConfigKpiPreset,
  type ViewConfigSheetPreset,
  type ViewConfigTreeNode,
  type ViewKpiAppearance,
  type ViewQueryField,
} from '@salescore/core'
import {
  type ChartLabelDisplayModeValue,
  type ChartLegendsDisplayModeValue,
  localStorageEffect,
  type ModalAtomType,
  type ModalAtomTypeWithInnerState,
  type StackedChartType,
} from '@salescore/frontend-common'
import { atom, atomFamily, selector } from 'recoil'
import { z, type ZodSchema } from 'zod'

import type { ViewConfigFilterNodeForForm } from '../../components/view_ui/ViewUISheetWithNavigation/SheetNavigation/filter/ViewFilterTreeForm'
import type { RSheetColumn, RSheetRecordNode } from '../../rsheet/types'
import { additionalConfigAtom } from '../records/atoms'
import { configAtom } from '../view/atoms'

const prefix = `view/navigation`

export const joinFilterTargetNodeAtom = atom<ViewConfigTreeNode | undefined>({
  key: `${prefix}/joinFilterTargetNode`,
  default: undefined,
})

export type RowLineNum = 1 | 2 | 3 | 4 | 5 | 10
export const rowLineNumAtom = atom<RowLineNum>({
  key: `${prefix}/rowLineNum`,
  default: 1,
  effects: [
    localStorageEffect(`${prefix}/rowLineNum`, z.number() as ZodSchema<RowLineNum>), // TODO
  ],
})

//
// modals（何かしらで共通化しようとしたが、逆に面倒だった）
//
export const searchSqlModalAtom = atom<ModalAtomType<ViewQueryField>>({
  key: `${prefix}/searchSqlModal`,
  default: {
    visible: false,
    content: undefined,
  },
})

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type DebugModalArgument = {
  content: unknown
  schema?: ZodSchema<unknown>
  onChange?: (x: unknown) => void
}
export const measureFormModalAtom = atom<
  ModalAtomType<{
    measureName?: string
    sheetViewId: string
    field: ViewConfigField
  }>
>({
  key: `${prefix}/measureFormModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const syncUserPlanNotFoundModalAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/syncUserPlanNotFoundModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const conditionalEffectsFormModalAtom = atom<
  ModalAtomType<{
    configField: ViewConfigField
  }>
>({
  key: `${prefix}/conditionalEffectsFormModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const conditionalEffectsFormModalAtom2 = atom<
  ModalAtomType<{
    configField: ViewConfigField
    column: RSheetColumn<RSheetRecordNode> // タイトル表示のためだけに使用予定
  }>
>({
  key: `${prefix}/conditionalEffectsFormModalAtom2`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const convertLeadFormModalAtom = atom<
  ModalAtomType<{
    leadIds: string[]
    onFinish?: () => void
  }>
>({
  key: `${prefix}/convertLeadFormModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const modelRecordsSnapshotsDrawerAtom = atom<
  ModalAtomType<{
    id: string
    modelName: string
    propertyNames: string[]
    columnName: string | undefined
  }>
>({
  key: `${prefix}/modelRecordsSnapshotsDrawerAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})
export const columnsDrawerAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/columnsDrawerAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})
export const debugModalAtom = atom<ModalAtomType<DebugModalArgument>>({
  key: `${prefix}/debugModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const modelFormViewModalAtom = atom<
  ModalAtomType<{ id?: string; modelName: string; onFinish?: (id: string) => void }>
>({
  key: `${prefix}/modelFormViewModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const nodeSearchSqlModalAtom = atom<ModalAtomType<ViewConfigTreeNode>>({
  key: `${prefix}/nodeSearchSqlModal`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const nodeModalAtom = atom<ModalAtomType<ViewConfigTreeNode>>({
  key: `${prefix}/nodeModal`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const importFieldsModalAtom = atom<ModalAtomType<ViewConfigField>>({
  key: `${prefix}/importFieldsModal`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const metadataModalAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/metadataModal`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const fieldsModalAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/fieldsModal`,
  default: {
    visible: false,
    content: undefined,
  },
})
export const sheetPresetFormModalAtom = atom<ModalAtomType<{ preset: ViewConfigSheetPreset | undefined }>>({
  key: `${prefix}/sheetPresetFormModal`,
  default: {
    visible: false,
    content: undefined,
  },
})
export const sheetPickedPresetNameAtom = atom<string | undefined>({
  key: `${prefix}/sheetPickedPresetNameAtom`,
  default: undefined,
})
export const filtersModalAtom = atom<ModalAtomType<ViewConfigFilterNodeForForm | undefined>>({
  key: `${prefix}/filtersModal`,
  default: {
    visible: false,
    content: undefined,
  },
})
export const sortersModalAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/sortersModal`,
  default: {
    visible: false,
    content: undefined,
  },
})
export const joinFiltersModalAtom = atom<ModalAtomType<ViewConfigTreeNode>>({
  key: `${prefix}/joinFiltersModal`,
  default: {
    visible: false,
    content: undefined,
  },
})
export const changesModalAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/changesModal`,
  default: {
    visible: false,
    content: undefined,
  },
})
export const errorsModalAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/errorsModal`,
  default: {
    visible: false,
    content: undefined,
  },
})

// viewIdを指定してドリルダウン
export interface DrillDownParameterForAny {
  type: 'any'
  viewId: string
}

// recordIdを指定して、フォームとして表示
export interface DrillDownParameterForSheet {
  type: 'form'
  recordId: string
  // 本来はここでフォームのviewIdを指定、ないしモデル名を指定したい
  // viewId
  // 現状だとフォーム周りの実装をしておらず、formのviewは存在しないので、prevViewがsheetである前提で作っている
}

// ダッシュボード・KPIからのドリルダウン。KPIと行列の値(絞り込みで利用)を指定して表示
export interface DrillDownParameterForKpiPivot {
  type: 'kpi'
  dimensionValues: {
    rows: Array<{ value: unknown; label: string | undefined }>
    columns: Array<{ value: unknown; label: string | undefined }>
  }
  viewId?: string
  additionalFilterLeafsWithPrev?: ViewConfigAdditionalConfig['filterLeafs']
  queryParameter?: ViewConfigKpiParameter['queryParameter']
  kpiPivotPresetName?: string
}

export type DrillDownParameter = DrillDownParameterForSheet | DrillDownParameterForKpiPivot | DrillDownParameterForAny
export const drillDownModalAtom = atom<
  ModalAtomTypeWithInnerState<
    DrillDownParameter | undefined,
    {
      hasChanges: boolean // ドリルダウンモーダルに未保存のデータ変更があるか
    }
  >
>({
  key: `${prefix}/drillDownModal`,
  default: {
    visible: false,
    content: undefined,
    innerState: { hasChanges: false },
  },
})

export const searchableFieldsModalAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/searchableFieldsModal`,
  default: {
    visible: false,
    content: undefined,
  },
})

// 以下、kpi系(別にした方がよいだろうか？)
export const kpiParameterAtom = selector<ViewConfigKpiParameter>({
  key: `${prefix}/kpiParameterAtom`,
  get({ get }) {
    const additionalConfig = get(additionalConfigAtom)
    const config = get(configAtom)
    return additionalConfig.kpiParameter ?? CORE_CONSTANT.PIVOT_EMPTY_PRESET(config).parameter
  },
  set({ get, set }, value) {
    const kpiParameter = value as ViewConfigKpiParameter
    set(additionalConfigAtom, (x) => {
      return {
        ...x,
        kpiParameter: {
          ...kpiParameter,
          pivot: {
            rows: kpiParameter.pivot.rows.uniqueBy((x) => [x.key, x.dateSpan].compact().join(',')), // XXX: 不要なはずだが、どこかのロジックでKPIが重複することがあったので一応追加
            columns: kpiParameter.pivot.columns.uniqueBy((x) => [x.key, x.dateSpan].compact().join(',')),
          },
          // periodだけは既存の設定を引き継ぎたいので以下のように上書き
          queryParameter: kpiParameter.queryParameter ?? x.kpiParameter?.queryParameter,
        },
      }
    })
  },
})

export const shareKpiParameterAtom = atom<ViewConfigKpiParameter | undefined>({
  key: `${prefix}/shareKpiParameterAtom`,
  default: undefined,
})

export const kpiPivotParameterDrawerVisibilityAtom = atom<boolean>({
  key: `${prefix}/kpiPivotParameterDrawerVisibilityAtom`,
  default: false,
})

export const kpiPivotKpisModalAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/kpiPivotKpisModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const kpiBulkDuplicateModalAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/kpiBulkDuplicateModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const kpiPresetFormModalAtom = atom<
  ModalAtomType<{ preset: ViewConfigKpiPreset | undefined; onFinish?: (newPreset: ViewConfigKpiPreset) => void }>
>({
  key: `${prefix}/kpiPresetFormModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const kpiPivotPickedPresetNameAtom = atom<string | undefined>({
  key: `${prefix}/kpiPivotPickedPresetNameAtom`,
  default: undefined,
})

export const kpiFormModalAtom = atom<ModalAtomType<{ kpiViewId: string; from: 'sheet' | 'kpi' }>>({
  key: `${prefix}/kpiFormModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const newKpiFormModalAtom = atom<ModalAtomType<{ onFinish: (kpiId: string) => void | Promise<void> }>>({
  key: `${prefix}/newKpiFormModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const kpiPivotFormModalAtom = atom<
  ModalAtomType<{ viewGroupId: string; onCreate: (viewId: string) => void | Promise<void> }>
>({
  key: `${prefix}/kpiPivotFormModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const dimensionFiltersModalAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/dimensionFiltersModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const compileLogsModalAtom = atom<ModalAtomType<void>>({
  key: `${prefix}/compileLogsModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export type KpiDisplayFormat = 'table' | 'bar' | 'pie' | 'bar-time-series' | 'sheet' | 'setting'
export const kpiDisplayFormatAtom = atom<KpiDisplayFormat | undefined>({
  key: `${prefix}/kpiDisplayFormatAtom`,
  default: undefined, // DisplayFormatPickerの方でuseEffectにより設定される
})

// 円グラフで使用するカテゴリのフィルター設定
const kpiPieChartCategoryFiltersSchema = z
  .object({
    key: z.string(),
  })
  .array()
export type KpiPieChartCategoryFilters = z.infer<typeof kpiPieChartCategoryFiltersSchema>
export const kpiPieChartCategoryFiltersAtom = atom<KpiPieChartCategoryFilters>({
  key: `${prefix}/kpiPieChartCategoryFiltersAtom`,
  default: [],
})

// KPIグラフにおけるデザイン設定
export interface ChartDesignSettings {
  chartType: StackedChartType
  chartValueLabelDisplayMode: ChartLabelDisplayModeValue
  chartValueLabelUnitType: ViewKpiAppearance['unitType']
  chartValueLabelDecimalPlaceType: ViewKpiAppearance['decimalPlaceType']
  chartValueLabelShouldShowPercentage: boolean
  chartAxisLabelDisplayMode: ChartLabelDisplayModeValue
  chartLegendsDisplayMode: ChartLegendsDisplayModeValue
}

export const chartDesignSettingsAtom = atom<ChartDesignSettings>({
  key: `${prefix}/stackedChartDesignSettingsAtom`,
  default: {
    chartType: 'bar',
    chartValueLabelDisplayMode: 'auto',
    chartValueLabelUnitType: 'noUnit',
    chartValueLabelDecimalPlaceType: 'automatic',
    chartValueLabelShouldShowPercentage: false,
    chartAxisLabelDisplayMode: 'auto',
    chartLegendsDisplayMode: 'auto',
  },
})

// ユーザーごとのちょっとした表示設定(=組織全体に反映しない設定)
const kpiUserAppearanceSchema = z.object({
  diffVisible: z.boolean().optional(),
  goalVisible: z.boolean().optional(),
  perElapsedBusinessDays: z.boolean().optional(),
  colorSchema: z.enum(['normal', 'success-green']).optional(),
  skipTotal: z.boolean().optional(),
  totalRowOrder: z.enum([`top`, `bottom`]).optional(),
  totalColumnOrder: z.enum([`left`, `right`]).optional(),
  fontSize: z.number().optional(),
})
export type KpiUserAppearance = z.infer<typeof kpiUserAppearanceSchema>
export const kpiUserAppearanceAtom = atom<KpiUserAppearance>({
  key: `${prefix}/kpiUserAppearanceAtom`,
  default: {
    diffVisible: true,
    goalVisible: true,
  },
  effects: [localStorageEffect(`${prefix}/kpiUserAppearanceAtom`, kpiUserAppearanceSchema)],
})

export const dimensionFilterOptionsWithCacheAtomFamily = atomFamily<
  Array<{ value: string | null; label: string }>,
  { dimensionKey: string }
>({
  key: `${prefix}/dimensionFilterOptionsWithCacheAtomFamily`,
  default: [],
})

// RI
export type RiDisplayFormat = 'timeSeries' | 'waterfall'
export const riDisplayFormatAtom = atom<RiDisplayFormat | undefined>({
  key: `${prefix}/riDisplayFormatAtom`,
  default: undefined,
})

import { isNull, sleep } from '@salescore/buff-common'
import { Modal } from 'antd'
import { t } from 'i18next'

import { ViewForm } from '../../../components/form/ViewForm'
import { useKpiPivotNavigationModal } from '../../../recoil/navigation/hooks'
import { useViewsContextValue } from '../../../recoil/view/hooks'
import { useViewsRelated } from '../../../recoil/view/selectors/viewsRelatedSelector'

//
// TODO: pageにも同名のコンポーネントがある
//
export const ViewFormModal = () => {
  const { newKpiFormModal } = useKpiPivotNavigationModal()
  const { viewGroups, views, viewGroupsWithParents } = useViewsRelated()
  const { createView } = useViewsContextValue()

  return (
    <Modal
      open={newKpiFormModal.isModalVisible}
      onCancel={() => {
        newKpiFormModal.hideModal()
      }}
      width={'95%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        top: '3%',
        // maxWidth: 1000,
        padding: 0,
      }}
      title={t(`KPIの新規作成`)}
      destroyOnClose
      footer={<></>}
    >
      {newKpiFormModal.content !== undefined && (
        <ViewForm
          isPrivate={false}
          views={views}
          viewGroupsWithParents={viewGroupsWithParents}
          type={'kpi'}
          createView={async (arg) => {
            return await createView({ ...arg, shouldOpenAsTab: true })
          }}
          updateView={async (arg) => {
            // updateはありえないので一旦無視
            await sleep(0)
          }}
          onAfterFinish={async (view) => {
            if (isNull(view)) {
              return
            }
            await newKpiFormModal.content?.onFinish(view.id)
            newKpiFormModal.hideModal()
          }}
        />
      )}
    </Modal>
  )
}

import { ReloadOutlined, WarningOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { compareFunction, isNull, isPresent, isSome } from '@salescore/buff-common'
import {
  type EltModelFieldsFragment,
  FetchConnectionsDocument,
  FetchEltModelsDocument,
  FetchSyncSuccessfulModelsDocument,
  InvalidateEltModelSyncDocument,
} from '@salescore/client-api'
import { getOrganizationIdFromPath, routes } from '@salescore/client-base'
import { SourceProviderIcon } from '@salescore/client-common'
import { datetimeFormatJaYYYYMMDDHHmm, getColumnSearchProps, useRedirect } from '@salescore/frontend-common'
import { App, Button, Card, Table, Tag, Typography } from 'antd'
import { t } from 'i18next'

import { ModelLabel } from './ModelLabel'
import { PropertyButton } from './PropertyButton'

const { Text } = Typography

export const SourceModelTable = ({ connectionId }: { connectionId: string | null }): JSX.Element => {
  const { modal, message } = App.useApp()

  const { data: syncSuccessfulModelsResultData } = useQuery(FetchSyncSuccessfulModelsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })

  const { data, refetch, loading } = useQuery(FetchEltModelsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const connections =
    useQuery(FetchConnectionsDocument, {
      variables: {
        organizationId: getOrganizationIdFromPath(),
      },
    }).data?.connections ?? []
  const [invalidateEltModelSync] = useMutation(InvalidateEltModelSyncDocument)

  const connectionMapper = connections.groupByUniqueKey((c) => c.id)
  const eltModels = data?.eltModels ?? []
  const sortedEltModels = eltModels
    .filter((x) => x.type === 'source')
    .sortBy((x) => [connectionMapper[x.connectionId ?? '']?.source.provider, x.connectionId ?? '', x.name])

  const redirect = useRedirect()

  const syncSuccessfulModels = syncSuccessfulModelsResultData?.syncSuccessfulModels ?? []
  const allConnectionIds = eltModels
    .map((x) => x.connectionId)
    .compact()
    .unique()

  return (
    <Card>
      <Table
        loading={loading}
        dataSource={sortedEltModels}
        pagination={{
          pageSize: 100,
        }}
        scroll={{ y: 'min(85vh, calc(100vh - 250px))' }}
        columns={[
          {
            title: t(`連携先`),
            key: `connection`,
            width: 200,
            filters: connections
              .filter((x) => allConnectionIds.includes(x.id))
              .map((x) => {
                const source = connectionMapper[x.id]?.source
                return {
                  value: x.id,
                  text: (
                    <span>
                      <SourceProviderIcon provider={source?.provider} /> {source?.name}
                    </span>
                  ),
                }
              })
              .compact(),
            onFilter: (value: unknown, record: EltModelFieldsFragment) => record.connectionId === value,
            defaultFilteredValue: [connectionId].filter((x) => isPresent(x)).compact(),
            render: (_: unknown, eltModel: EltModelFieldsFragment) => {
              const source = connectionMapper[eltModel.connectionId ?? '']?.source
              if (source === undefined) {
                return <></>
              }
              return (
                <span>
                  <SourceProviderIcon provider={source.provider} /> {source.name}
                </span>
              )
            },
          },
          {
            title: t(`オブジェクト名`),
            width: 250,
            key: `label`,
            sorter: (a, b) => compareFunction(a.model.label, b.model.label),
            ...getColumnSearchProps<EltModelFieldsFragment>((eltModel) => eltModel.model.label),
            render: (_, eltModel) => {
              return <ModelLabel model={eltModel.model} />
            },
          },
          {
            title: t(`内部名`),
            width: 250,
            key: `name`,
            sorter: (a, b) => compareFunction(a.name, b.name),
            ...getColumnSearchProps<EltModelFieldsFragment>((eltModel) => eltModel.name),
            render: (_, eltModel) => {
              return <Tag>{eltModel.name}</Tag>
            },
          },
          {
            title: t(`同期時刻`),
            // 厳密には「同期時刻」はsyncedAtが正しく、syncedAtとtransformedAtを別々に表示したいが、
            // ユーザーがそこまで理解できないので、transformedAtを表示する
            key: `transformedAt`,
            width: 200,
            sorter: (a: EltModelFieldsFragment, b: EltModelFieldsFragment) =>
              compareFunction(a.transformedAt, b.transformedAt),
            render: (_: unknown, eltModel: EltModelFieldsFragment) => {
              if (eltModel.transform?.type === 'view') {
                // TODO: 同期が不要なことをどう伝えればいいか？
                return <span className="text-sm text-gray-400">同期の必要はありません</span>
              }
              if (isSome(eltModel.syncInvalidatedAt) || isSome(eltModel.transformInvalidatedAt)) {
                return <span>{t(`再同期中`)}</span>
              }
              return (
                <span>
                  {!syncSuccessfulModels.includes(eltModel.name) && (
                    <Text type={'danger'}>
                      <WarningOutlined />
                    </Text>
                  )}
                  {datetimeFormatJaYYYYMMDDHHmm(eltModel.transformedAt)}
                </span>
              )
            },
          },
          {
            title: ``,
            key: `properties`,
            width: 100,
            render: (_, eltModel) => <PropertyButton eltModel={eltModel} />,
          },
          {
            title: ``,
            key: `form`,
            width: 150,
            render: (_: unknown, eltModel: EltModelFieldsFragment) => {
              const disabled =
                eltModel.model.creatable === false ||
                eltModel.model.updatable === false ||
                eltModel.model.write === undefined
              return (
                <Button
                  type="text"
                  className="text-blue-500"
                  icon={<ReloadOutlined />}
                  disabled={disabled}
                  onClick={() => {
                    redirect(routes.eltModelFormPathV2(eltModel.name))
                  }}
                >
                  {t(`入力項目設定`)}
                </Button>
              )
            },
          },
          {
            title: ``,
            key: `invalidate`,
            width: 100,
            render: (_: unknown, eltModel: EltModelFieldsFragment) => {
              const disabled = isNull(eltModel.syncedAt) || isNull(eltModel.transformedAt)
              return (
                <Button
                  type="text"
                  className="text-blue-500"
                  icon={<ReloadOutlined />}
                  disabled={disabled}
                  onClick={() => {
                    void modal.confirm({
                      content: (
                        <div>
                          {t(`「{{label}}」のすべてのレコードを再取得します。`, { label: eltModel.model.label })}
                          {t(`通常、この操作を行う必要はありません。`)}
                          {t(`同期結果に問題が発生した際のみ、この操作を行ってください。`)}
                          {t(`同期には時間がかかることがあります。`)}
                        </div>
                      ),
                      cancelText: t('いいえ'),
                      okText: t(`はい`),
                      onOk: () => {
                        // TODO: この部分はシートの同期の仕組に変更したほうが良い
                        void invalidateEltModelSync({
                          variables: {
                            organizationId: getOrganizationIdFromPath(),
                            id: eltModel.id,
                          },
                          onCompleted: () => {
                            void message.success(
                              t(`再取得をセットしました。次回同期時に、全てのレコードが再取得されます`),
                            )
                            void refetch()
                          },
                          onError: () => {
                            void message.error(t(`エラーが発生しました`))
                          },
                        })
                      },
                    })
                  }}
                >
                  {t(`再同期`)}
                </Button>
              )
            },
          },
        ]}
      />
    </Card>
  )
}

import { logger } from '@salescore/frontend-common'
import { useEffect } from 'react'

import { useUpsertSheetRowMutation } from '../../../../recoil/mutations/upsertSheetRowMutation'
import type { RSheetsCellInputRenderTypeArgument } from '../InputCell'

// 一応定義しているものの、booleanは編集モードになることがないため、使われない想定
export function RSheetsCellBooleanInput({
  recordNode,
  column,
  rowIndex,
  innerRowIndex,
  defaultWidth,
  finishEditMode,
  boxStyle,
}: RSheetsCellInputRenderTypeArgument) {
  const columnOnChange = useUpsertSheetRowMutation()

  logger.debug(`booleanCellRender is called`)
  const initialValue = recordNode === undefined ? undefined : column.value(recordNode)

  useEffect(() => {
    if (typeof initialValue === 'boolean') {
      void columnOnChange({ value: !initialValue, rowIndex, innerRowIndex, column })
    } else {
      void columnOnChange({ value: true, rowIndex, innerRowIndex, column })
    }
    finishEditMode()
  }, [])

  return (
    <div
      style={{
        ...boxStyle,
        // Spreadsheetと似たロジックで、中身の文字列が増えたら幅を広げて全部表示させる。
        // 他のセルの位置に影響を与えずこれを実現するため、position: absoluteにして実現している
        // width: Math.max(defaultWidth, calculateCharLength(value as string) * 10),
        padding: '6px 10px', // padingYはボーダーにかぶらなければなんでも良い
        marginTop: 1, // これがないと1pxずれるが、Cell側と比べてなぜ1px分の差があるのか不明
        marginLeft: 1,
        // boxShadow: '1px 4px 7px 2px rgb(60 64 67 / 15%)',
      }}
    ></div>
  )
}

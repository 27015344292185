import { RightOutlined } from '@ant-design/icons'
import {
  CustomModelIcon,
  getProviderFromModelName,
  HandleQuery,
  isCustomModel,
  SourceProviderIcon,
} from '@salescore/client-common'
import type { CoreModel, NodePath } from '@salescore/core'
import { Select, Space } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { useFieldMutations } from '../../../recoil/view/mutations/fieldMutations'
import { useTreeMutations } from '../../../recoil/view/mutations/treeMutations'
import { useConnectionsSelector } from '../../../recoil/view/selectors/connectionsSelector'
import { useViewSelector } from '../../../recoil/view/selectors/viewSelector'
import { PropertySelectorTable } from './PropertySelectorTableForContextMenu/PropertySelectorTable'

// レンダリングのパフォーマンスが悪いため、一部に制限する
// TODO: virtualizeして良い感じにやりたい
export const RENDER_ROW_LIMIT = 100
export const TABLE_HEIGHT = 400
const HEADER_HEIGHT = 160

export const PropertySelectorTableForContextMenu = ({
  initialNodePath,
  configIndex,
}: {
  initialNodePath: NodePath
  configIndex: number | undefined
}): ReactNode => {
  const { config, flattenNodes } = useViewSelector()
  const { models, getModel } = useConnectionsSelector()
  const { addByProperty } = useFieldMutations()
  const { addChildNode, setRootNode } = useTreeMutations()
  const [currentNodePath, setCurrentNodePath] = useState<NodePath>(initialNodePath)
  const [addedColumnCount, setAddedColumnCount] = useState(0)
  // NOTE: オブジェクトの順序を内部名の昇順で保ちつつprefixがsalescore_ or custom_の場合は末尾に配置する
  const sortedModels = [...models].sortBy((x) => {
    const [xPrefix] = x.name.split('_')
    return xPrefix === 'custom' || xPrefix === 'salescore' ? 1 : -1
  })

  if (currentNodePath.isBlank() || config.tree === undefined) {
    return (
      <div data-e2e="property-selector-table-for-context-menu-object-selection">
        <div className="mb-4">{t(`最初に、追加したいオブジェクトを選択してください。`)}</div>
        <Select
          className="w-full"
          showSearch
          filterOption={(input, option) => {
            return ((option?.key ?? '') as string).toLowerCase().includes(input.toLowerCase())
          }}
          onChange={(value) => {
            const model = models.find((x) => x.name === value)!
            if (model !== undefined) {
              setRootNode({
                model,
                onSet(node) {
                  setCurrentNodePath([node.name])
                },
              })
            }
          }}
        >
          {sortedModels
            .filter((x) => !isSystemModel(x.name))
            .map((model) => {
              return (
                <Select.Option value={model.name} key={[model.name, model.label].join('-')}>
                  <Space>
                    <ModelIconLabel model={model} />
                  </Space>
                </Select.Option>
              )
            })}
        </Select>
      </div>
    )
  }

  const currentNodes = currentNodePath.map((nodeName) => flattenNodes.find((x) => x.name === nodeName)).compact()

  const modelLabel: string = getModel(currentNodes.last()!.modelName)?.label ?? t(`(削除されたモデル)`)

  return (
    <div
      className="relative overflow-y-auto"
      style={{ height: TABLE_HEIGHT + HEADER_HEIGHT }}
      data-e2e="property-selector-table-for-context-menu"
    >
      <div className="absolute flex w-full flex-col bg-white">
        <div className="mb-2 flex-none text-xs text-gray-500">
          {currentNodes.map((node, index) => {
            return (
              <span
                key={index}
                // TODO: ちゃんと戻れるようにする
                onClick={() => {
                  if (currentNodePath.length > 1) {
                    setCurrentNodePath((paths) => paths.slice(0, -1))
                  }
                }}
                className={currentNodePath.length > 1 ? 'cursor-pointer' : ''}
              >
                {getModel(node.modelName)?.label ?? t(`(削除されたモデル)`)}{' '}
                {index !== currentNodes.length - 1 && <RightOutlined />}
              </span>
            )
          })}{' '}
        </div>
        <div className="mb-2 flex-none font-bold">{t(`{{label}}に項目を追加`, { label: modelLabel })}</div>

        <TransitionGroup className="flex-1">
          {currentNodes.map((node, index) => {
            return (
              <CSSTransition
                key={node.name}
                in={true}
                timeout={600}
                appear={true}
                classNames={index === 0 ? '' : 'slide-left-property-context-menu'}
                unmountOnExit={true}
              >
                <HandleQuery>
                  <PropertySelectorTable
                    style={{
                      ...(index < currentNodes.length - 1
                        ? {
                            visibility: 'hidden',
                            transition: 'visibility 0s 0.3s linear',
                          }
                        : {
                            visibility: 'visible',
                          }),
                    }}
                    node={node}
                    parentNode={currentNodes[index - 1]}
                    addField={(nodeProperty) => {
                      if (configIndex === undefined) {
                        return
                      }
                      addByProperty({
                        nodeProperty,
                        targetIndex: configIndex + addedColumnCount, // configIndex がない時は先頭に追加
                        onAdded: () => {
                          setAddedColumnCount((x) => x + 1)
                        },
                        onRemoved: () => {
                          setAddedColumnCount((x) => [x - 1, 0].max()!) // max が undefined を返すのは配列の長さが0の場合のみ
                        },
                      })
                    }}
                    addChildNode={(referenceToProperty, option) => {
                      addChildNode({
                        node,
                        referenceToProperty,
                        onAdded: (newNodeName) => {
                          if (option?.skipSetCurrentNodePath !== true) {
                            setCurrentNodePath((xs) => [...xs, newNodeName])
                          }
                        },
                      })
                    }}
                  />
                </HandleQuery>
              </CSSTransition>
            )
          })}
        </TransitionGroup>
      </div>
    </div>
  )
}

function ModelIconLabel({ model }: { model: CoreModel }): ReactNode {
  const provider = getProviderFromModelName(model.name)
  return (
    <>
      {isCustomModel(model) ? <CustomModelIcon model={model} /> : <SourceProviderIcon provider={provider} />}
      <span className="whitespace-normal break-words">{model.label}</span>
    </>
  )
}

export function isSystemModel(modelName: string): boolean {
  return [
    `salescore_users`,
    `salescore_user_groups`,
    `salescore_kpis`,
    `salescore_goals`,
    `salescore_record_changes`,
    `salescore_my_actions`,
    `salescore_reverse_elt_logs`,
  ].includes(modelName)
}

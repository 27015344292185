import { LinkOutlined } from '@ant-design/icons'
import type { ViewUITitleFormItem } from '@salescore/core'
import { Button, Form, Row, Space, Typography } from 'antd'
import { t } from 'i18next'
import { useContext } from 'react'

import { useRecordsValue } from '../../recoil/records/hooks'
import { ViewUIFormListContext } from './ViewUIFormList'

const { Paragraph } = Typography

interface FormItemChildProperties {
  value?: string
  onChange?: (value: string) => void
}

export const FormItemWrapper = (properties: FormItemChildProperties) => {
  // TODO: editにしたときにレイアウトが崩れる
  return (
    <Paragraph
      editable={{
        onChange: properties.onChange,
        autoSize: { maxRows: 1, minRows: 1 },
      }}
      style={{ marginBottom: 0, width: 500 }}
    >
      {properties.value}
    </Paragraph>
  )
}

export function ViewUITitleFormItemC({ component }: { component: ViewUITitleFormItem }) {
  const { formListField } = useContext(ViewUIFormListContext)
  if (component.urlPath === undefined) {
    return <></>
  }

  const records = useRecordsValue()
  // const recordNode = getRecordNodes(component.urlPath.slice(0, -1)).first() // TODO
  const recordNode = records.first()
  const url = recordNode ? recordNode.attributes[component.urlPath.last() ?? ''] : undefined

  // TODO: 要デザイン検討、仕様検討
  if (url === undefined && component.valuePath === undefined) {
    if (component.defaultTitle === undefined) {
      return <></>
    }

    return (
      <Space className="mb-4 flex items-center align-middle text-xl font-bold">
        <div>{component.defaultTitle}</div>
      </Space>
    )
  }

  return (
    <Row className="mb-4 text-xl font-bold" justify="space-between" align="middle">
      <Row>
        <div className="mr-2">{component.defaultTitle}</div>
        {component.valuePath === undefined ? (
          <div className="font-bold">{/* {component.defaultTitle ?? 'オブジェクトを編集'} */}</div>
        ) : (
          <>
            <div className="mr-2">-</div>
            <div className="">
              <Form.Item
                name={formListField === undefined ? component.valuePath : [formListField.name, ...component.valuePath]}
                noStyle={true}
              >
                <FormItemWrapper />
              </Form.Item>
              {/* <EditOutlined /> */}
            </div>
          </>
        )}
      </Row>
      {/* 閉じるボタンを重ならないようにする */}
      <div className="mr-8">
        {url !== undefined && (
          <a href={url as string} target="_blank" rel="noreferrer">
            <Button icon={<LinkOutlined />}>{t(`連携先レコード`)}</Button>
          </a>
        )}
      </div>
    </Row>
  )
}

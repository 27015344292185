import { mutation } from '@salescore/frontend-common'
import { useSetRecoilState } from 'recoil'

import { kpiParameterAtom } from '../../../recoil/navigation/atoms'
import { pivotColumnsSelector } from '../../../recoil/selectors/pivotColumnsSelector'
import { configAtom } from '../../../recoil/view/atoms'
import { setUiColumnMutation } from '../../../recoil/view/mutations/field/setUiColumnMutation'
import { setConfigMutation } from '../../../recoil/view/mutations/setConfigMutation'
import type { RSheetColumn, RSheetRecordNode } from '../../types'

const resizeColumnMutation = mutation({
  key: `rsheet/resizeColumnMutation`,
  set(
    { get, set },
    {
      width,
      column,
      allColumnIndex,
    }: { width: number; column: RSheetColumn<RSheetRecordNode>; allColumnIndex: number },
  ) {
    const config = get(configAtom)

    if (config.type === 'sheet') {
      if (column.field === undefined) {
        // システムカラム等、 config.field が存在しない場合は何もしない。つまり列幅の変更を禁じる。
        return
      }
      set(setUiColumnMutation, { field: column.field, column: { width } })
    }
    if (config.type === 'kpiPivot' || config.type === 'kpi') {
      const { systemColumns, rowColumns, columnKeys } = get(pivotColumnsSelector)
      const type =
        allColumnIndex < systemColumns.length
          ? undefined
          : allColumnIndex < systemColumns.length + rowColumns.length
            ? 'row'
            : 'column'
      const parameter = get(kpiParameterAtom)
      if (type === 'row') {
        // 行のwidthを設定
        const index = allColumnIndex - systemColumns.length
        const dimension = parameter.pivot.rows[index]
        if (dimension === undefined) {
          return
        }
        // 型推論の都合で場合わけが必要（ロジックは同じ）
        if (config.type === 'kpi') {
          if (config.ui === undefined) {
            return // ありえないはず
          }
          set(setConfigMutation, {
            ...config,
            ui: {
              ...config.ui,
              appearance: {
                ...config.ui.appearance,
                rows: [
                  {
                    key: dimension.key,
                    width,
                  },
                  ...(config.ui.appearance?.rows ?? []).filter((x) => x.key !== dimension.key),
                ].uniqueBy((x) => x.key),
              },
            },
          })
        } else {
          set(setConfigMutation, {
            ...config,
            ui: {
              ...config.ui,
              appearance: {
                ...config.ui?.appearance,
                rows: [
                  {
                    key: dimension.key,
                    width,
                  },
                  ...(config.ui?.appearance?.rows ?? []).filter((x) => x.key !== dimension.key),
                ].uniqueBy((x) => x.key),
              },
            },
          })
        }
      } else if (type === 'column') {
        // 列のキーにwidhtを設定
        const index = allColumnIndex - systemColumns.length - rowColumns.length
        const dimension = parameter.pivot.columns.last()
        const columnKey = columnKeys[index]
        if (dimension === undefined || columnKey === undefined) {
          return
        }
        const value = columnKey.last()?.value
        if (value === undefined) {
          return
        }
        const previous = (config.ui?.appearance?.columns ?? []).find((x) => x.key === dimension.key)
        if (config.type === 'kpi') {
          if (config.ui === undefined) {
            return // ありえないはず
          }
          set(setConfigMutation, {
            ...config,
            ui: {
              ...config.ui,
              appearance: {
                ...config.ui.appearance,
                columns: [
                  {
                    key: dimension.key,
                    keys: [
                      {
                        value,
                        width,
                      },
                      ...(previous?.keys ?? []).filter((x) => x.value !== value),
                    ].uniqueBy((x) => x.value),
                  },
                  ...(config.ui.appearance?.columns ?? []).filter((x) => x.key !== dimension.key),
                ].uniqueBy((x) => x.key),
              },
            },
          })
        } else {
          set(setConfigMutation, {
            ...config,
            ui: {
              ...config.ui,
              appearance: {
                ...config.ui?.appearance,
                columns: [
                  {
                    key: dimension.key,
                    keys: [
                      {
                        value,
                        width,
                      },
                      ...(previous?.keys ?? []).filter((x) => x.value !== value),
                    ].uniqueBy((x) => x.value),
                  },
                  ...(config.ui?.appearance?.columns ?? []).filter((x) => x.key !== dimension.key),
                ].uniqueBy((x) => x.key),
              },
            },
          })
        }
      }
    }
  },
})

export const useResizeColumnMutation = () => {
  return useSetRecoilState(resizeColumnMutation)
}

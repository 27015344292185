import { useApolloClient } from '@apollo/client'
import { FetchViewsDocument, ViewConfigTypeEnum } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import type { ViewConfig, ViewQueryResult } from '@salescore/core'

export function useClearViewQueryCacheMutation() {
  const client = useApolloClient()

  /**
   * 指定されたviewIdsに紐づくviewQueryResultキャッシュを削除する
   */
  const clearTargetViewQueryCaches = ({ viewIds }: { viewIds: string[] }): void => {
    client.cache.modify<{
      viewQueryResult: ViewQueryResult
    }>({
      fields: {
        viewQueryResult: (existingViewQueryResult, { storeFieldName, DELETE }) => {
          const isMatch = viewIds.some((id) => storeFieldName.includes(`"viewId":"${id}"`))
          return isMatch ? DELETE : existingViewQueryResult
        },
      },
    })
  }

  /**
   * 該当のKPIとそのKPIを含むダッシュボードのviewQueryResultキャッシュを削除する
   */
  const clearKpiRelatedViewQueryCache = async ({ viewId }: { viewId: string }): Promise<void> => {
    const { data } = await client.query({
      query: FetchViewsDocument,
      variables: {
        organizationId: getOrganizationIdFromPath(),
        type: ViewConfigTypeEnum.KpiPivot,
      },
      fetchPolicy: 'cache-first',
    })
    const views = data.views

    // 対象のKPIを含むダッシュボードの一覧
    const kpiRelatedDashboards = views.filter((view) => {
      return view.config.type === 'kpiPivot' && view.config.kpis.some((kpi) => kpi.viewId === viewId)
    })
    // キャッシュを削除するviewIdの一覧
    const cacheClearViewIds = [viewId, ...kpiRelatedDashboards.map((x) => x.id)]
    clearTargetViewQueryCaches({ viewIds: cacheClearViewIds })
  }

  const clearRelatedViewQueryCaches = async ({
    viewId,
    config,
  }: {
    viewId: string
    config: ViewConfig
  }): Promise<void> => {
    switch (config.type) {
      case 'sheet':
      case 'kpiPivot':
      case 'kpiTimeSeries': {
        // KPI以外は指定されたviewIdに紐づくviewQueryResultキャッシュのみ削除する
        clearTargetViewQueryCaches({ viewIds: [viewId] })
        break
      }
      case 'kpi': {
        // KPIの場合は自身と自身を含むダッシュボードのviewQueryResultキャッシュを削除する
        await clearKpiRelatedViewQueryCache({ viewId })
        break
      }
    }
  }

  return {
    clearRelatedViewQueryCaches,
    clearKpiRelatedViewQueryCache,
    clearTargetViewQueryCaches,
  }
}

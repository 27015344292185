import { notifyBugsnag, type TailwindCssColor } from '@salescore/client-base'
import { selector, useRecoilValue } from 'recoil'

import { flatNodes } from '../../../state/nodeUtil'
import { listQueryAtom } from '../atoms'

const columnColors: TailwindCssColor[] = [`blue`, `green`, `yellow`, `red`, `purple`, `sky`, `emerald`, `amber`, `rose`]
export const querySelector = selector({
  key: `view/querySelector`,
  get({ get }) {
    const query = get(listQueryAtom)

    const flattenNodes = flatNodes(query.tree)
    const flattenFilterNodeLeafs = flatNodes(query.filterTree).flatMap((x) => x.leafs)
    const tableNameCounts = flattenNodes.map((x) => x.read.tableSql).countBy((x) => x)

    const usingNodeNames = query.fields.flatMap((x) => x.nodePath).unique()
    const nodeNamesWithColors = usingNodeNames.toObject((nodeName, index) => [
      nodeName,
      columnColors[index % columnColors.length],
    ])

    const getNodeName = (tableName: string) => {
      for (let index = 0; index < 100; index++) {
        const nodeName = index === 0 ? tableName : `${tableName}_${index}`
        if (!flattenNodes.map((x) => x.name).includes(nodeName)) {
          return nodeName
        }
      }
      notifyBugsnag({ error: new Error(`getNodeName failed`) })
      throw new Error(`getNodeName failed`)
    }

    return {
      query,
      flattenNodes,
      flattenFilterNodeLeafs,
      tableNameCounts,
      nodeNamesWithColors,
      getNodeName,
    }
  },
})

export const useQuerySelector = () => {
  return useRecoilValue(querySelector)
}

import { JobQueueLogLevelEnum } from '@salescore/client-api'
import { Tag } from 'antd'

const colors = {
  [JobQueueLogLevelEnum.Info]: 'blue',
  [JobQueueLogLevelEnum.Warn]: 'yellow',
  [JobQueueLogLevelEnum.Error]: 'red',
  [JobQueueLogLevelEnum.Debug]: 'gray',
}

export const JobQueueLogLevelTag = ({ status }: { status: JobQueueLogLevelEnum }) => {
  return <Tag color={colors[status]}>{status}</Tag>
}

import { DownOutlined } from '@ant-design/icons'
import type { FetchMeQuery, V2Organization } from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { recoil } from '@salescore/client-recoil'
import { ellipsisFilter, useRedirect } from '@salescore/frontend-common'
import { Dropdown, Menu, Space } from 'antd'
import SubMenu from 'antd/es/menu/SubMenu'
import { t } from 'i18next'

import { signInAs } from '../presentation/common/auth'
import { OrganizationAvatar } from './avatar/OrganizationAvatar'

export const SiderOrganizationV2 = ({
  myIdentity,
  myUser,
}: {
  myUser: FetchMeQuery['myUser']
  myIdentity: {
    users: Array<{
      id: string
      organization: Pick<V2Organization, 'createdAt' | 'name' | 'id'>
    }>
  }
}) => {
  const ability = recoil.global.policy.useCanForFeatures()
  const redirect = useRedirect()
  const content = (
    <div className="flex w-full cursor-pointer items-center justify-between px-4 py-3 align-middle hover:bg-slate-50 hover:opacity-90">
      <div className="flex items-center align-middle">
        <OrganizationAvatar organization={myUser.organization} />
        <div className="ml-2 text-sm" style={{ width: 140, wordBreak: 'break-all' }}>
          {ellipsisFilter(myUser.organization.name, 20)}
        </div>
      </div>
      <div className="ml-1">
        <DownOutlined />
      </div>
    </div>
  )
  if (myIdentity.users.length <= 1) {
    return content
  }

  const users = myIdentity.users.sortBy((user) => {
    return user.organization.createdAt as unknown
  })
  return (
    <Dropdown
      placement="topRight"
      arrow={true}
      overlay={
        <Menu>
          <SubMenu title={t(`他の組織にログイン`)}>
            {users.map((user) => (
              <Menu.Item
                key={user.id}
                onClick={() => {
                  signInAs(user.organization, {
                    newWindow: true,
                  })
                }}
              >
                <Space>
                  <OrganizationAvatar organization={user.organization} />
                  {user.organization.name}
                </Space>
              </Menu.Item>
            ))}
          </SubMenu>
          {ability.canManageOrganizationSetting && (
            <Menu.Item
              onClick={() => {
                redirect(routes.organizationSettingsPathV2())
              }}
            >
              {/* spanがないと、collapsed: trueのときに1文字のみになってしまう */}
              <span>{t(`組織設定`)}</span>
            </Menu.Item>
          )}
        </Menu>
      }
      trigger={['click']}
    >
      {content}
    </Dropdown>
  )
}

import { SourceProviderEnum } from '@salescore/client-api'
import { Tooltip } from 'antd'
import Avatar from 'antd/es/avatar/avatar'

type IntegrationProviderColumnName =
  | 'salescore_user_id'
  | 'salescore_invitation_id'
  | 'salesforce_user_id'
  | 'hubspot_owner_id'
  | 'hubspot_user_id'
  | 'zoho_user_id'
  | 'next_sfa_user_id'
  | 'google_sheets_user_id'
  | 'comdesk_user_id'
  | 'senses_user_id'
  | 'microsoft_dynamics_user_id'
  | 'big_query_user_id'
  | 'kintone_user_id'

interface INTEGRATION_PROVIDER_WITH_COLUMN_NAMES {
  provider: SourceProviderEnum
  columns: IntegrationProviderColumnName[]
}

export const INTEGRATION_PROVIDERS_WITH_COLUMN_NAMES: INTEGRATION_PROVIDER_WITH_COLUMN_NAMES[] = [
  {
    provider: SourceProviderEnum.Salescore,
    columns: ['salescore_user_id', 'salescore_invitation_id'],
  },
  {
    provider: SourceProviderEnum.Salesforce,
    columns: ['salesforce_user_id'],
  },
  {
    provider: SourceProviderEnum.Hubspot,
    columns: ['hubspot_owner_id', 'hubspot_user_id'],
  },
  {
    provider: SourceProviderEnum.Zoho,
    columns: ['zoho_user_id'],
  },
  {
    provider: SourceProviderEnum.NextSfa,
    columns: ['next_sfa_user_id'],
  },
  {
    provider: SourceProviderEnum.GoogleSheets,
    columns: ['google_sheets_user_id'],
  },
  {
    provider: SourceProviderEnum.Comdesk,
    columns: ['comdesk_user_id'],
  },
  {
    provider: SourceProviderEnum.Senses,
    columns: ['senses_user_id'],
  },
  {
    provider: SourceProviderEnum.MicrosoftDynamics,
    columns: ['microsoft_dynamics_user_id'],
  },
  {
    provider: SourceProviderEnum.BigQuery,
    columns: ['big_query_user_id'],
  },
  {
    provider: SourceProviderEnum.Kintone,
    columns: ['kintone_user_id'],
  },
]

const IconBody = ({ provider }: { provider: SourceProviderEnum }) => {
  switch (provider) {
    case SourceProviderEnum.Salescore: {
      return <Avatar size="small" src="/images/provider_icon/salescore.png" />
    }
    case SourceProviderEnum.Salesforce: {
      return <Avatar size="small" src="/images/provider_icon/salesforce.png" />
    }
    case SourceProviderEnum.Hubspot: {
      return <Avatar size="small" src="/images/provider_icon/hubspot.png" />
    }
    case SourceProviderEnum.Zoho: {
      return <Avatar size="small" src="/images/provider_icon/zoho.png" />
    }
    case SourceProviderEnum.NextSfa: {
      return <Avatar size="small" src="/images/provider_icon/next_sfa.png" />
    }
    case SourceProviderEnum.GoogleSheets: {
      return <Avatar size="small" src="/images/provider_icon/google_sheets.png" />
    }
    case SourceProviderEnum.GoogleCalendar: {
      // return <Avatar src="/images/provider_icon/google_sheets.png" />
      return <></>
    }
    case SourceProviderEnum.Comdesk: {
      return <Avatar size="small" src="/images/provider_icon/comdesk.png" />
    }
    case SourceProviderEnum.Senses: {
      return <Avatar size="small" src="/images/provider_icon/senses.png" />
    }
    case SourceProviderEnum.MicrosoftDynamics: {
      return <Avatar size="small" src="/images/provider_icon/microsoft_dynamics.png" />
    }
    case SourceProviderEnum.BigQuery: {
      return <Avatar size="small" src="/images/provider_icon/big_query.png" />
    }
    case SourceProviderEnum.Kintone: {
      return <Avatar size="small" src="/images/provider_icon/kintone.png" />
    }
    default: {
      // const x: never = provider
      return <></>
    }
  }
}

export const IntegrationProviderColumnIcon = ({
  provider,
  ids,
}: {
  provider: INTEGRATION_PROVIDER_WITH_COLUMN_NAMES
  ids: string[]
}) => {
  return (
    <Tooltip
      title={provider.columns.map((column, index) => (
        <div> {`${column}: ${ids[index]!}`}</div>
      ))}
    >
      <span className="mr-1" style={{ cursor: 'help' }}>
        <IconBody provider={provider.provider} />
      </span>
    </Tooltip>
  )
}

import { SettingOutlined } from '@ant-design/icons'
import { Collapse, Space } from 'antd'
import type { ReactNode } from 'react'

import { handleMe } from '../../presentation/handleMe'

export const AdminCollapse = ({ children }: { children: ReactNode }): ReactNode => {
  return handleMe(({ isAdmin }) => {
    if (!isAdmin) {
      return <></>
    }
    return (
      <Collapse defaultActiveKey={[]} className="mt-4">
        <Collapse.Panel
          header={
            <Space>
              <SettingOutlined /> <span>管理者用</span>
            </Space>
          }
          key="admin"
        >
          {children}
        </Collapse.Panel>
      </Collapse>
    )
  })
}

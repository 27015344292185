import { useUpsertSheetRowMutation } from '../../../../recoil/mutations/upsertSheetRowMutation'
import type { RSheetsCellInputRenderTypeArgument } from '../InputCell'
import { DatePicker } from './DatePicker'
import { RSheetsInputCellWrapper } from './InputCellWrapper'

export function RSheetsCellDateInput({
  recordNode,
  column,
  rowIndex,
  innerRowIndex,
  defaultWidth,
  finishEditMode,
  boxStyle,
}: RSheetsCellInputRenderTypeArgument) {
  const columnOnChange = useUpsertSheetRowMutation()
  const value = recordNode === undefined ? undefined : column.value(recordNode)
  const date = typeof value === 'string' ? value : undefined

  return RSheetsInputCellWrapper(
    {
      onChangeFixed() {
        // TODO: 単純にキャンセルとするで良いか？
      },
      finishEditMode,
    },
    ({ handleKeyDown }) => {
      return (
        <div
          style={{
            ...boxStyle,
            padding: '9px 9px',
            backgroundColor: 'white',
          }}
        >
          <DatePicker
            value={date}
            onChange={async (value) => {
              await columnOnChange({ value: value?.format('YYYY-MM-DD'), rowIndex, innerRowIndex, column })
              finishEditMode()
            }}
            onClose={() => {
              finishEditMode()
            }}
          />
        </div>
      )
    },
  )
}

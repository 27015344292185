import { range } from '@salescore/buff-common'
import type { TailwindCssColor } from '@salescore/client-base'
import { selector, useRecoilValue } from 'recoil'

import { ROW_HEIGHT } from '../../components/body/RSheetRow'
import { TREE_HEADER_HEIGHT_PADDING } from '../../components/header/RSheetsNodeBlockHeader'
import { contextAtom } from '../atoms'
import { columnRelatedSelector } from './columnRelatedSelector'

export interface RSheetsNodeBlockCell {
  block: RSheetsNodeBlock
  width: number
  label: string
  smallLabel: string | undefined
  color: TailwindCssColor | undefined
  tooltipText?: string
  // onClick
}
export type RSheetsNodeBlockRow = RSheetsNodeBlockCell[]
type RSheetsNodeBlockHeader = RSheetsNodeBlockRow[]

export interface RSheetsNodeBlock {
  nodePath: string[]
  labels: string[]
  smallLabels?: string[]
  colors: TailwindCssColor[]
  helpTexts?: Array<string | undefined>
  // menu,onClickなど
}

const defaultNodeBlock: RSheetsNodeBlock = {
  nodePath: [],
  labels: [],
  colors: [],
  helpTexts: [],
}

export const columnBlocksRelatedSelector = selector({
  key: 'columnBlocksRelatedSelector',
  get: ({ get }) => {
    const context = get(contextAtom)
    const { columns, leftFixedColumns, notFixedColumns } = get(columnRelatedSelector)

    const leftFixedNodeBlockHeader = getNodeBlock(
      leftFixedColumns.map((x) => x.nodeBlock ?? defaultNodeBlock),
      leftFixedColumns.map((x) => x.width ?? 100),
    )
    const notFixedNodeBlockHeader = getNodeBlock(
      notFixedColumns.map((x) => x.nodeBlock ?? defaultNodeBlock),
      notFixedColumns.map((x) => x.width ?? 100),
    )
    const nodeBlockHeaderAll = getNodeBlock(
      columns.map((x) => x.nodeBlock ?? defaultNodeBlock),
      columns.map((x) => x.width ?? 100),
    )
    const maxDepth = nodeBlockHeaderAll.length
    const treeHeaderHeightPerNode = context.asKpiTable === true ? 42 : 20
    const treeHeaderHeight = maxDepth * treeHeaderHeightPerNode + TREE_HEADER_HEIGHT_PADDING
    const headerCellHeight =
      context.headerWithBigIcon === true ? 62 + ((context.headerTitleRowNum ?? 1) - 1) * 14 : ROW_HEIGHT
    const headerTopMargin = 2
    const headerHeight = treeHeaderHeight + headerCellHeight - headerTopMargin

    return {
      leftFixedNodeBlockHeader,
      notFixedNodeBlockHeader,
      nodeBlockHeaderAll,
      maxDepth,
      treeHeaderHeight,
      headerHeight,
      treeHeaderHeightPerNode,
      headerCellHeight,
      headerTopMargin,
    }
  },
})

export const useColumnBlocksRelatedSelector = () => {
  return useRecoilValue(columnBlocksRelatedSelector)
}

function getNodeBlock(nodeBlocks: RSheetsNodeBlock[], widths: number[]): RSheetsNodeBlockHeader {
  if (nodeBlocks.isBlank()) {
    return []
  }

  const maxDepth = nodeBlocks.map((x) => x.nodePath.length).max() ?? 0
  const blockHeader = range(0, maxDepth - 1).map((depth): RSheetsNodeBlockRow => {
    const blockGroups = nodeBlocks.sliceWhen((a, b) => a.nodePath[depth] !== b.nodePath[depth])
    const ws = [...widths]
    return blockGroups.map((blockGroup): RSheetsNodeBlockCell => {
      const block = blockGroup.first()!
      const label = block.labels[depth]
      const smallLabel = (block.smallLabels ?? [])[depth]
      const color = block.colors[depth]
      const tooltipText = (block.helpTexts ?? [])[depth]
      return {
        block,
        label: label ?? '-',
        smallLabel,
        width: ws.splice(0, blockGroup.length).sum(),
        color,
        tooltipText,
      }
    })
  })

  return blockHeader
}

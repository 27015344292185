import { useMutation } from '@apollo/client'
import { isSome } from '@salescore/buff-common'
import {
  ArchiveViewGroupDocument,
  CreateViewGroupDocument,
  DeleteViewGroupDocument,
  type ViewGroupCreateInput,
} from '@salescore/client-api'
import { message } from 'antd'
import { t } from 'i18next'

import { useOrganizationId } from '../../global/atoms'
import { useViewGroupTreeExpandedKeys } from '../hooks'
import { useRefetchViewGroups } from './refetchViewGroups'

export const useCreateViewGroup = () => {
  const [createViewGroupMutation] = useMutation(CreateViewGroupDocument)
  const organizationId = useOrganizationId()
  const refetchViewGroups = useRefetchViewGroups()
  const [_expandedKeys, setExpandedKeys] = useViewGroupTreeExpandedKeys()

  return async (input: ViewGroupCreateInput) => {
    try {
      const result = await createViewGroupMutation({
        variables: {
          organizationId,
          viewGroup: input,
        },
      })
      await refetchViewGroups() // TODO: fetchしたくない
      void message.success(t(`フォルダを作成しました`))
      const viewGroup = result.data?.createViewGroup
      // 親要素があれば、必ず親要素を開く
      // TODO: 孫以下が作成された場合には、孫に到達するまでの全てのkeysをexpandKeysにセットする必要があるが、現状できていない
      if (isSome(viewGroup?.viewGroupId)) {
        setExpandedKeys((keys) => {
          return [...keys, viewGroup.viewGroupId].compact().unique()
        })
      }
      return result.data?.createViewGroup
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  }
}

export const useDeleteViewGroup = () => {
  const [deleteViewGroupMutation] = useMutation(DeleteViewGroupDocument)
  const organizationId = useOrganizationId()
  const refetchViewGroups = useRefetchViewGroups()

  return async (id: string) => {
    try {
      const result = await deleteViewGroupMutation({
        variables: {
          organizationId,
          id,
        },
      })
      await refetchViewGroups() // TODO: fetchしたくない
      void message.success(t(`フォルダを削除しました`))
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  }
}

export const useArchiveViewGroup = () => {
  const [archiveViewGroupMutation] = useMutation(ArchiveViewGroupDocument)
  const organizationId = useOrganizationId()
  const refetchViewGroups = useRefetchViewGroups()

  return async (id: string) => {
    try {
      const result = await archiveViewGroupMutation({
        variables: {
          organizationId,
          id,
        },
      })
      await refetchViewGroups() // TODO: fetchしたくない
      void message.success(t(`フォルダをゴミ箱へ移動しました`))
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  }
}

import type { CoreDslFormOperator, CoreDslFormTerm, ModelProperty } from '@salescore/core'
import { Button, Input, InputNumber, Select, Space } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { CoreDslDateLiteralFormItem } from './CoreDslFormDateLiteralFormItem'

interface Properties {
  onFinish: (term: CoreDslFormTerm) => void
  property: ModelProperty
  term: CoreDslFormTerm | undefined
  operator: CoreDslFormOperator | undefined
}

const NotSupportedType = () => {
  return <div>not supported</div>
}

export function CoreDslFormLiteralFormItem(argument: Properties) {
  const { property, operator, onFinish } = argument

  if (operator === 'in' || operator === 'not_in') {
    return <StringArrayLiteralFormItem {...argument} />
  }

  const type = property.type
  switch (type) {
    case 'string' as const: {
      return <StringLiteralFormItem {...argument} />
    }
    case 'integer' as const: {
      return <NumberLiteralFormItem {...argument} />
    }
    case 'numeric' as const: {
      return <NumberLiteralFormItem {...argument} />
    }
    case 'date' as const: {
      return <CoreDslDateLiteralFormItem {...argument} />
    }
    case 'datetime' as const: {
      return <CoreDslDateLiteralFormItem {...argument} />
    } // 全てDateで扱う
    case 'time' as const: {
      return <NotSupportedType />
    }
    case 'boolean' as const: {
      return <BooleanLiteralFormItem {...argument} />
    }
    case 'object' as const: {
      // TODO
      return <NotSupportedType />
    }
    case 'array' as const: {
      return <StringArrayLiteralFormItem {...argument} />
    }
    default: {
      const x: never = type
      return <NotSupportedType />
    }
  }
}

function StringArrayLiteralFormItem({ onFinish, property }: Properties) {
  const [value, setValue] = useState<string[]>([])
  // TODO: 現在の値の表示

  const selectOptions = property.selectOptions ?? []

  return (
    <Space>
      <Select
        mode="multiple"
        style={{ width: '100%', minWidth: 140 }}
        onChange={(value) => {
          setValue(value as string[])
        }}
        options={selectOptions.map((x) => ({
          value: x.value,
          label: x.label,
        }))}
        showSearch
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      />
      <Button
        type="primary"
        onClick={() => {
          onFinish({
            type: 'literal',
            literalType: 'array',
            value,
          })
        }}
      >
        {t(`決定`)}
      </Button>
    </Space>
  )
}

function StringLiteralFormItem({ onFinish, property }: Properties) {
  const [value, setValue] = useState('')
  // TODO: 現在の値の表示

  const selectOptions = property.selectOptions ?? []

  if (
    // !['include', 'not_include'].includes(filterType as string) &&
    selectOptions.isPresent()
  ) {
    return (
      <Space>
        <Select
          style={{ width: '100%', minWidth: 140 }}
          onChange={(value) => {
            setValue(value as string)
          }}
          options={selectOptions.map((x) => ({
            value: x.value,
            label: x.label,
          }))}
          showSearch
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        />
        <Button
          type="primary"
          onClick={() => {
            onFinish({
              type: 'literal',
              literalType: 'string',
              value,
            })
          }}
        >
          {t(`決定`)}
        </Button>
      </Space>
    )
  }

  return (
    <Space>
      <Input
        value={value}
        style={{ minWidth: 160 }}
        onChange={(e) => {
          setValue(e.target.value)
        }}
      />
      <Button
        type="primary"
        onClick={() => {
          onFinish({
            type: 'literal',
            literalType: 'string',
            value,
          })
        }}
      >
        {t(`決定`)}
      </Button>
    </Space>
  )
}

function NumberLiteralFormItem({ onFinish, term }: Properties) {
  const [value, setValue] = useState(0) // TODO: 現在の値の表示

  return (
    <Space>
      <InputNumber
        style={{ minWidth: 120 }}
        value={value}
        onChange={(v) => {
          setValue(v ?? 0)
        }}
      />
      <Button
        type="primary"
        onClick={() => {
          onFinish({
            type: 'literal',
            literalType: 'number',
            value,
          })
        }}
      >
        {t(`決定`)}
      </Button>
    </Space>
  )
}

function BooleanLiteralFormItem({ onFinish, term }: Properties) {
  const [value, setValue] = useState<boolean>(true) // TODO: 現在の値の表示
  return (
    <Space>
      <Select
        value={value}
        onChange={(e) => {
          setValue(e)
        }}
        options={[
          {
            label: 'TRUE',
            value: true,
          },
          {
            label: 'FALSE',
            value: false,
          },
        ]}
      />
      <Button
        type="primary"
        onClick={() => {
          onFinish({
            type: 'literal',
            literalType: 'boolean',
            value,
          })
        }}
      >
        {t(`決定`)}
      </Button>
    </Space>
  )
}

//
// deprecated
// coreのrecordNodeUtilを使ってください
//

import type { ViewQueryList, ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'
import { v4 } from 'uuid'

import { VIEW_NEW_RECORD_PREFIX } from '../domain/constant'
import { getDefaultAttributes } from '../recoil/records/selectors/querySelector'
import type { ViewRecordFieldChange } from './useViewRecordsState/useChangesState'

// pathに応じたrecordsを取得する
// recordsは元のnodeのchildrenの配列オブジェクトそのものではなく、pushなどしても元のrootには反映されないので注意
// （深さ3以上のときを考えれば自明）
export function getRecordNodeFromRootNode(
  rootNode: ViewQueryRecordNode,
  nodePath: string[],
): ViewQueryRecordNode | ViewQueryRecordNode[] | undefined {
  if (nodePath.length === 0) {
    return undefined
  }
  if (nodePath.length === 1) {
    return rootNode
  }
  return getRecordNode(rootNode, nodePath.slice(1))
}

function getRecordNode(
  node: ViewQueryRecordNode,
  nodePath: string[],
): ViewQueryRecordNode | ViewQueryRecordNode[] | undefined {
  if (nodePath.length === 0) {
    return node
  }

  const childTableNode = node.children.find((x) => x.nodeName === nodePath.first()!)
  if (childTableNode === undefined) {
    return undefined
  }
  return childTableNode.children
    .flatMap((childRecordNode) => getRecordNode(childRecordNode, nodePath.slice(1)))
    .compact()
}

export function getNode(node: ViewQueryNode, nodePath: string[]): ViewQueryNode | undefined {
  if (nodePath.length === 1 && node.name === nodePath[0]) {
    return node
  }
  if (nodePath.length <= 1) {
    // TODO: ノードが見つからなかった時
    return undefined
  }
  const childNode = (node.children ?? []).find((childNode) => childNode.name === nodePath[1])
  if (childNode === undefined) {
    return undefined
  }

  return getNode(childNode, nodePath.slice(1))
}

export function flatNodes<T extends { children?: T[] }>(node: T | undefined): T[] {
  if (node === undefined) {
    return []
  }

  return [node, ...(node.children?.flatMap((childNode) => flatNodes(childNode)) ?? [])]
}

// 破壊的操作なので注意
export function addNode({
  tree,
  targetNodeName,
  newNode,
}: {
  tree: ViewQueryNode
  targetNodeName: string
  newNode: ViewQueryNode
}): ViewQueryNode | undefined {
  if (tree.name === targetNodeName) {
    tree.children = [...(tree.children ?? []), newNode]
    return tree
  }

  // dfs
  for (const childNode of tree.children ?? []) {
    const newChildNode = addNode({ tree: childNode, targetNodeName, newNode })
    if (newChildNode !== undefined) {
      return tree // 参照渡しになっており、破壊的操作をするので、これでいけるはず？
    }
  }

  return undefined // 失敗したとき
}

// treeのrootは削除できない仕様
export function removeNode({ tree, targetNodeName }: { tree: ViewQueryNode; targetNodeName: string }): ViewQueryNode {
  const children = tree.children ?? []

  if (children.length === 0) {
    return tree
  }

  if (children.map((x) => x.name).includes(targetNodeName)) {
    const newChildren = children.filter((x) => x.name !== targetNodeName)
    return {
      ...tree,
      children: newChildren,
    }
  }

  // dfs
  const newChildren = children.map((x) => removeNode({ tree: x, targetNodeName }))
  return {
    ...tree,
    children: newChildren,
  }
}

export function removeNodes({
  tree,
  targetNodeNames,
}: {
  tree: ViewQueryNode
  targetNodeNames: string[]
}): ViewQueryNode {
  const newNode = targetNodeNames.reduce((currentTree, targetNodeName) => {
    return removeNode({ tree: currentTree, targetNodeName })
  }, tree)
  return newNode
}

export function replaceNode({ tree, newNode }: { tree: ViewQueryNode; newNode: ViewQueryNode }): ViewQueryNode {
  if (tree.name === newNode.name) {
    return newNode
  }

  const children = tree.children ?? []

  if (children.length === 0) {
    return tree
  }

  // node.nameが同じnodeがツリーに存在しない前提で、bfs
  const newChildren = children.map((childNode) => replaceNode({ tree: childNode, newNode }))
  return {
    ...tree,
    children: newChildren,
  }
}

export const generateNewRecordId = (): string => {
  return `${VIEW_NEW_RECORD_PREFIX}-${v4()}`
}

export const generateNewRootRecord = (query: ViewQueryList) => {
  const defaultAttributes = getDefaultAttributes(query.tree, query) // TODO: defaultAttributeはrecordChanges側で対応しないとrelationが反映されない
  const newRecord: ViewQueryRecordNode = {
    id: generateNewRecordId(),
    attributes: defaultAttributes.recordAttributes,
    children: [],
    meta: {
      height: 1,
      innerRowIndexStart: 0,
      innerRowIndexEnd: 1,
    },
  }
  const change: ViewRecordFieldChange | undefined =
    query.tree.write === undefined
      ? undefined
      : {
          id: newRecord.id,
          streamName: query.tree.write.streamName,
          fieldChanges: [], // デフォルト値に対しては不要なはず
          before: {},
          after: defaultAttributes.changeAttributes,
        }
  return {
    newRecord,
    change,
  }
}

// TODO: RSheetと共通化？
export function getInnerRecordNode(recordNodes: ViewQueryRecordNode[], innerRowIndex: number) {
  return recordNodes.find((x) => x.meta.innerRowIndexStart <= innerRowIndex && innerRowIndex < x.meta.innerRowIndexEnd)
}

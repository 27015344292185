import { EnvTooltip } from '@salescore/frontend-common'

export const Logo = ({ type, width }: { type?: 'white' | 'black'; width?: number }) => {
  return (
    <div
      style={{
        width,
      }}
    >
      <img alt="SALESCORE" src={`/images/logo-${type ?? 'white'}.png`} style={{ width: '100%' }} />
      <EnvTooltip />
    </div>
  )
}

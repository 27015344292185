import type { ViewConfigMeta } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'
import { useSetRecoilState } from 'recoil'

import { configSheetAtom } from '../atoms'
import { setConfigMutation } from './setConfigMutation'

const setMetaMutation = mutation({
  key: `view/setMetaMutation`,
  set({ get, set }, { newMeta }: { newMeta: ViewConfigMeta | undefined }) {
    const config = get(configSheetAtom)

    if (newMeta === undefined) {
      return
    }

    set(setConfigMutation, {
      ...config,
      meta: {
        ...config.meta,
        ...newMeta,
      },
    })
  },
})

export const useMetaMutations = () => {
  return {
    setMeta: useSetRecoilState(setMetaMutation),
  }
}

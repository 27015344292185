import { DownOutlined, ExclamationCircleOutlined, RedoOutlined, SwapOutlined } from '@ant-design/icons'
import { type ApolloClient, ApolloConsumer } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import { SyncContextEnum } from '@salescore/client-api'
import { type ConnectionWithSourceProvider, useSyncConnection } from '@salescore/client-common'
import { ButtonWithTooltip } from '@salescore/frontend-common'
import { Dropdown, Popconfirm } from 'antd'
import { t } from 'i18next'

const Body = ({
  connection,
  client,
  onSuccess,
}: {
  connection: ConnectionWithSourceProvider
  client: ApolloClient<unknown>
  onSuccess: () => void
}) => {
  const { loading, queueSync } = useSyncConnection({ connections: [connection], client, onSuccess })
  const isConnectionDeactivated = isNull(connection) || !connection.active
  const items = [
    {
      key: 'incremental',
      icon: <SwapOutlined />,
      label: t(`差分のみ取得`),
      onClick: () => {
        void queueSync(SyncContextEnum.ManualIncremental)
      },
    },
    {
      key: 'bulk',
      icon: <ExclamationCircleOutlined />,
      label: (
        <Popconfirm
          title={
            <div>
              {t(`全てのレコードを取得するには非常に時間がかかる可能性があります。よろしいですか？`)}
              <br />
              {t(`※取得データに不整合がおきた場合などのみお使いください。`)}
            </div>
          }
          onConfirm={() => {
            void queueSync(SyncContextEnum.ManualBulk)
          }}
          okText={t(`実行`)}
          cancelText={t(`キャンセル`)}
        >
          {t(`全てのレコードを取得`)}
        </Popconfirm>
      ),
    },
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']} arrow disabled={isConnectionDeactivated}>
      <ButtonWithTooltip
        tooltipTitle={t(`連携が停止中のため同期できません`)}
        showTooltip={isConnectionDeactivated}
        icon={<RedoOutlined />}
        disabled={isConnectionDeactivated}
      >
        {t(`同期`)}
        <DownOutlined />
      </ButtonWithTooltip>
    </Dropdown>
  )
}

export const SyncButton = ({
  connection,
  onSuccess,
}: {
  connection: ConnectionWithSourceProvider
  onSuccess: () => void
}) => {
  return (
    <ApolloConsumer>
      {(client) => {
        return <Body connection={connection} client={client} onSuccess={onSuccess} />
      }}
    </ApolloConsumer>
  )
}

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, type FormInstance, type FormListFieldData, Input } from 'antd'
import { t } from 'i18next'

interface Properties {
  bulkEditable: boolean
  form: FormInstance
}

interface ItemFormProperties {
  field: FormListFieldData
  onRemove: () => void
}

export interface SelectOptionItem {
  value: string
  label: string
}

export const GoalDimensionListFormItem = (properties: Properties) => {
  return properties.bulkEditable ? <BulkEditForm /> : <ListForm />
}

function ListForm() {
  return (
    <Form.List name={['selectOptions', 'items']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <ListFormItemInput
              key={index}
              field={field}
              onRemove={() => {
                remove(field.name)
              }}
            />
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add({ label: '', value: '' })
              }}
              block
              icon={<PlusOutlined />}
            >
              {t(`項目を追加`)}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

function ListFormItemInput(properties: ItemFormProperties) {
  const {
    field: { key, name: nameKey, ...restFields },
    onRemove,
  } = properties
  return (
    <div key={key} className="flex items-baseline gap-x-2">
      <Form.Item
        {...restFields}
        className="flex-1"
        rules={[{ required: true, message: t(`ラベルを入力してください`) }]}
        name={[nameKey, 'label']}
      >
        <Input placeholder={t(`ラベルを入力`)} />
      </Form.Item>
      <Form.Item
        {...restFields}
        className="flex-1"
        name={[nameKey, 'value']}
        rules={[{ required: true, message: t(`値を入力してください`) }]}
      >
        <Input placeholder={t(`値を入力`)} />
      </Form.Item>
      <DeleteOutlined onClick={onRemove} />
    </div>
  )
}

function BulkEditForm() {
  return (
    <Form.Item name={['selectOptions', 'itemsStr']}>
      <Input.TextArea
        placeholder={t(`「ラベル,値」の形式で入力してください。複数入力する場合は改行してください。`)}
        style={{ minHeight: '250px' }}
      />
    </Form.Item>
  )
}
